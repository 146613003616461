import { createContext, Dispatch, useContext, useReducer } from "react"

type ActionProps = {
    type:"view_cart"|"close_cart",
    payload?:any
}

const initialState = {
    isOpen:false,
    payload:null
}

function reducer(state = initialState, action: ActionProps ) {
    switch (action.type) {
        case "view_cart":
            return{
                ...state,
                payload: action.payload,
                isOpen:true
            };
        case "close_cart":
            return{
                ...state,
                payload: null,
                isOpen:false
            };
    
        default:
            throw new Error("Unknown Modal Action!");
    }
}

const StateContext = createContext<typeof initialState>(initialState);
StateContext.displayName = "StateContext";
const DispatchContext = createContext<Dispatch<ActionProps> | undefined>(undefined);
DispatchContext.displayName = "DispatchContext";


export function CartProvider({ children }:any){
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}

export function useShoppingCart(){
    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    if (state === undefined) {
        throw new Error("use Cart Modal Under Provider");
    }

    if (dispatch === undefined) {
        throw new Error("use Cart Modal Under Provider");
    }

    return{
        ...state,
        viewCart(){
            dispatch({ type:"view_cart" });
        },
        closeCart(){
            dispatch({ type:"close_cart" })
        }

    }
}