import React, { SyntheticEvent } from 'react';
import { LockClosedIcon } from '@heroicons/react/solid'

import { InputBox } from '../ui/input-box';
import { useUser } from '../hook/use-user';
import { useModal } from '../ui/modal/modal.context';


function ForgotPassword() {
  const { openModal } = useModal();
  const { userReset, userUpdatePassword,  info, error } = useUser();
  
  const handleSubmitFrom = (e:SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      token:{
        value:string
      },
      confirm_password:{
        value:string
      },
      password:{
        value:string
      },
      credential:{
        value:string
      }
    }
    
    if (target?.password?.value?.length < 8) {
      return openModal({ view:"ALERT",  payload:"Your password must be minimum 8 digits" })
    }

    if (target?.password?.value?.length !== target?.confirm_password?.value?.length) {
      return openModal({ view:"ALERT",  payload:"Your password do not match" })
    }

    const save = target.token?.value ? { 
      token:target.token.value, 
      confirm_password:target.confirm_password.value, 
      password:target.password.value 
    } : { credential:target.credential.value };


    let detectSession = Object.entries(save).length;
    console.log("this", save,detectSession);

    if (detectSession < 2) {
      userReset({
        ...save,
        abilities: "[1]",
        source: "inbuilt"
      });
    }else {
      userUpdatePassword(save);
    }
  }

  React.useEffect(()=>{

    setTimeout(() => {
      if(info.status && info.query === "Your password has been updated successfully"){ 
        window.location.replace("/");
      }
    }, 1200);
  },[info]);

  return (
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 my-auto">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Reset Password</h2>
          <div className="mt-6 text-center text-blue-400">{info.query ?? error}</div>
        </div>
        {!info.status && !info.query
        ? <form className="mt-8 space-y-6" onSubmit={handleSubmitFrom}>
          <div className="rounded-md shadow-sm -space-y-px">
              <InputBox 
                name="credential"
                type="text"
                required={true}
                classNameInput="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
              />
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#403d93] hover:bg-[#6e6bd5] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {/* <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
              </span> */}
              Reset
            </button>
          </div>
        </form>
        :
        <form className="mt-8 space-y-6" onSubmit={handleSubmitFrom}>
          <div className="rounded-md shadow-sm -space-y-px">
              <InputBox
                name="token"
                type="token"
                autoComplete="token"
                required={true}
                classNameInput="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Token"
              />
              <InputBox
                name="password"
                type="password"
                autoComplete="current-password"
                required={true}
                classNameInput="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
              />
              <InputBox
                name="confirm_password"
                type="password"
                autoComplete="current-password"
                required={true}
                classNameInput="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
              />
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#403d93] hover:bg-[#6e6bd5] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {/* <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
              </span> */}
              Update
            </button>
          </div>
        </form>
        }
      </div>
    </div>
    )
}

export default ForgotPassword;
