/*jshint ignore:start*/
import "./common.css";
import React from "react";
import { Link } from "react-router-dom";

export const Customer = (props) => {
  return (
    <div>
      <section className="text-center">
        <div className="">
          {/* <!-- page heading--> */}
          <section className="jumbotron text-center">
            <div className="container md:py-10">
              <h1 className="jumbotron-heading md:text-[3em]">
                CUSTOMER SERVICE
              </h1>
              <p className="lead opacity-50 mb-0 help-step-title md:mt-4">
                help and enquiries
              </p>
            </div>
          </section>
          <div className="container pb-3 mb-1">
            <div className="card mb-3">
              <div className="p-4 md:p-5 text-center text-white text-lg md:text-2xl bg-[#403D94] rounded-t-lg">
                <span className="text-uppercase">
                  Hello, how can we help you?
                </span>
              </div>

              <div
                className="card-body"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="steps md:flex justify-between items-center pt-2 pb-1">
                  <Link to="/login">
                    <div
                      className="step step-effect pb-5 md:pb-0 flex flex-col items-center justify-center p-10 md:w-[285px]"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                        <img
                          className="icon-img w-[50px] h-[50px]"
                          src="https://pxstores-frontend.netlify.app/images/icons/orders.png"
                        />
                      </div>
                      <h2 className="text-[14px] text-[#403D94] text-center font-black  help-step-title">
                        <Link to="/login"> Your Orders</Link>
                      </h2>
                      <h2 className="step-title text-sm">
                        Track parcels, edit or cancel orders
                      </h2>
                    </div>
                  </Link>

                  <Link to="/login">
                    <div
                      className="step step-effect pb-5 md:pb-0 flex flex-col items-center justify-center p-10 md:w-[285px]"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                        <img
                          className="icon-img w-[50px] h-[50px]"
                          src="https://pxstores-frontend.netlify.app/images/icons/returns%20and%20refunds.png"
                        />
                      </div>
                      <h3 className="text-[14px] text-[#403D94] text-center font-black  help-step-title">
                        <Link to="/login">Returns and Refunds</Link>
                      </h3>
                      <h2 className="step-title text-sm">
                        Return or exchange items
                      </h2>
                    </div>
                  </Link>

                  <Link to="/login">
                    <div
                      className="step step-effect pb-5 md:pb-0 flex flex-col items-center justify-center p-10 md:w-[285px]"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                        <img
                          className="icon-img w-[50px] h-[50px]"
                          src="https://pxstores-frontend.netlify.app/images/icons/contact.png"
                        />
                      </div>
                      <h3 className="text-[14px] text-[#403D94] text-center font-black  help-step-title">
                        <Link to="/login">Account information</Link>
                      </h3>
                      <h2 className="step-title text-sm">
                        Change email address or password and <br />
                        other login information
                      </h2>
                    </div>
                  </Link>
                </div>
              </div>
              <div
                className="card-body"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="steps md:flex justify-between items-center pt-2 pb-1">
                  <Link to="/login">
                    <div
                      className="step step-effect pb-5 md:pb-0 flex flex-col items-center justify-center p-10 md:w-[285px]"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                        <img
                          className="icon-img w-[50px] h-[50px]"
                          src="https://pxstores-frontend.netlify.app/images/icons/payment.png"
                        />
                      </div>
                      <h3 className="text-[14px] text-[#403D94] text-center font-black  help-step-title">
                        <Link to="/login">Payment Options</Link>
                      </h3>
                      <h2 className="step-title text-sm">
                        Add or edit payment methods, <br /> edit expired card
                        details
                      </h2>
                    </div>
                  </Link>

                  <Link to="/login">
                    <div
                      className="step step-effect pb-5 md:pb-0 flex flex-col items-center justify-center p-10 md:w-[285px]"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                        <img
                          className="icon-img w-[50px] h-[50px]"
                          src="https://pxstores-frontend.netlify.app/images/icons/premier.png"
                        />
                      </div>
                      <h3 className="text-[14px] text-[#403D94] text-center font-black  help-step-title">
                        <Link to="/login">Manage Premier</Link>
                      </h3>
                      <h2 className="step-title text-sm">
                        Learn about PX Stores premier, <br /> cancel
                        subscription
                      </h2>
                    </div>
                  </Link>

                  <Link to="/support">
                    <div
                      className="step step-effect pb-5 md:pb-0 flex flex-col items-center justify-center p-10 md:w-[285px]"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                        <img
                          className="icon-img w-[50px] h-[50px]"
                          src="https://pxstores-frontend.netlify.app/images/icons/report.png"
                        />
                      </div>
                      <h3 className="text-[14px] text-[#403D94] text-center font-black  help-step-title">
                        <Link to="/support">Report Problems</Link>
                      </h3>
                      <h2 className="step-title text-sm">
                        Report issues with purchase, <br /> scam and phishing
                        cases.
                      </h2>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
