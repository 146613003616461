/*jshint ignore:start*/
import "./common.css";
import React from "react";

export const Carrier = (props) => {
  return (
    <div>
      <section className="jumbotron text-center bg-gray-400 py-5 my-2 md:py-10 md:my-5">
        <div className="container md:py-10">
          <h1 className="jumbotron-heading md:text-[3em]">BECOME A CARRIER</h1>
          <p className="lead opacity-50 mb-0 help-step-title md:mt-4">
            Join our drivers
          </p>
        </div>
      </section>

      <div className="container pb-3 mb-1">
        <div className="card mb-3">
          <div
            className="card-body"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="steps md:flex justify-between items-center pt-2 pb-1">
              <div
                className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <img
                  className="about-img-2 p-10"
                  src="https://pxstores-frontend.netlify.app/images/become-a-carrier.png"
                />
              </div>
              <div
                className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="help-step-title heading text-left  text-[1.5em] md:text-[4em] text-[#403D94] font-bold">
                  Who are carriers?
                </div>
                <h2 className="text-sm md:text-lg text-left subtitle">
                  Carriers are registered members of Parcel Exchange, who
                  carryout parcel delivery services for PX Stores. They own
                  Goods - In - Transit insured transport means ranging from
                  aeroplanes, trucks , lorries, vans , cars to motorbikes used
                  for delivery.
                </h2>
                <h2 className="text-sm md:text-lg text-left subtitle">
                  All carriers have access to our advanced intergrated driver
                  tracking application available on Google Play and App Store.
                  The app is designed to track individual loads location real
                  time 24/7 anywhere in the globe.
                </h2>
              </div>
            </div>
          </div>

          <div
            className="p-4 text-center text-white text-2xl bg-[#403D94] rounded-t-lg"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h3 className="text-white">How do carriers benefit?</h3>
          </div>
          <div
            className="card-body"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="steps md:flex justify-between items-start pt-2 pb-1">
              <div
                className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                  <img
                    className="icon-img w-[30px] h-[30px]"
                    src="https://pxstores-frontend.netlify.app/images/icons/numbers/1.png"
                  />
                </div>
                <h3 className="text-[14px] text-[#403D94] text-center font-black md:w-[200px] help-step-title">
                  Earn up to $600 per calendar month working part time, and earn
                  more working full time.
                </h3>
              </div>
              <div
                className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                  <img
                    className="icon-img w-[30px] h-[30px]"
                    src="https://pxstores-frontend.netlify.app/images/icons/numbers/2.png"
                  />
                </div>
                <h3 className="text-[14px] text-[#403D94] text-center font-black md:w-[200px] help-step-title">
                  Be self employed
                </h3>
              </div>
              <div
                className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                  <img
                    className="icon-img w-[30px] h-[30px]"
                    src="https://pxstores-frontend.netlify.app/images/icons/numbers/3.png"
                  />
                </div>
                <h3 className="text-[14px] text-[#403D94] text-center font-black md:w-[200px] help-step-title">
                  Work flexible hours that suit you
                </h3>
              </div>
              <div
                className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                  <img
                    className="icon-img w-[30px] h-[30px]"
                    src="https://pxstores-frontend.netlify.app/images/icons/numbers/4.png"
                  />
                </div>
                <h3 className="text-[14px] text-[#403D94] text-center font-black md:w-[200px] help-step-title">
                  Free logistics and customer <br />
                  service training.
                </h3>
              </div>
            </div>
          </div>

          <div
            className="p-4 text-center text-white text-2xl bg-[#403D94] rounded-t-lg"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h3 className="text-white">How to register as a carrier</h3>
          </div>
          <div
            className="card-body"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div
              className="p-4 text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h3 style={{ color: "#403D94" }}>
                Please follow these steps to register as a carrier
              </h3>
            </div>
            <div className="steps md:grid md:grid-cols-7 justify-between items-start pt-2 pb-1">
              <div
                className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                  <img
                    className="icon-img w-[30px] h-[30px]"
                    src="https://pxstores-frontend.netlify.app/images/icons/numbers/1.png"
                  />
                </div>
                <h3 className="text-[14px] text-[#403D94] text-center font-black md:w-[150px] help-step-title">
                  Download Parcel Exchange <br />
                  Member App
                </h3>
              </div>
              <div
                className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                  <img
                    className="icon-img w-[30px] h-[30px]"
                    src="https://pxstores-frontend.netlify.app/images/icons/numbers/2.png"
                  />
                </div>
                <h3 className="text-[14px] text-[#403D94] text-center font-black md:w-[150px] help-step-title">
                  Register Free By Selecting Carrier
                </h3>
              </div>
              <div
                className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                  <img
                    className="icon-img w-[30px] h-[30px]"
                    src="https://pxstores-frontend.netlify.app/images/icons/numbers/3.png"
                  />
                </div>
                <h3 className="text-[14px] text-[#403D94] text-center font-black md:w-[150px] help-step-title">
                  Select &amp; Pay Package <br />
                  Subscription Fee
                </h3>
              </div>
              <div
                className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                  <img
                    className="icon-img w-[30px] h-[30px]"
                    src="https://pxstores-frontend.netlify.app/images/icons/numbers/4.png"
                  />
                </div>
                <h3 className="text-[14px] text-[#403D94] text-center font-black md:w-[150px] help-step-title">
                  Upload Documents
                </h3>
              </div>
              <div
                className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                  <img
                    className="icon-img w-[30px] h-[30px]"
                    src="https://pxstores-frontend.netlify.app/images/icons/numbers/5.png"
                  />
                </div>
                <h3 className="text-[14px] text-[#403D94] text-center font-black md:w-[150px] help-step-title">
                  Your Account is Activated
                </h3>
              </div>
              <div
                className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                  <img
                    className="icon-img w-[30px] h-[30px]"
                    src="https://pxstores-frontend.netlify.app/images/icons/numbers/6.png"
                  />
                </div>
                <h3 className="text-[14px] text-[#403D94] text-center font-black md:w-[150px] help-step-title">
                  Training
                </h3>
              </div>
              <div
                className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                  <img
                    className="icon-img w-[30px] h-[30px]"
                    src="https://pxstores-frontend.netlify.app/images/icons/numbers/7.png"
                  />
                </div>
                <h3 className="text-[14px] text-[#403D94] text-center font-black md:w-[150px] help-step-title">
                  Start Receiving Jobs and Earn Money
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-[32px] text-center text-white flex flex-col justify-center items-center">
        <div className="text-[#403D94] text-center font-black">
          {"For more information, visit "}
          <a
            style={{ color: "#E8550F" }}
            href="https://parcelexchangegroup.com"
          >
            Parcel Exchange
          </a>
        </div>
      </div>
    </div>
  );
};
