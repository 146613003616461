import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import React, { useState } from "react";

type PaginationProps = {
  from: number;
  to: number;
  total: number;
  current: number;
  last: number;
  goto: any;
};
export default function Pagination({
  current,
  from,
  goto,
  last,
  to,
  total,
}: PaginationProps) {
  const [pageNumber, setpaginate] = useState<number>(1);

  function handleNext() {
    if (current < last) {
      let increment = pageNumber + 1;
      goto(increment);
      setpaginate(increment);
    }
  }
  function handlePrevious() {
    if (current > 1 || current < last) {
      let decrement = pageNumber - 1;
      goto(decrement);
      setpaginate(decrement);
    }
  }
  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <a
          role="button"
          onClick={handlePrevious}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-[#000] bg-white hover:bg-gray-50"
          style={{ color: "#000" }}
        >
          Previous
        </a>
        <a
          role="button"
          onClick={handleNext}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-[#000] bg-white hover:bg-gray-50"
          style={{ color: "#000" }}
        >
          Next
        </a>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{from}</span> to{" "}
            <span className="font-medium">{to}</span> of{" "}
            <span className="font-medium">{total}</span> results
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <a
              role="button"
              onClick={handlePrevious}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only text-[#000]">Previous</span>
              <ChevronLeftIcon
                className="h-5 w-5 text-black"
                aria-hidden="true"
              />
            </a>
            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
            {pageNumber > 1 && (
              <a
                role="button"
                onClick={() => goto(pageNumber - 1)}
                className="z-10 bg-indigo-50 border-indigo-500 text-black relative inline-flex items-center px-4 py-2 border text-sm font-medium"
              >
                {pageNumber - 1}
              </a>
            )}
            <a
              role="button"
              onClick={() => goto(pageNumber)}
              className="z-10 bg-indigo-50 border-indigo-500 text-black relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            >
              {pageNumber}
            </a>
            {pageNumber > 1 && pageNumber !== last && (
              <>
                <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                  ...
                </span>
                <a
                  role="button"
                  onClick={() => goto(last)}
                  className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  {last}
                </a>
              </>
            )}
            <a
              role="button"
              onClick={handleNext}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only text-[#000]">Next</span>
              <ChevronRightIcon
                className="h-5 w-5 text-black"
                aria-hidden="true"
              />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}
