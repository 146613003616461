import axios from "axios";
import React, { useState } from "react";
import { baseURl } from "../../redux/env/host-url";

import { useApply } from "../hook/apply-for-jobs";
import { useConverter } from "../hook/currency-conversion";
import { issueSubcriptionReceipt } from "../hook/customer-receipt";
import useFileInput from "../hook/file-handler";
import { useJob } from "../hook/use-job";
import { useSubcription } from "../hook/use-subcription";
import { useSubcriptionPlan } from "../hook/use-subcriptionPlan";
import { usePersonal } from "../hook/user-detail";
import useToken from "../hook/token";
import { PackageCard } from "../ui/card";
import { CheckBoxWithOption } from "../ui/check-box";
import { HorizontalLineAcross } from "../ui/horizontal-line-across";
import { FileBox } from "../ui/input-box";
import MenuTab from "../ui/menu";
import { useModal } from "../ui/modal/modal.context";
import { Payment } from "../ui/payment";
import ShopShortList from "../ui/shop-short-list";
import { Spacer } from "../ui/spacer";
import Loader from "../ui/spinner";
import { TagShortDecription } from "../ui/tag-short-decription";
import Pagination from "../ui/table/pagination";
import { usePermission } from "../hook/permission";

export default function Packages() {
  const [token] = useToken();
  const { data } = usePersonal(true);
  const isSeller = usePermission(1);
  const { openModal } = useModal();
  const { currency_name, iforexWS, iforex } = useConverter();
  const [activavtedTab, activeTab] = React.useState<string>("Subscriptions");
  const jobs = useJob();
  const appylJob = useApply();
  const subscription = useSubcription();
  const subscriptionPlans = useSubcriptionPlan();
  const [subscriptionShopList, setsubscriptionShopList] = React.useState<any[]>(
    []
  );
  const [cashoutDetails, setcashoutDetails] = React.useState<{
    cashout: any;
    receipt: any;
  }>({ cashout: null, receipt: null });
  const [toggled, toggle] = useState<boolean>(false);
  const [jobApplication, jobApplying] = useState<any>(false);

  React.useEffect(() => {
    jobs.getJob({ page: 1 });
    appylJob.getApplicant({ page: 1 });
    subscription.getSubcription({ page: 1 });
    subscriptionPlans.getSubcriptionPlan({ page: 1 });
    let query = window.location.search,
      qureyValue = query?.slice(query.indexOf("=") + 1);
    qureyValue && activeTab(qureyValue);
  }, []);
  React.useEffect(() => {
    let isApiSubscribed = true;
    // @ts-ignore
    axios
      .post(`${baseURl}/shopz`, { owner_id: data?.id })
      .then((result) => {
        if (isApiSubscribed) {
          setsubscriptionShopList(result.data?.HLL);
          // console.log('result',result.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });

    return () => {
      isApiSubscribed = false;
    };
  }, [data]);

  //reformatting lists
  let listed = jobs.info.HLL?.data
    ? jobs.info.HLL?.data.map((value) => {
        return {
          _id: value.id,
          _status: value.status,
          title: value.title,
          state: value.state,
          country: value.country,
          details: value.details,
          description: value.description,
          qualification: value.quantification,
          expiry: value.expiry,
        };
      })
    : [];
  let premierMonthly = subscriptionPlans.info.HLL?.data
    ? subscriptionPlans.info.HLL?.data
        .map((item) => {
          let amount = iforexWS({ from: item.currency, value: item.amount }),
            amountWithSymBol = iforex({
              from: item.currency,
              value: item.amount,
            });
          return {
            type: item.type,
            amount: amountWithSymBol,
            period: item.period,
            full_details: { ...item, amount },
          };
        })
        .find(
          (value: any) => value.type === "premier" && value.period === "Monthly"
        )
    : [];
  let premierAnnually = subscriptionPlans.info.HLL?.data
    ? subscriptionPlans.info.HLL?.data
        .map((item) => {
          let amount = iforexWS({ from: item.currency, value: item.amount }),
            amountWithSymBol = iforex({
              from: item.currency,
              value: item.amount,
            });
          return {
            type: item.type,
            amount: amountWithSymBol,
            period: item.period,
            full_details: { ...item, amount },
          };
        })
        .find(
          (value: any) =>
            value.type === "premier" && value.period === "Annually"
        )
    : [];
  let advert = subscriptionPlans.info.HLL?.data
    ? subscriptionPlans.info.HLL?.data
        .map((item) => {
          let amount = iforexWS({ from: item.currency, value: item.amount }),
            amountWithSymBol = iforex({
              from: item.currency,
              value: item.amount,
            });
          return {
            type: item.type,
            amount: amountWithSymBol,
            total_clicks: item.total_clicks,
            full_details: { ...item, amount },
          };
        })
        .find((value: any) => value.type === "advert")
    : [];
  let commission = subscriptionPlans.info.HLL?.data
    ? subscriptionPlans.info.HLL?.data
        .map((item) => {
          let amount = iforexWS({ from: item.currency, value: item.amount }),
            amountWithSymBol = iforex({
              from: item.currency,
              value: item.amount,
            });
          return {
            type: item.type,
            amount: amountWithSymBol,
            period: item.period,
            full_details: { ...item, amount },
          };
        })
        .find((value: any) => value.type === "commission")
    : [];

  //_subscription
  const handleSubcription = (item: any) => {
    // formatting
    // @ts-ignore
    let email = data?.email,
      // @ts-ignore
      userID = data?.id;

    const cashout = {
      email,
      currency: currency_name,
      total: item.amount,
    };

    // invoicing
    let receipt = issueSubcriptionReceipt({
      // @ts-ignore
      owner_id: userID,
      ...item,
      amount: item.amount,
    });
    setcashoutDetails({ cashout, receipt });
  };

  // @ts-ignore _____job offer
  let nameTag: string | null = data?.name ?? "_pxs_";
  let filename = (
    jobApplication.title +
    "_" +
    nameTag +
    "_" +
    new Date().toDateString()
  ).replace(/(\s)/gi, "_");
  const { handleDocumentSelection, handleUploadFile } = useFileInput({
    FileTag: filename,
    FileTarget: "docx",
  });
  const handleSubmitApplyJob = () => {
    if (!token) {
      return openModal({
        view: "ALERT",
        payload: "Please login and come back to proceed",
      });
    }

    if (!jobApplication || !filename) {
      return openModal({
        view: "ALERT",
        payload: "Please make sure to select your offer and save you resume",
      });
    }

    const payload = {
      // @ts-ignore
      owner_id: data?.id,
      cv: `${baseURl}/files/resumes/${filename}.docx`,
      title: jobApplication.title,
      country: jobApplication.country,
    };

    // console.log("item",payload);
    appylJob.createOrUpdateApplicant(payload);
  };

  const status = [subscriptionPlans.loading, jobs.loading].every(
    (v) => v === true
  );
  if (status) {
    return <Loader />;
  }

  return (
    <div className="lg:mx-[5rem] lg:my-5 md:m-10">
      <MenuTab onSelect={(e) => activeTab(e.name)} />

      <HorizontalLineAcross />
      {activavtedTab === "Subscriptions" ? (
        <>
          <>
            <TagShortDecription
              title="Subscriptions"
              description="Subscribe for our amazing services."
              outerlayerStyle="md:col-span-1"
              innerBodyStyle="px-4 sm:px-0 my-10"
              innerHeaderStyle="text-lg font-medium leading-6 text-gray-900"
              innerlayerStyle="mt-1 text-sm text-gray-600"
            />
            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-2 md:gap-2">
                {/* section 1 */}
                <div className="bg-white">
                  <>
                    <PackageCard
                      item={premierMonthly}
                      cardStyli="mx-10 my-5 shadow"
                      onClick={() =>
                        handleSubcription(premierMonthly.full_details)
                      }
                    />
                    <PackageCard
                      item={premierAnnually}
                      cardStyli="mx-10 my-5 shadow"
                      onClick={() =>
                        handleSubcription(premierAnnually.full_details)
                      }
                    />
                    {isSeller && (
                      <>
                        <PackageCard
                          item={advert}
                          cardStyli="mx-10 my-5 shadow"
                          onClick={() => handleSubcription(advert.full_details)}
                        />
                        <PackageCard
                          item={commission}
                          cardStyli="mx-10 my-5 shadow"
                          onClick={() => toggle(!toggled)}
                        />
                      </>
                    )}
                  </>
                </div>
                {/* <PaymentMethodButton  
                    paymentType="subscription"
                    buttonsHorizontalOrientation={true}
                    billingDetails={cashoutDetails.cashout}
                    receipt={cashoutDetails.receipt}
                /> */}
                <Payment
                  checkout={{
                    cashoutDetails: cashoutDetails?.cashout,
                    receipt: {
                      ...cashoutDetails?.receipt,
                      reasaon: "subscription",
                    },
                  }}
                />
              </div>
            </div>
          </>
          {toggled && (
            <>
              <Spacer />
              <ShopShortList
                onCLick={(v) =>
                  handleSubcription({ ...v, ...commission.full_details })
                }
                list={subscriptionShopList}
              />
            </>
          )}
        </>
      ) : (
        activavtedTab === "Jobs" && (
          <>
            <TagShortDecription
              title="Job Opportunites"
              description="We also provide you with update on job opportunites."
              outerlayerStyle="md:col-span-1"
              innerBodyStyle="px-4 sm:px-0 my-10"
              innerHeaderStyle="text-lg font-medium leading-6 text-gray-900"
              innerlayerStyle="mt-1 text-sm text-gray-600"
            />
            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-flow-col md:gap-2 items-start">
                {/* section 1 */}
                <div className="bg-white max-h-screen overflow-auto">
                  <CheckBoxWithOption
                    onChange={(e) => jobApplying(e)}
                    type="radio"
                    name="name"
                    htmlFor="name"
                    autoComplete="given-name"
                    classNameCheck="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded m-5"
                    classNameGrouped="col-span-3"
                    useDisplayComponent={true}
                    list={listed}
                  />
                </div>
                <div className="mt-5 md:mt-0 bg-white flex flex-col justify-center items-center shadow">
                  {/* section 2 */}
                  <FileBox
                    shortDescriptionOnFile="DOCX"
                    onSubmit={handleUploadFile}
                    onSelect={handleDocumentSelection}
                    fileType="docx"
                  />

                  <button
                    type="submit"
                    onClick={handleSubmitApplyJob}
                    className="font-bold w-3/4 m-2 block justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white bg-[#403d93] hover:bg-[#403d93] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Apply
                  </button>
                </div>
              </div>
              <Pagination
                from={jobs.info.HLL?.from}
                to={jobs.info.HLL?.to}
                total={jobs.info.HLL?.total}
                current={jobs.info.HLL?.current_page}
                last={jobs.info.HLL?.last_page}
                goto={(pageNumber: number) => jobs.getJob({ page: pageNumber })}
              />
            </div>
          </>
        )
      )}
      <Spacer />
    </div>
  );
}
