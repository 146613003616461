import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./components/auth/protect-route";

import Landing from "./components/page/landing";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import ForgotPassword from "./components/auth/forgot-password";
import Header from "./components/header";
import ROUTES from "./constants/routes-list";
import { NotFound } from "./components/page/error";
import Checkout from "./components/page/checkout";
import Account from "./components/page/account";
import Packages from "./components/page/packages";
import Contact from "./components/page/contact";
import useToken from "./components/hook/token";
import Success from "./components/page/Success";
import Cancel from "./components/page/cancel";
import RelatedView from "./components/page/related-view";
import Footer from "./components/footer";
// import TermAndConditionPage from "./components/page/terms-and-conditions";
// import About from "./components/page/about";
// import Safety from "./components/page/safety";
// import SellOnPXStores from "./components/page/sell-on-pxstores";
// import Returns from "./components/page/return";
// import Quality from "./components/page/Quality";
// import Purchase from "./components/page/Purchase";
// import Privacy from "./components/page/privacy";
// import Licensed from "./components/page/licensed.";
// import CustomerService from "./components/page/customer-service";
// import Carrier from "./components/page/Carrier";
// import Advertise from "./components/page/advertise.";
import AccountDeletion from "./components/page/account-deletion";
import ChatBox from "./components/ui/chat";

// from old version
import { About } from "./staticViews/About";
import { Advertise } from "./staticViews/Advertise";
import { Licensed } from "./staticViews/Licensed";
import { Safety } from "./staticViews/Safety";
import { Privacy } from "./staticViews/Privacy";
import { Terms } from "./staticViews/Terms";
import { Sell } from "./staticViews/Sell";
import { Return } from "./staticViews/Return";
import { Quality } from "./staticViews/Quality";
import { Purchase } from "./staticViews/Purchase";
import { Customer } from "./staticViews/CustomerService";
import { Carrier } from "./staticViews/Carrier";
import { Career } from "./staticViews/Career";

const App = () => {
  const [token] = useToken();
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route index element={<Landing />} />
        <Route path={ROUTES.HOME} element={<Landing />} />
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.REGISTER} element={<Register />} />
        <Route path={ROUTES.RESET} element={<ForgotPassword />} />
        <Route path={ROUTES.SUPPORT} element={<Contact />} />
        <Route path={ROUTES.PREOWN + "/:related"} element={<RelatedView />} />
        <Route path={ROUTES.BRANDS + "/:related"} element={<RelatedView />} />
        <Route path={ROUTES.SHOPS + "/:related"} element={<RelatedView />} />
        <Route
          path={ROUTES.SUBCATEGORY + "/:related"}
          element={<RelatedView />}
        />
        <Route path={ROUTES.SEARCH + "/:related"} element={<RelatedView />} />
        <Route element={<ProtectedRoute isAllowed={Boolean(token)} />}>
          <Route path={ROUTES.PACKAGES} element={<Packages />} />
          <Route path={ROUTES.ACCOUNT} element={<Account />} />
          <Route path={ROUTES.CHECKOUT} element={<Checkout />} />
          <Route path={ROUTES.SUCCESS} element={<Success />} />
          <Route path={ROUTES.CANCEL} element={<Cancel />} />
        </Route>
        <Route path={ROUTES.TERM} element={<Terms />} />
        <Route path={ROUTES.ABOUT} element={<About />} />
        <Route path={ROUTES.SAFETY} element={<Safety />} />
        <Route path={ROUTES.SELL} element={<Sell />} />
        <Route path={ROUTES.RETURNS} element={<Return />} />
        <Route path={ROUTES.QUALITY} element={<Quality />} />
        <Route path={ROUTES.PURCHASE} element={<Purchase />} />
        <Route path={ROUTES.PRIVACY} element={<Privacy />} />
        <Route path={ROUTES.LICENSED} element={<Licensed />} />
        <Route path={ROUTES.SERVICE} element={<Customer />} />
        <Route path={ROUTES.CARRIER} element={<Carrier />} />
        <Route path={ROUTES.ADVERTISE} element={<Advertise />} />
        <Route path={ROUTES.DELETION_ACOUNT} element={<AccountDeletion />} />
        <Route path={ROUTES.CAREER} element={<Career />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <ChatBox />
    </BrowserRouter>
  );
};

export default App;
