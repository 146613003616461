import React from "react";
import { Link } from "react-router-dom";
import ROUTES from "../../../constants/routes-list";

import { callouts } from "./collection.context";

type CollectionProps = {
  list: any[];
};
export default function Collection({ list = [] }: CollectionProps) {
  const products = list;
  return (
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto py-16 sm:py-24 lg:py-32 lg:max-w-none">
          <h2 className="text-2xl font-extrabold text-gray-900">Collections</h2>

          <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6">
            {products
              .sort(() => Math.random() - 0.5)
              .slice(0, 3)
              .map((item, index) => (
                <Link
                  key={index}
                  to={`${ROUTES.SHOPS}/${
                    item?.shop?.name + "-" + item?.shop?.id + "-collection"
                  }`}
                >
                  <div key={item.name} className="group relative">
                    <div className="relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                      <img
                        src={callouts[index].imageSrc}
                        alt={item.name}
                        className="w-full h-full object-center object-cover"
                      />
                    </div>
                    <h3 className="mt-6 text-sm text-gray-500">
                      <a href={item.href}>
                        <span className="absolute inset-0" />
                        {item.name}
                      </a>
                    </h3>
                    <p className="text-base font-semibold text-gray-900">
                      {item?.shop?.name}
                    </p>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
