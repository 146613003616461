/*jshint ignore:start*/
import "./common.css";
import React from "react";

import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

const classes = {
  root: "w-full",
  heading: "text-[15px] flex-shrink-0 pr-4",
  secondaryHeading: "text-[15px] text-[blue]",
};

export const Terms = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <React.Fragment>
      <div>
        <section className="jumbotron text-center py-5 my-2 md:py-10 md:my-5">
          <div className="container md:py-10">
            <h1 className="jumbotron-heading md:text-[3em]">
              TERMS AND CONDITIONS
            </h1>
            <p className="lead opacity-50 mb-0 md:mt-4">
              Information on Terms and Conditions
            </p>
          </div>
        </section>
        <div>
          <div className={classes.root}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>A</Typography>
                <Typography className={classes.secondaryHeading}>
                  Overview
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <p>
                    PX Stores that shall be referred to as “we” is an e-commerce
                    platform made up of a website and mobile application and
                    this shall be referred to as market place. PX Stores is
                    operated by Parcel Exchange Limited, which is the parent
                    company and part of a group known as Globemeth Consults
                    Int’l Limited. The aim of the market place is for the sale
                    and purchase of consumer products in every country of the
                    world.
                  </p>
                  <p>
                    These general term and conditions shall apply to buyers and
                    sellers on the marketplace and shall govern your use of the
                    marketplace and related services. By using our marketplace,
                    you accept these general terms and conditions in full. If
                    you disagree with these general terms and conditions or any
                    part of these general terms and conditions, you must not use
                    our marketplace. If you use our marketplace in the course of
                    a business or other organizational project, then by so doing
                    you:{" "}
                  </p>
                  <p>
                    (i) confirm that you have obtained the necessary authority
                    to agree to these general terms and conditions;{" "}
                  </p>
                  <p>
                    (ii) legally bind both yourself and the person, company or
                    other legal entity that operates that business or
                    organizational project, to these general terms and
                    conditions; and{" "}
                  </p>
                  <p>
                    (iii) agree that "you" in these general terms and conditions
                    shall reference both the individual user and the relevant
                    person, company or legal entity unless the context requires
                    otherwise.
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.heading}>B</Typography>
                <Typography className={classes.secondaryHeading}>
                  Registration and account
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  (a) You may not register with our marketplace if you are under
                  18 years of age (by using our marketplace or agreeing to these
                  general terms and conditions, you warrant and represent to us
                  that you are at least 18 years of age).
                  <br />
                  (b) You may register for an account with our marketplace by
                  completing and submitting the registration form on our
                  marketplace
                  <br />
                  (c) You represent and warrant that all information provided in
                  the registration form is complete and accurate. (d) If you
                  register for an account with our marketplace, you will be
                  asked to provide an email address/user ID and password and you
                  agree to the following: <br />
                  - keep your password confidential;
                  <br />
                  - notify us in writing immediately (using our contact details
                  provided at section W) if you become aware of any disclosure
                  of your password; and
                  <br />
                  - Indemnify our market place of any activity on our
                  marketplace arising out of any failure to keep your password
                  confidential, and that you may be held liable for any losses
                  arising out of such a failure.
                  <br />
                  (e) Your account shall be used exclusively by you and you
                  shall not transfer your account to any third party. If you
                  authorize any third party to manage your account on your
                  behalf this shall be at your own risk.
                  <br />
                  (f) We may suspend or cancel your account, and/or edit your
                  account details, at any time at our discretion and without
                  notice or explanation, provided that if we cancel any products
                  or services you have paid for but not received, and you have
                  not breached these general terms and conditions, we will
                  refund you in respect of the same.
                  <br />
                  (g) You may cancel your account on our marketplace by
                  contacting us as provided in section W. <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography className={classes.heading}>C</Typography>
                <Typography className={classes.secondaryHeading}>
                  Terms and conditions of sale
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  1. You acknowledge and agree that: <br />
                  (i) the marketplace provides an online location for sellers to
                  sell and buyers to purchase products;
                  <br />
                  (ii) we shall accept binding sales, on behalf of sellers, but
                  PX Stores is not a party to the transaction between the seller
                  and the buyer; and
                  <br />
                  (iii) a contract for the sale and purchase of a product or
                  products will come into force between the buyer and seller,
                  and accordingly you commit to buying or selling the relevant
                  product or products, upon the buyer’s confirmation of purchase
                  through the marketplace.
                  <br />
                  2. Subject to these general terms and conditions, the seller’s
                  terms of business shall govern the contract for sale and
                  purchase between the buyer and the seller. Aside this, the
                  following provisions will be incorporated into the contract of
                  sale and purchase between the buyer and the seller: <br />
                  (i) the price for a product will be as stated in the relevant
                  product listing;
                  <br />
                  (ii) the price for the product must include all taxes and
                  comply with applicable laws in force from time to time;
                  <br />
                  (iii) delivery charges, packaging charges, handling charges,
                  administrative charges, insurance costs, other ancillary costs
                  and charges, will only be payable by the buyer if this is
                  expressly and clearly stated in the product listing;
                  <br />
                  (iv) products must be of satisfactory quality, fit and safe
                  for any purpose specified in, and conform in all material
                  respects to, the product listing and any other description of
                  the products supplied or made available by the seller to the
                  buyer; and
                  <br />
                  (v) the seller guarantees/warrants that the seller has good
                  title to, and is the sole legal and beneficial owner of, the
                  products, and that the products are not subject to any third
                  party rights or restrictions including in respect of third
                  party intellectual property rights and/or any criminal,
                  insolvency or tax investigation or proceedings
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
              >
                <Typography className={classes.heading}>D</Typography>
                <Typography className={classes.secondaryHeading}>
                  Returns and refunds
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  1. Returns of products by buyers and acceptance of returned
                  products by sellers shall be managed by us in accordance with
                  the returns page on the marketplace, as may be amended from
                  time to time. Acceptance of returns shall be in our
                  discretion, subject to compliance with applicable laws of all
                  the territories where our market place exists.
                  <br />
                  2. Refunds in respect of returned products shall be managed in
                  accordance with the refunds page on the marketplace, as may be
                  amended from time to time. Our rules regarding refunds shall
                  be exercised in our discretion, subject to applicable laws of
                  all the territories where our market place operates. We may
                  offer refunds, at our discretion: <br />
                  (i) in respect of the product price;
                  <br />
                  (ii) local and/or international shipping fees (as stated on
                  the refunds page); and
                  <br />
                  (iii) by way of store credits, wallet refunds, vouchers,
                  mobile money transfer, bank transfers or such other method as
                  we may determine from time to time and this shall be binding
                  on buyers.
                  <br />
                  3. Returned products shall be accepted and refunds issued by
                  PX Stores, for and on behalf of the seller.
                  <br />
                  4. Changes to our returns page or refunds page shall be
                  effective in respect of all purchases made from the date of
                  publication of the change on our website.
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel6bh-content"
                id="panel6bh-header"
              >
                <Typography className={classes.heading}>E</Typography>
                <Typography className={classes.secondaryHeading}>
                  Payments
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  You must make payments due under these general terms and
                  conditions in accordance with the Payments Information and
                  Guidelines on the marketplace.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel7bh-content"
                id="panel7bh-header"
              >
                <Typography className={classes.heading}>F</Typography>
                <Typography className={classes.secondaryHeading}>
                  Rules about your content
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  (a) In these general terms and conditions, "your content"
                  means: <br />
                  (i) all works and materials (including without limitation
                  text, graphics, images, audio material, video material,
                  audio-visual material, scripts, software and files) that you
                  submit to us or our marketplace for storage or publication,
                  processing by, or onward transmission; and
                  <br />
                  (ii) all communications on the marketplace, including product
                  reviews, feedback and comments.
                  <br />
                  (b) Your content, and the use of your content by us in
                  accordance with these general terms and conditions, must be
                  accurate, complete and truthful.
                  <br />
                  (c) Your content must be appropriate, civil and tasteful, and
                  accord with generally accepted standards of etiquette and
                  behaviour on the internet, and must not: <br />
                  (i) be offensive, obscene, indecent, pornographic, lewd,
                  suggestive or sexually explicit;
                  <br />
                  (ii) depict violence in an explicit, graphic or gratuitous
                  manner; or
                  <br />
                  (iii) be blasphemous, in breach of racial or religious hatred
                  or discrimination legislation;
                  <br />
                  (iv) be deceptive, fraudulent, threatening, abusive,
                  harassing, anti-social, menacing, hateful, discriminatory or
                  inflammatory;
                  <br />
                  (v) cause annoyance, inconvenience or needless anxiety to any
                  person; or
                  <br />
                  (vi) constitute spam.
                  <br />
                  (d) Your content must not be illegal or unlawful, infringe any
                  person's legal rights, or be capable of giving rise to legal
                  action against any person (in each case in any jurisdiction
                  and under any applicable law). Your content must not infringe
                  or breach: <br />
                  (i) any copyright, moral right, database right, trademark
                  right, design right, right in passing off or other
                  intellectual property right;
                  <br />
                  (ii) any right of confidence, right of privacy or right under
                  data protection legislation;
                  <br />
                  (iii) any contractual obligation owed to any person; or
                  <br />
                  (iv) any court order
                  <br />
                  (e) You must not use our marketplace to link to any website or
                  web page consisting of or containing material that would, were
                  if posted on our marketplace, breach the provisions of these
                  general terms and conditions
                  <br />
                  (f) You must not submit to our marketplace any material that
                  is or has ever been the subject of any threatened or actual
                  legal proceedings or other similar complaint.
                  <br />
                  (g) The review function on the marketplace may be used to
                  facilitate buyer reviews on products. You shall not use the
                  review function or any other form of communication to provide
                  inaccurate, inauthentic or fake reviews.
                  <br />
                  (h) You must not interfere with a transaction by:
                  <br /> (i) contacting another user to buy or sell an item
                  listed on the marketplace outside of the marketplace; or
                  <br /> (ii) communicating with a user involved in an active or
                  completed transaction to warn them away from a particular
                  buyer, seller or item; or
                  <br /> (iii) contacting another user with the intent to
                  collect any payments
                  <br />
                  (i) You acknowledge that all users of the marketplace are
                  solely responsible for interactions with other users and you
                  shall exercise caution and good judgment in your communication
                  with users. You shall not send them personal information
                  including credit card details.
                  <br />
                  (j) We may periodically review your content and we reserve the
                  right to remove any content in our discretion for any reason
                  whatsoever. <br />
                  (k) If you learn of any unlawful material or activity on our
                  marketplace, or any material or activity that breaches these
                  general terms and conditions, you may inform us by contacting
                  us as provided at section W.
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel8bh-content"
                id="panel8bh-header"
              >
                <Typography className={classes.heading}>G</Typography>
                <Typography className={classes.secondaryHeading}>
                  Our rights to use your content
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  (a) You grant to us a worldwide, irrevocable, non-exclusive,
                  royalty-free license to use, reproduce, store, adapt, publish,
                  translate and distribute your content across our marketing
                  channels and any existing or future media.
                  <br />
                  (b) You grant to us the right to sub-license the rights
                  licensed under section G(a) <br />
                  (c) You grant to us the right to bring an action for
                  infringement of the rights licensed under section G (a).
                  <br />
                  (d) You hereby waive all your moral rights in your content to
                  the maximum extent permitted by applicable law; and you
                  warrant and represent that all other moral rights in your
                  content have been waived to the maximum extent permitted by
                  applicable law
                  <br />
                  (e) Without prejudice to our other rights under these general
                  terms and conditions, if you breach our rules on content in
                  any way, or if we reasonably suspect that you have breached
                  our rules on content, we may delete, unpublish or edit any or
                  all of your content.
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel9bh-content"
                id="panel9bh-header"
              >
                <Typography className={classes.heading}>H</Typography>
                <Typography className={classes.secondaryHeading}>
                  Use of website and mobile applications
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  (a) In this section H, the words "marketplace" and "website"
                  shall be used interchangeably to refer to PX Stores’ websites
                  and mobile applications.
                  <br />
                  (b) You may: <br />
                  (i) view pages from our website in a web browser;
                  <br />
                  (ii) download pages from our website for caching in a web
                  browser;
                  <br />
                  <br />
                  (iii) print pages from our website for your own personal and
                  non-commercial use, providing that such printing is not
                  systematic or excessive;
                  <br />
                  (iv) stream audio and video files from our website using the
                  media player on our website; and
                  <br />
                  (v) use our marketplace services by means of a web browser,
                  <br />
                  subject to the other provisions of these general terms and
                  conditions. <br />
                  (c) Except as expressly permitted by section H(b) or the other
                  provisions of these general terms and conditions, you must not
                  download any material from our website or save any such
                  material to your computer
                  <br />
                  (d) You may only use our website for your own personal and
                  business purposes in respect of selling or purchasing products
                  on the marketplace
                  <br />
                  (e) Except as expressly permitted by these general terms and
                  conditions, you must not edit or otherwise modify any material
                  on our website.
                  <br />
                  (f) Unless you own or control the relevant rights in the
                  material, you must not: <br />
                  (i) republish material from our website (including
                  republication on another website);
                  <br />
                  (ii) sell, rent or sub-license material from our website;
                  <br />
                  (iii) show any material from our website in public;
                  <br />
                  (iv) exploit material from our website for a commercial
                  purpose; or
                  <br />
                  (v) redistribute material from our website.
                  <br />
                  (g) Notwithstanding section H (f), you may forward links to
                  products on our website and redistribute our newsletter and
                  promotional materials in print and electronic form to any
                  person.
                  <br />
                  (h) We reserve the right to suspend or restrict access to our
                  website, to areas of our website and/or to functionality
                  regarding our website. We may, for example, suspend access to
                  the website during server maintenance or when we update the
                  website. You must not circumvent or bypass, or attempt to
                  circumvent or bypass, any access restriction measures on the
                  website. (i) You must not: <br />
                  (i) use our website in any way or take any action that causes,
                  or may cause, damage to the website or impairment of the
                  performance, availability, accessibility, integrity or
                  security of the website;
                  <br />
                  (ii) use our website in any way that is unethical, unlawful,
                  illegal, fraudulent or harmful, or in connection with any
                  unlawful, illegal, fraudulent or harmful purpose or activity;
                  <br />
                  (iii) hack or otherwise tamper with our website;
                  <br />
                  (iv) probe, scan or test the vulnerability of our website
                  without our permission;
                  <br />
                  (v) circumvent any authentication or security systems or
                  processes on or relating to our website;
                  <br />
                  (vi) use our website to copy, store, host, transmit, send,
                  use, publish or distribute any material which consists of (or
                  is linked to) any spyware, computer virus, Trojan horse, worm,
                  keystroke logger, rootkit or other malicious computer
                  software;
                  <br />
                  (vii) impose an unreasonably large load on our website
                  resources (including bandwidth, storage capacity and
                  processing capacity);
                  <br />
                  (viii) decrypt or decipher any communications sent by or to
                  our website without our permission;
                  <br />
                  (ix) conduct any systematic or automated data collection
                  activities (including without limitation scraping, data
                  mining, data extraction and data harvesting) on or in relation
                  to our website without our express written consent;
                  <br />
                  (x) access or otherwise interact with our website using any
                  robot, spider or other automated means, except for the purpose
                  of search engine indexing;
                  <br />
                  (xi) use our website except by means of our public interfaces;
                  <br />
                  (xii) violate the directives set out in the robots.txt file
                  for our website;
                  <br />
                  (xiii) use data collected from our website for any direct
                  marketing activity (including without limitation email
                  marketing, SMS marketing, telemarketing and direct mailing);
                  or
                  <br />
                  (xiv) do anything that interferes with the normal use of our
                  website.
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel10bh-content"
                id="panel10bh-header"
              >
                <Typography className={classes.heading}>I</Typography>
                <Typography className={classes.secondaryHeading}>
                  Copyright and trademarks
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  (a) Subject to the express provisions of these general terms
                  and conditions: <br />
                  (i) we, together with our licensors, own and control all the
                  copyright and other intellectual property rights in our
                  website and the material on our website; and
                  <br />
                  (ii) all the copyright and other intellectual property rights
                  in our website and the material on our website are reserved.
                  <br />
                  (b) PX Stores’ logos and our other registered and unregistered
                  trademarks are trademarks belonging to us; we give no
                  permission for the use of these trademarks, and such use may
                  constitute an infringement of our rights.
                  <br />
                  (c) The third party registered and unregistered trademarks or
                  service marks on our website are the property of their
                  respective owners and we do not endorse and are not affiliated
                  with any of the holders of any such rights and as such we
                  cannot grant any license to exercise such rights
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel11"}
              onChange={handleChange("panel11")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel11bh-content"
                id="panel11bh-header"
              >
                <Typography className={classes.heading}>J</Typography>
                <Typography className={classes.secondaryHeading}>
                  Data Privacy
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  (a) Buyers agree to processing of their personal data in
                  accordance with the terms of PX Stores’ Privacy and Cookie
                  Notice
                  <br />
                  (b) PX Stores shall process all personal data obtained through
                  the marketplace and related services in accordance with the
                  terms of our Privacy Policy.
                  <br />
                  (c) Sellers shall be directly responsible to buyers for any
                  misuse of their personal data and PX Stores shall bear no
                  liability to buyers in respect of any misuse by sellers of
                  their personal data.
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel12"}
              onChange={handleChange("panel12")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel12bh-content"
                id="panel12bh-header"
              >
                <Typography className={classes.heading}>K</Typography>
                <Typography className={classes.secondaryHeading}>
                  Due diligence and audit rights
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  (a) We operate an anti-money laundering compliance program and
                  reserve the right to perform due diligence checks on all users
                  of the marketplace.
                  <br />
                  (b) You agree to provide to us all such information,
                  documentation and access to your business premises as we may
                  require: <br />
                  (i) in order to verify your adherence to, and performance of,
                  your obligations under this Agreement;
                  <br />
                  (ii) for the purpose of disclosures pursuant to a valid order
                  by a court or other governmental body; or
                  <br />
                  (iii) as otherwise required by law or applicable regulation
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel13"}
              onChange={handleChange("panel13")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel13bh-content"
                id="panel13bh-header"
              >
                <Typography className={classes.heading}>L</Typography>
                <Typography className={classes.secondaryHeading}>
                  PX Stores’ role as a marketplace
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  (a) You acknowledge that: <br />
                  (i) we do not confirm the identity of all marketplace users,
                  check their credit worthiness or bona fides, or otherwise vet
                  them;
                  <br />
                  (ii) we do not check, audit or monitor all information
                  contained in listings;
                  <br />
                  (iii) we are not party to any contract for the sale or
                  purchase of products advertised on the marketplace;
                  <br />
                  (iv) we are not involved in any transaction between a buyer
                  and a seller in any way, save that we facilitate a marketplace
                  for buyers and sellers and process payments on behalf of
                  sellers;
                  <br />
                  (v) we are not the agents for any buyer or seller and
                  accordingly we will not be liable to any person in relation to
                  the offer for sale, sale or purchase of any products
                  advertised on our marketplace; furthermore we are not
                  responsible for the enforcement of any contractual obligations
                  arising out of a contract for the sale or purchase of any
                  products and we will have no obligation to mediate between the
                  parties to any such contract. <br />
                  (b) We do not warrant or represent: <br />
                  (i) the completeness or accuracy of the information published
                  on our marketplace;
                  <br />
                  (ii) that the material on the marketplace is up to date;
                  <br />
                  (iii) that the marketplace will operate without fault; or
                  <br />
                  (iv) that the marketplace or any service on the marketplace
                  will remain available.
                  <br />
                  (c) We reserve the right to discontinue or alter any or all of
                  our marketplace services, and to stop publishing our
                  marketplace, at any time in our sole discretion without notice
                  or explanation.
                  <br />
                  (d) We do not guarantee any commercial results concerning the
                  use of the marketplace.
                  <br />
                  (e) To the maximum extent permitted by applicable law and
                  subject to section M (a) below, we exclude all representations
                  and warranties relating to the subject matter of these general
                  terms and conditions, our marketplace and the use of our
                  marketplace.
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel14"}
              onChange={handleChange("panel14")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel14bh-content"
                id="panel14bh-header"
              >
                <Typography className={classes.heading}>M</Typography>
                <Typography className={classes.secondaryHeading}>
                  Limitations and exclusions of liability
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  (a) Nothing in these general terms and conditions will: <br />
                  (i) limit any liabilities in any way that is not permitted
                  under applicable law; or
                  <br />
                  (ii) exclude any liabilities or statutory rights that may not
                  be excluded under applicable law.
                  <br />
                  (b) The limitations and exclusions of liability set out in
                  this section M and elsewhere in these general terms and
                  conditions: <br />
                  (i) are subject to section M (a); and
                  <br />
                  (ii) govern all liabilities arising under these general terms
                  and conditions or relating to the subject matter of these
                  general terms and conditions, including liabilities arising in
                  contract, in tort (including negligence) and for breach of
                  statutory duty, except to the extent expressly provided
                  otherwise in these general terms and conditions
                  <br />
                  (c) In respect of the services offered to you free of charge
                  we will not be liable to you for any loss or damage of any
                  nature whatsoever.
                  <br />
                  (d) Our aggregate liability to you in respect of any contract
                  to provide services to you under these general terms and
                  conditions shall not exceed the total amount paid and payable
                  to us under the contract. Each separate transaction on the
                  marketplace shall constitute a separate contract for the
                  purpose of this section M (d).
                  <br />
                  (e) Besides section M (d) above, we will not be liable to you
                  for any loss or damage of any nature, including in respect of:{" "}
                  <br />
                  (i) any losses occasioned by any interruption or dysfunction
                  to the website;
                  <br />
                  (ii) any losses arising out of any event or events beyond our
                  reasonable control;
                  <br />
                  (iii) any business losses, including (without limitation) loss
                  of or damage to profits, income, revenue, use, production,
                  anticipated savings, business, contracts, commercial
                  opportunities or goodwill;
                  <br />
                  (iv) any loss or corruption of any data, database or software;
                  or
                  <br />
                  (v) any special, indirect or consequential loss or damage.
                  <br />
                  (f) We accept that we have an interest in limiting the
                  personal liability of our officers and employees and, having
                  regard to that interest, you acknowledge that we are a limited
                  liability entity; you agree that you will not bring any claim
                  personally against our officers or employees in respect of any
                  losses you suffer in connection with the marketplace or these
                  general terms and conditions (this will not limit or exclude
                  the liability of the limited liability entity itself for the
                  acts and omissions of our officers and employees).
                  <br />
                  (g) Our marketplace includes hyperlinks to other websites
                  owned and operated by third parties; such hyperlinks are not
                  recommendations. We have no control over third party websites
                  and their contents, and we accept no responsibility for them
                  or for any loss or damage that may arise from your use of
                  them.
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel15"}
              onChange={handleChange("panel15")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel15bh-content"
                id="panel15bh-header"
              >
                <Typography className={classes.heading}>N</Typography>
                <Typography className={classes.secondaryHeading}>
                  Indemnification
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  1. You hereby indemnify us, and undertake to keep us
                  indemnified, against:
                  <br />
                  o any and all losses, damages, costs, liabilities and expenses
                  (including without limitation legal expenses and any amounts
                  paid by us to any third party in settlement of a claim or
                  dispute) incurred or suffered by us and arising directly or
                  indirectly out of your use of our marketplace or any breach by
                  you of any provision of these general terms and conditions or
                  the PX Stores codes, policies or guidelines; and <br />
                  o any VAT liability or other tax liability that we may incur
                  in relation to any sale, supply or purchase made through our
                  marketplace, where that liability arises out of your failure
                  to pay, withhold, declare or register to pay any VAT or other
                  tax properly due in any jurisdiction.
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel16"}
              onChange={handleChange("panel16")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel16bh-content"
                id="panel16bh-header"
              >
                <Typography className={classes.heading}>O</Typography>
                <Typography className={classes.secondaryHeading}>
                  Breaches of these general terms and conditions
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  1. If we permit the registration of an account on our
                  marketplace it will remain open indefinitely, subject to these
                  general terms and conditions.
                  <br />
                  2. If you breach these general terms and conditions, or if we
                  reasonably suspect that you have breached these general terms
                  and conditions or any PX Stores codes, policies or guidelines
                  in any way we may: <br />
                  o temporarily suspend your access to our marketplace;
                  <br />
                  o permanently prohibit you from accessing our marketplace;
                  <br />
                  o block computers using your IP address from accessing our
                  marketplace;
                  <br />
                  o contact any or all of your internet service providers and
                  request that they block your access to our marketplace;
                  <br />
                  o suspend or delete your account on our marketplace; and/or
                  <br />
                  o commence legal proceeding against you, whether for breach of
                  contract or otherwise.
                  <br />
                  3. Where we suspend, prohibit or block your access to our
                  marketplace or a part of our marketplace you must not take any
                  action to circumvent such suspension or prohibition or
                  blocking (including without limitation creating and/or using a
                  different account).
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel17"}
              onChange={handleChange("panel17")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel17bh-content"
                id="panel17bh-header"
              >
                <Typography className={classes.heading}>P</Typography>
                <Typography className={classes.secondaryHeading}>
                  Entire Agreement
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  These general terms and conditions and the PX Stores’ codes,
                  policies and guidelines (and in respect of sellers the seller
                  terms and conditions) shall constitute the entire agreement
                  between you and us in relation to your use of our marketplace
                  and shall supersede all previous agreements between you and us
                  in relation to your use of our marketplace
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel18"}
              onChange={handleChange("panel18")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel18bh-content"
                id="panel18bh-header"
              >
                <Typography className={classes.heading}>Q</Typography>
                <Typography className={classes.secondaryHeading}>
                  Hierarchy
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Should these general terms and conditions, the seller terms
                  and conditions, and the PX Stores codes, policies and
                  guidelines be in conflict, these terms and conditions, the
                  seller terms and conditions and the PX Stores codes, policies
                  and guidelines shall prevail in the order here stated.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel19"}
              onChange={handleChange("panel19")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel19bh-content"
                id="panel19bh-header"
              >
                <Typography className={classes.heading}>R</Typography>
                <Typography className={classes.secondaryHeading}>
                  Variation
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  (a) We may revise these general terms and conditions, the
                  seller terms and conditions, and the PX Stores codes, policies
                  and guidelines from time to time.
                  <br />
                  (b) The revised general terms and conditions shall apply from
                  the date of publication on the marketplace.
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel20"}
              onChange={handleChange("panel20")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel20bh-content"
                id="panel20bh-header"
              >
                <Typography className={classes.heading}>S</Typography>
                <Typography className={classes.secondaryHeading}>
                  Severability
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  (a) If a provision of these general terms and conditions is
                  determined by any court or other competent authority to be
                  unlawful and/or unenforceable, the other provisions will
                  continue in effect.
                  <br />
                  (b) If any unlawful and/or unenforceable provision of these
                  general terms and conditions would be lawful or enforceable if
                  part of it were deleted, that part will be deemed to be
                  deleted, and the rest of the provision will continue in
                  effect.
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel21"}
              onChange={handleChange("panel21")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel21bh-content"
                id="panel21bh-header"
              >
                <Typography className={classes.heading}>T</Typography>
                <Typography className={classes.secondaryHeading}>
                  Assignment
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  1. You hereby agree that we may assign, transfer, sub-contract
                  or otherwise deal with our rights and/or obligations under
                  these general terms and conditions.
                  <br />
                  2. You may not without our prior written consent assign,
                  transfer, sub-contract or otherwise deal with any of your
                  rights and/or obligations under these general terms and
                  conditions.
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel22"}
              onChange={handleChange("panel22")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel22bh-content"
                id="panel22bh-header"
              >
                <Typography className={classes.heading}>U</Typography>
                <Typography className={classes.secondaryHeading}>
                  Third party rights
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  1. A contract under these general terms and conditions is for
                  our benefit and your benefit, and is not intended to benefit
                  or be enforceable by any third party
                  <br />
                  2. The exercise of the parties' rights under a contract under
                  these general terms and conditions is not subject to the
                  consent of any third party
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel23"}
              onChange={handleChange("panel23")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel23bh-content"
                id="panel23bh-header"
              >
                <Typography className={classes.heading}>V</Typography>
                <Typography className={classes.secondaryHeading}>
                  Law and jurisdiction
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  1. These general terms and conditions shall be governed by and
                  construed in accordance with the laws of any territory where
                  our market place operates.
                  <br />
                  2. Any disputes relating to these general terms and conditions
                  shall be subject to the exclusive jurisdiction of the courts
                  of any territory where our market place operates.
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel24"}
              onChange={handleChange("panel24")}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel24bh-content"
                id="panel24bh-header"
              >
                <Typography className={classes.heading}>W</Typography>
                <Typography className={classes.secondaryHeading}>
                  Our Market Place details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  The marketplace is operated by PX Stores Limited. We are
                  registered in England and Wales with Company Number 13587358,
                  and our head office is situated at Kemp House, 160 City Road,
                  London EC1V 2NX, England. Our Africa Office is located at 18A
                  Nduka Osadebey Street, Ajao Estate Lagos. You can contact us
                  by using our contact form or send an email to
                  pxstores@pxstores.com
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
