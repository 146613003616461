const ROUTES = {
  LOGIN: "/login",
  REGISTER: "/register",
  RESET: "/forgot-password",
  HOME: "/",
  ORDERS: "/ordders",
  PACKAGES: "/packages",
  ACCOUNT: "/account",
  CHECKOUT: "/checkout",
  SUPPORT: "/support",
  BRANDS: "/brands",
  PREOWN: "/pre-own",
  SHOPS: "/collections",
  CATEGORY: "/category",
  SUBCATEGORY: "/subcategory",
  SUCCESS: "/success",
  CANCEL: "/cancel",
  SEARCH: "/search",
  TERM: "/terms-and-conditions",
  ABOUT: "/about",
  SAFETY: "/safety",
  SELL: "/sell",
  RETURNS: "/returns",
  QUALITY: "/quality",
  PURCHASE: "/purchase",
  PRIVACY: "/privacy",
  LICENSED: "/licensed",
  SERVICE: "/service",
  CARRIER: "/carrier",
  ADVERTISE: "/advertise",
  DELETION_ACOUNT: "/deletion-account",
  CAREER: "/career",
};
export default ROUTES;
