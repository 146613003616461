import React from "react";

export default function Promo() {
    return (
      <div className="relative bg-white overflow-hidden">
        <div className="pt-16 pb-80 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
            <div className="sm:max-w-lg">
              <h1 className="text-4xl font font-extrabold tracking-tight text-gray-900 sm:text-6xl">
                Shoppers' choice has finally come
              </h1>
              <p className="mt-4 text-xl text-gray-500">
                Get all your needs at one spot, from our best collections from the best dealers round the world.
              </p>
            </div>
            <div>
              <div className="mt-10">
                {/* Decorative image grid */}
                <div
                  aria-hidden="true"
                  className="pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full"
                >
                  <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                    <div className="flex items-center space-x-6 lg:space-x-8">
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-44 h-64 rounded-lg overflow-hidden sm:opacity-0 lg:opacity-100">
                          <img
                            src="https://m.media-amazon.com/images/I/81B0fT53TOL._AC_UL320_.jpg"
                            alt=""
                            className="max-w-[176px] h-full object-center object-scale-down"
                          />
                        </div>
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <img  
                            src="//ae01.alicdn.com/kf/Sb0eff7a6cfc1404db09b451c5419b46ct/Japan-Korea-Star-Moon-Bracelet-For-Women-Girls-Fashion-Pink-Crystal-Pearl-Chain-Bracelet-Wholesale-Designer.jpg_220x220xz.jpg_.webp"
                            alt=""
                            className="max-w-[176px] h-full object-center object-scale-down"
                          />
                        </div>
                      </div>
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <img
                            src="https://ng.jumia.is/unsafe/fit-in/300x300/filters:fill(white)/product/18/7744801/1.jpg?6487"
                            alt=""
                            className="max-w-[176px] h-full object-center object-scale-down hidden"
                          />
                        </div>
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <img
                            src="//ae01.alicdn.com/kf/H120bc843f7c64f46b85489fd788a49a8D/Anti-Vibration-Feet-Pads-Rubber-Mat-Slipstop-Silent-Universal-Washing-Machine-Refrigerator-Furniture-Fixed-Raiser-Dampers.jpg_220x220xz.jpg_.webp"
                            alt=""
                            className="max-w-[176px] h-full object-center object-scale-down"
                          />
                        </div>
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <img
                            src="https://media.istockphoto.com/photos/businessman-posing-confidently-picture-id468828120?k=20&m=468828120&s=612x612&w=0&h=P7ZWtTEkikvSoYf6KDkXWGD1U_06KfuA_uk4ThR7Aw8="
                            alt=""
                            className="max-w-[176px] h-full object-center object-cover"
                          />
                        </div>
                      </div>
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <img
                            src="https://tailwindui.com/img/ecommerce-images/home-page-03-hero-image-tile-06.jpg"
                            alt=""
                            className="max-w-[176px] h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <img
                            src="https://i.ebayimg.com/images/g/EiUAAOSw65VilgcZ/s-l225.jpg"
                            alt=""
                            className="max-w-[176px] h-full object-center object-scale-down"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                {/* <a
                  href="#"
                  className="inline-block text-center bg-indigo-600 border border-transparent rounded-md py-3 px-8 font-medium text-white hover:bg-indigo-700"
                >
                  Shop Collection
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  