import React from "react";

type OrderReciptProps = {
    delivery_fee:number,
    delivery_fee_fxws_percent:number,
    payment_gateway:"PAYSTACK" | "PAYPAL",
    shipping_address:{
        street_name:string
        city:string
        state:string
        country:string
        zip:string
    }
    status:number
    items:any[]
    total_items:number 
    total_price:number 
    total_price_fxws:number 
    total_wieght:number 
    isEmpty:boolean
    userID:number
    currency:string
}

export const issueOrderReceipt = ({ 
    delivery_fee = 0, 
    delivery_fee_fxws_percent = 0, 
    payment_gateway, 
    shipping_address, 
    status = 1,
    items, 
    total_items, 
    total_price, 
    total_price_fxws, 
    total_wieght, 
    isEmpty,
    userID,
    currency
}:OrderReciptProps) => {

    const orderRecipt = {
        "total_items":total_items,
        "total_price":total_price,
        "total_price_fxws":total_price_fxws,
        "total_wieght":total_wieght,
        "sales_tax":0,
        // "onwer_id":userID,
        "delivery_fee":delivery_fee,
        "delivery_fee_fxws_percent":delivery_fee_fxws_percent,
        "payment_gateway":payment_gateway,
        "shipping_address":shipping_address,
        "customer_id":userID,
        // "customer_contact":"+2435677477488",
        "currency":currency,
        "isEmpty":isEmpty,
        "status": status,
        "items":items
    }
    
    return orderRecipt;
}


type SubcriptionProps = {
    owner_id: number
    shop_id?: number
    type:  "advert" | "premier" | "commission" | "",
    nature?: "",
    period: "Annually" | "Monthly" | "",
    unit?: "value",
    currency: string,
    total_clicks: number,
    amount: number
}
export const issueSubcriptionReceipt = ({
    owner_id,
    shop_id = 0,
    type = "",
    nature = "",
    period = "",
    unit = "value",
    currency,
    total_clicks = 0,
    amount = 0
}:SubcriptionProps) => {

    const subcriptionReceipt = {
        owner_id,
        shop_id,
        type,
        nature,
        period,
        unit,
        currency,
        total_clicks,
        amount
    }
    
    return subcriptionReceipt;
}
