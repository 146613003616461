
export default function useToken():[string | null,()=>void, number | null] {
        
    const clearToken = () => {
        return window.localStorage.removeItem('token');
    }

    
    const response = window.localStorage.getItem('token'),
    separator = response?.lastIndexOf(".");
    let token = response?.slice(0,separator) as string,
    // @ts-ignore
    userID = response?.slice(separator!+1) as number;

    return [token, clearToken, userID];
}
