/*jshint ignore:start*/
import "./common.css";
import React from "react";
import privacyImage from "../staticImages/privacy.jpg";
import { Typography, List, ListItem, ListItemText, Grid } from "@mui/material";

const classes = {
  container: {
    paddingLeft: "5rem",
    paddingRight: "5rem",
    backgroundColor: "white",
    fontSize: "14px",
    justifyContent: "center",
  },
  Text: {
    color: "black",
    alignItems: "center",
  },
  TextHeader: {
    color: "black",
    alignItems: "center",
    fontWeight: "bold",
    paddingTop: "1rem",
  },
};

export const Privacy = () => {
  return (
    <div data-aos="fade-up" data-aos-duration="1000">
      <section className="jumbotron text-center py-5 my-2 md:py-10 md:my-5">
        <div className="container md:py-10">
          <h1 className="jumbotron-heading md:text-[3em]">PRIVACY POLICY</h1>
          <p className="lead opacity-50 mb-0 md:mt-4">
            Information on privacy policy
          </p>
        </div>
      </section>
      <img className="privacy w-full h-full" src={privacyImage} />
      <div
        class="card-body"
        data-aos="fade-up"
        data-aos-duration="1000"
        style={{ backgroundColor: "white" }}
      >
        <Grid
          item
          xs={12}
          sm
          container
          direction="column"
          className={classes.container}
        >
          <div>
            <Typography
              className={classes.TextHeader}
              variant="body1"
              component="p"
            >
              {
                "This Privacy Policy provides information on how PX Stores collects and processes your personal data when you visit our website or mobile applications. "
              }
            </Typography>
            <Typography
              className={classes.TextHeader}
              variant="body1"
              component="p"
            >
              {"(a). The Data We Collect About You?"}
            </Typography>
            <Typography className={classes.Text} variant="body1" component="p">
              {
                "We collect your personal data in order to provide and continually improve our products and services. We may collect, use, store and transfer the following different kinds of personal data about you or marketing and personal data optimization purposes. PX Stores also uses Google Digital Marketing to propose targeted offers. "
              }
            </Typography>
            <Typography className={classes.Text} variant="body1" component="p">
              {"To find out more: "}
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    "•	Information you provide to us: We receive and store the information you provide to us including your identity data, contact data, delivery address and financial data."
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    "•	Information on your use of our website and/or mobile applications: We automatically collect and store certain types of information regarding your use of the PX Stores marketplace including information about your searches, views, downloads and purchases."
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    "•	Information from third parties and publicly available sources: We may receive information about you from third parties including our carriers; payment service providers; merchants/brands; and advertising service providers."
                  }
                />
              </ListItem>
            </List>
          </div>
          <div>
            <Typography
              className={classes.TextHeader}
              variant="body1"
              component="p"
            >
              {"(b). Cookies and How We Use Them"}
            </Typography>
            <Typography className={classes.Text} variant="body1" component="p">
              {
                "A cookie is a small file of letters and numbers that we put on your computer if you agree. "
              }
            </Typography>
            <Typography className={classes.Text} variant="body1" component="p">
              {
                "Cookies allow us to distinguish you from other users of our website and mobile applications, and this helps us to provide you with an enhanced browsing experience. For example we use cookies for the following purposes: "
              }
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    "•	Recognising and counting the number of visitors and to see how visitors navigate around our site when they are using it (this helps us to improve the way our website works, for example by ensuring that users can find what they are looking for)."
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    "•	Identifying your preferences and subscriptions for example language settings, saved items, items stored in your basket and Premier Membership."
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    "•	Sending you newsletters and commercial/advertising messages tailored to your interests. "
                  }
                />
              </ListItem>
            </List>
            <Typography className={classes.Text} variant="body1" component="p">
              {
                "Our approved third parties may also set cookies when you use our marketplace. Third parties include search engines, providers of measurement and analytics services, social media networks and advertising companies. "
              }
            </Typography>
          </div>
          <div>
            <Typography
              className={classes.TextHeader}
              variant="body1"
              component="p"
            >
              {"(c). How We Use Your Personal Data"}
            </Typography>
            <Typography className={classes.Text} variant="body1" component="p">
              {
                "We use your personal data to operate, provide, develop and improve the products and services that we offer, including the following: "
              }
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary={"•	Registering you as a new customer."} />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={"•	Processing and delivering your orders."}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={"•	Managing your relationship with us."}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    "•	Enabling you to participate in promotions, competitions and surveys."
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    "•	Improving our website, applications, products and services"
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    "•	Recommending/advertising products or services which may be of interest to you."
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    "•	Complying with our legal obligations, including verifying your identity where necessary."
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={"•	Detecting fraud."} />
              </ListItem>
            </List>
          </div>
          <div>
            <Typography
              className={classes.TextHeader}
              variant="body1"
              component="p"
            >
              {"(d). How We Share Your Personal Data"}
            </Typography>
            <Typography className={classes.Text} variant="body1" component="p">
              {
                "We may need to share your personal data with third parties for the following purposes: "
              }
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    "•	Sale of products and services: In order to deliver your products and services purchased on our marketplace from third parties, we may be required to provide your personal data to such third parties. "
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    "•	Working with third party service providers: We engage third parties to perform certain functions on our behalf. Examples include fulfilling orders for products or services, delivering packages, analyzing data, providing marketing assistance, processing payments, transmitting content, assessing and managing credit risk, and providing customer service."
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    "•	Business transfers: As we continue to develop our business, we might sell or buy other businesses or services. In such transactions, customer information may be transferred together with other business assets."
                  }
                />
              </ListItem>
              <Typography
                className={classes.Text}
                variant="body1"
                component="p"
              >
                {"When we share your personal data with third parties we:"}
              </Typography>
              <ListItem>
                <ListItemText
                  primary={
                    "•	Detecting fraud and abuse: We release account and other personal data to other companies and organizations for fraud protection and credit risk reduction, and to comply with the law. "
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    "•	Require them to agree to use your data in accordance with the terms of this Privacy policy. Our Privacy Policy are in accordance with the law; and \n Only permit them to process your personal data for specified purposes and in accordance with our instructions. We do not allow our third-party service providers to use your personal data for their own purposes."
                  }
                />
              </ListItem>
            </List>
          </div>
          <div>
            <Typography
              className={classes.TextHeader}
              variant="body1"
              component="p"
            >
              {"(e). International Transfers"}
            </Typography>
            <Typography className={classes.Text} variant="body1" component="p">
              {
                "We may transfer your personal data to locations in another country, if this is permissible pursuant to applicable laws in your location. There are inherent risks in such transfers. "
              }
            </Typography>
            <Typography className={classes.Text} variant="body1" component="p">
              {
                "In the event of international transfers of your personal data, we shall put in place measures necessary to protect your data, and we shall continue to respect your legal rights pursuant to the terms of this Privacy policy and applicable laws in your location. "
              }
            </Typography>
          </div>
          <div>
            <Typography
              className={classes.TextHeader}
              variant="body1"
              component="p"
            >
              {"(f). Data Security"}
            </Typography>
            <Typography className={classes.Text} variant="body1" component="p">
              {
                "We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. "
              }
            </Typography>
            <Typography className={classes.Text} variant="body1" component="p">
              {
                "In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business. They will only process your personal data on our instructions and they are subject to a duty of confidentiality. "
              }
            </Typography>
            <Typography className={classes.Text} variant="body1" component="p">
              {
                "We have procedures in place to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so. "
              }
            </Typography>
          </div>
          <div>
            <Typography
              className={classes.TextHeader}
              variant="body1"
              component="p"
            >
              {"(g). Your Legal Rights"}
            </Typography>
            <Typography className={classes.Text} variant="body1" component="p">
              {
                "It is important that the personal data we hold about you is accurate and current. Please keep us updated if your personal data changes during your relationship with us. "
              }
            </Typography>
            <Typography className={classes.Text} variant="body1" component="p">
              {
                "Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to access, correct or erase your personal data, object to or restrict processing of your personal data, and unsubscribe from our emails and newsletters. "
              }
            </Typography>
          </div>
          <div>
            <Typography
              className={classes.TextHeader}
              variant="body1"
              component="p"
            >
              {"(h). More information"}
            </Typography>
            <Typography className={classes.Text} variant="body1" component="p">
              {
                "For more information on how we process your personal data or you wish to exercise your legal rights in respect of your personal data, please contact pxstores@pxstores.com. "
              }
            </Typography>
          </div>
        </Grid>
      </div>
    </div>
  );
};
