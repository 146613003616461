import React, { SyntheticEvent } from "react";

import { HorizontalLineAcross } from "../horizontal-line-across";
import { InputBox } from "../input-box";
import { ListItems } from "../item-list";
import { SelectBox } from "../select-box";
import TableLayout from "../table";
import { TagShortDecription } from "../tag-short-decription";
import { FormLayout } from "../form-layout";
import { usePersonal } from "../../hook/user-detail";
import { useUser } from "../../hook/use-user";
import useToken from "../../hook/token";
import { useModal } from "../modal/modal.context";
import { useAddress } from "../../hook/use-addresses";
import { useSubcription } from "../../hook/use-subcription";
import Loader from "../spinner";
import { useOrder } from "../../hook/use-order";
import { Spacer } from "../../ui/spacer";
import { useConverter } from "../../hook/currency-conversion";
import { SelectCountry } from "../selection-box";
import ROUTES from "../../../constants/routes-list";

export const userForm = [
  { key: "name", value: "Name" },
  { key: "email", value: "Email" },
  { key: "phone", value: "Phone Number" },
];
export const addressForm = [
  { key: "title", value: "Name" },
  { key: "street_name", value: "Street Address" },
  // { key: "city", value: "City" },
  // { key: "state", value: "State  / Province" },
  // { key: "country", value: "Country" },
  { key: "zip", value: "Zip / Post Code" },
];
export const orderForm = [
  { key: "tracking_number", value: "Tracking number" },
  { key: "status", value: "Status" },
  { key: "amount", value: "Amount" },
  { key: "paid_total", value: "Paid total" },
  { key: "total", value: "Total" },
  { key: "payment_gateway", value: "Payment Method" },
  { key: "delivery_fee", value: "Delivery Fee" },
  { key: "number_of_items", value: "Items Count" },
];

function Profile() {
  const { data } = usePersonal(true);
  const [token] = useToken();
  const { openModal } = useModal();
  const { userUpdate, info, error } = useUser();
  const address = useAddress();
  const subcriptions = useSubcription();
  const [selectedAddress, setselectedAddress] = React.useState<any>(null);
  const order = useOrder();
  const { iforex } = useConverter();

  const user: any = data; //preset
  const makeTokenReady: typeof token = token; //preset

  // lists
  let addressez: any[] = address.info.HLL
    ? address.info.HLL.map((v) => {
        return {
          ...v,
          city: v.details.city,
          country: v.details.country,
          state: v.details.state,
          street_name: v.details.street_name,
          zip: v.details.zip,
        };
      }).filter((v: any) => v.customer_id == user?.id)
    : [];

  let orders = order.info.HLL?.data
    ? order.info.HLL?.data
        .filter((v: any) => v.parent_id === null && v.user.id == user?.id)
        .map((v: any) => ({
          ...v,
          paid_total: iforex({ from: v.currency, value: v.paid_total_fxws }),
          total: iforex({ from: v.currency, value: v.total_fxws }),
          delivery_fee: iforex({ from: v.currency, value: v.delivery_fee }),
          delivery_fee_fxws: v.delivery_fee,
        }))
    : [];

  let subcriptionz: any[] = subcriptions.info.HLL?.data
    ? subcriptions.info.HLL?.data
        .map((value: any) => ({
          customer_id: value.customer_id,
          type:
            value.type.charAt(0).toUpperCase() +
            value.type.slice(1) +
            " Subscription",
          period: value.period,
          total_clicks: value.total_clicks === 0 ? null : value.total_clicks,
          cost: value.amount,
          currency: value.currency,
          activation: value.start_date,
          expiration: value.end_date,
          is_active: value.is_active === 1 ? "active" : "inactivate",
        }))
        .filter((v: any) => v.customer_id == user?.id)
    : [];

  React.useEffect(() => {
    address.getAddress({ page: 1 });
    order.getOrder({ page: 1 });
    subcriptions.getSubcription({ page: 1 });
  }, [data]);

  const handleProfile = async (e: SyntheticEvent) => {
    e.preventDefault();

    if (!token) {
      return openModal({
        view: "ALERT",
        payload: "Please login and come back to proceed",
      });
    }

    if (!user) {
      return openModal({
        view: "ALERT",
        payload:
          "It seems like your session has ended. We suggest try reloading you browser, Otherwise login again",
      });
    }

    const target = e.target as typeof e.target & {
      name: {
        value: string;
      };
      email: {
        value: string;
      };
      phone: {
        value: string;
      };
    };
    let payload = {
      name: target.name.value,
      email: target.email.value,
      phone: target.phone.value,
      abilities: user?.abilities,
      id: user?.id,
    };

    // return console.log("input",payload, makeTokenReady);
    await userUpdate(payload, makeTokenReady);

    setTimeout(() => {
      return address.info?.status && window.location.reload();
    }, 3000);
  };
  const handleAddress = async (e: SyntheticEvent) => {
    e.preventDefault();

    if (!token) {
      return openModal({
        view: "ALERT",
        payload: "Please login and come back to proceed",
      });
    }

    if (!user) {
      return openModal({
        view: "ALERT",
        payload:
          "It seems like your session has ended. We suggest try reloading you browser, Otherwise login again",
      });
    }

    if (addressez.length >= 5) {
      return openModal({
        view: "ALERT",
        payload:
          "You have reached maximum address entries. You can delete by double clicking on anyone",
      });
    }

    const target = e.target as typeof e.target & {
      title: {
        value: string;
      };
      email: {
        value: string;
      };
      street_name: {
        value: string;
      };
      city: {
        value: string;
      };
      state: {
        value: string;
      };
      country: {
        value: string;
      };
      zip: {
        value: string;
      };
      country_coordinate: {
        value: any;
      };
      state_coordinate: {
        value: any;
      };
      city_coordinate: {
        value: any;
      };
    };

    let cityNeedle = target.city.value.indexOf("{");
    let stateNeedle = target.state.value.indexOf("{");
    let countryNeedle = target.country.value.indexOf("{");
    let city =
      cityNeedle !== -1
        ? JSON.parse(target.city.value)?.city_name
        : target.city.value;
    let state =
      stateNeedle !== -1
        ? JSON.parse(target.state.value)?.state_name
        : target.state.value;
    let country =
      countryNeedle !== -1
        ? JSON.parse(target.country.value)?.name
        : target.country.value;

    let payload = {
      details: {
        city: city ? city : selectedAddress.details.city_name,
        city_name: city ? city : selectedAddress.details.city_name,
        city_coordinate: selectedAddress.details.city_coordinate,
        country: country ? country : selectedAddress.details.country_name,
        country_code: selectedAddress.details.country_code,
        country_name: country ? country : selectedAddress.details.country_name,
        country_coordinate: selectedAddress.details.country_coordinate,
        state: state ? state : selectedAddress.details.state_name,
        state_code: selectedAddress.details.state_code,
        state_name: state ? state : selectedAddress.details.state_name,
        state_coordinate: selectedAddress.details.state_coordinate,
        street_name:
          target.street_name.value ?? selectedAddress.details.street_name,
        zip: target.zip.value ?? selectedAddress.details.zip,
      },
      onwer_id: user?.id,
      title: target.title.value,
    };

    // @ts-ignore
    // return console.log("payload", payload);
    await address.createOrUpdateAddress(payload);
    setselectedAddress(null);

    setTimeout(() => {
      return address.info?.status && window.location.reload();
    }, 3000);
  };

  if (!token) {
    openModal({
      view: "ALERT",
      payload: "Please login and come back to proceed",
    });
    return <></>;
  }

  // const status = [addressez].every((v) => v.length > 0);
  // if (!status) {
  //   return <Loader />;
  // }

  return (
    <div className="lg:mx-[10rem] lg:my-10 md:m-10">
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <TagShortDecription
            title="Personal Information"
            description="You can update your psersonal information"
            outerlayerStyle="md:col-span-1"
            innerBodyStyle="px-4 sm:px-0"
            innerHeaderStyle="text-lg font-medium leading-6 text-gray-900"
            innerlayerStyle="mt-1 text-sm text-gray-600"
          />
          <div className="mt-5 md:mt-0 md:col-span-2">
            {info?.query && <div className="text-blue-700">{info?.query}</div>}
            {error && <div className="text-red-700">{error}</div>}
            <FormLayout onsubmit={handleProfile}>
              {userForm.map((value, index) => {
                let inputItem: any =
                  data && Object.entries(data).find((v) => v[0] === value.key);
                return (
                  <InputBox
                    key={index}
                    type="text"
                    name={value.key}
                    autoComplete={value.value}
                    classNameInput="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    classNameLabel="block text-sm font-medium text-gray-700"
                    classNameGrouped="col-span-6"
                    defaultValue={inputItem ? inputItem[1] : ""}
                    placeholder={value.value}
                  />
                );
              })}
            </FormLayout>
          </div>
        </div>
        <a href={ROUTES.DELETION_ACOUNT} className="text-red-500">
          Delete Account
        </a>
      </div>

      <HorizontalLineAcross />
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <TagShortDecription
            title="Personal Address"
            description="You can upate / add your address. Refresh anytime"
            outerlayerStyle="md:col-span-1"
            innerBodyStyle="px-4 sm:px-0"
            innerHeaderStyle="text-lg font-medium leading-6 text-gray-900"
            innerlayerStyle="mt-1 text-sm text-gray-600"
          />
          <div className="mt-5 md:mt-0 md:col-span-2">
            {address.info?.query && (
              <div className="text-blue-700">{address.info?.query}</div>
            )}
            {address.error && (
              <div className="text-red-700">{address.error}</div>
            )}
            <FormLayout
              onsubmit={handleAddress}
              setClearButtonTitle={true}
              onClear={() => setselectedAddress(null)}
            >
              {addressForm.map((value, index) => {
                let inputItem: any =
                  selectedAddress &&
                  Object.entries(selectedAddress).find(
                    (v) => v[0] === value.key
                  );
                return (
                  <InputBox
                    key={index}
                    type="text"
                    name={value.key}
                    autoComplete={value.value}
                    classNameInput="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    classNameLabel="block text-sm font-medium text-gray-700"
                    classNameGrouped="col-span-6"
                    defaultValue={inputItem ? inputItem[1] : ""}
                    placeholder={value.value}
                  />
                );
              })}
              <SelectCountry
                // enableCountry={false}
                // enableState={false}
                // enableCity={false}
                autoTitle={false}
                countrySelected={(v: any) => {
                  setselectedAddress({
                    ...selectedAddress,
                    details: {
                      ...v,
                      ...selectedAddress?.details,
                      country: v.country_name,
                      country_coordinate: {
                        latitude: v.latitude,
                        longitude: v.longitude,
                      },
                    },
                  });
                }}
                stateSelected={(v: any) => {
                  setselectedAddress({
                    ...selectedAddress,
                    details: {
                      ...v,
                      ...selectedAddress?.details,
                      state: v.state_name,
                      state_coordinate: {
                        latitude: v.latitude,
                        longitude: v.longitude,
                      },
                    },
                  });
                }}
                citySelected={(v: any) => {
                  setselectedAddress({
                    ...selectedAddress,
                    details: {
                      ...v,
                      ...selectedAddress?.details,
                      city: v.city_name,
                      city_coordinate: {
                        latitude: v.latitude,
                        longitude: v.longitude,
                      },
                    },
                  });
                }}
                defaultCountry={selectedAddress?.country}
                defaultState={selectedAddress?.state}
                defaultCity={selectedAddress?.city}
              />
            </FormLayout>
          </div>
        </div>
      </div>

      <HorizontalLineAcross />
      <div className="overflow-auto pl-[10px] sm:pl-0 sm:overflow-none">
        <ListItems
          list={addressez}
          showCount={true}
          maxLengthExpection={5}
          layoutStyli="flex items-start shadow overflow-auto sm:rounded-md mb-[100px]"
          card={{
            frameStyli: "shadow rounded-md sm:w-2/3 min-w-[300px] mx-[10px]",
            itemStyli: "border-b border-gray-100",
          }}
          pattern="key"
          onSelect={(v) =>
            setselectedAddress({
              ...v,
              ...v.details,
            })
          }
          onDelete={(v) => {
            if (
              window.confirm("Are you sure you want to delete this address? ")
            ) {
              address.deleteAddress({
                id: v.id,
                type: "clear",
              });

              setTimeout(() => {
                return address.info?.status && window.location.reload();
              }, 3000);
            }
          }}
        />
      </div>

      <HorizontalLineAcross />
      <div className="overflow-auto pl-[10px] sm:pl-0 sm:overflow-none">
        <ListItems
          list={subcriptionz}
          showCount={true}
          maxLengthExpection={10}
          layoutStyli="flex items-start shadow overflow-auto sm:rounded-md mb-[100px]"
          card={{
            frameStyli: "shadow rounded-md sm:w-2/3 min-w-[300px] mx-[10px]",
            itemStyli: "border-b border-gray-100",
          }}
          pattern="key"
        />
      </div>

      <HorizontalLineAcross />
      <h3 className="text-lg font-medium leading-6 text-gray-900">My Orders</h3>
      <TableLayout
        list={orders}
        schama={orderForm}
        columnWidth={200}
        pagination={{
          from: order.info.HLL?.from,
          to: order.info.HLL?.to,
          total: order.info.HLL?.total,
          current: order.info.HLL?.current_page,
          last: order.info.HLL?.last_page,
          goto: (pageNumber: number) => order.getOrder({ page: pageNumber }),
        }}
      />
      <Spacer />
    </div>
  );
}
export default Profile;
