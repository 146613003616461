import axios, { AxiosRequestConfig } from "axios";

import { ActionProps, DataProps } from "../../types";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseURl } from "../env/host-url";

export const initialState:DataProps = {
    info: {
        status:false,
        query:'',
        detail:null,
        reset_token:null,
        HLL:null
    },
    loading: false,
    error: ''
};


export const userAction = createAsyncThunk("user/userAction", async ({path, payload, token = ""}:ActionProps) => {
    try {
        const headers:any = {
            'Content-Type':'application/json',
            'Accept':'application/json',
            "Authorization": `Bearer ${token}`
        }; 
        const response = await axios.post(`${baseURl+path}`,payload, {headers});

        //register token to the app
        if(response.data.detail.token){
            window.localStorage.setItem(
                "token",
                response.data.detail.token as string
                +"."+
                response.data.detail.id as string
            );
        }
        
        return response.data;
    } catch (error) {
        // @ts-ignore
        return error.message
    }
})

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers(builder){
        builder.addCase(userAction.pending, (state, action) => {
            state.loading = true;
        }).addCase(userAction.fulfilled, (state, action)=>{
            state.info = action.payload;
            state.error = "";
        }).addCase(userAction.rejected, (state, action)=>{
            state.error = action.error.message;
        })
    }
})

export default userSlice.reducer

export const userSelector = (state:any) => state.user;

