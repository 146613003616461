import React from "react";

import { HorizontalLineAcross } from "../ui/horizontal-line-across";
import { CheckBox } from "../ui/check-box";
import { ListItems } from "../ui/item-list";
import { TagShortDecription } from "../ui/tag-short-decription";
import { CardWrapper } from "../ui/card";
import { Spacer } from "../ui/spacer";
import { useAddress } from "../hook/use-addresses";
import useToken from "../hook/token";
import Loader from "../ui/spinner";
import { useShipping } from "../hook/use-shipping";
import { useModal } from "../ui/modal/modal.context";
import { useCart } from "../hook/use-cart";
import { useDistanceInformation } from "../hook/distance-matrix";
import { usePersonal } from "../hook/user-detail";
import { useConverter } from "../hook/currency-conversion";
import { issueOrderReceipt } from "../hook/customer-receipt";
import { baseURl } from "../../redux/env/host-url";
import axios from "axios";
import { Payment } from "../ui/payment";

function Checkout() {
  const { openModal } = useModal();
  const { fetchMilage, mileageData } = MileageList(),
    mileage = mileageData(),
    { fetchDistance, distanceData } = DisancesList(),
    distance = distanceData();
  const {
    total_price,
    total_wieght,
    items,
    total_items,
    total_price_fxws,
    isEmpty,
  } = useCart();
  const { deliveryPrice, handleRetrieveLocation } = useDistanceInformation();
  const { currency_name, iforexWS, UiForex, liveInfo } = useConverter(),
    userLocation = liveInfo?.location?.country?.name;
  const [, , userID] = useToken();
  const { data } = usePersonal(true);
  const address = useAddress();
  const shipping = useShipping();
  const [iscConfirmed, iscConfirm] = React.useState<boolean>(false);
  const [shipingInfo, setshippingInfo] = React.useState<{
    shippingMethod: any;
    address: any;
  }>({
    shippingMethod: null,
    address: null,
  });

  // lists
  let addressez: any[] = address.info.HLL
    ? address.info.HLL.map((v) => {
        return {
          ...v,
          city: v.details.city,
          country: v.details.country,
          state: v.details.state,
          street_name: v.details.street_name,
          zip: v.details.zip,
        };
      }).filter((v: any) => v.customer_id == userID)
    : [];
  let defaultAddress = addressez.find((v: any) => v.default > 0); //use directly as alternative
  let shippingz: any[] = shipping.info.HLL?.data
    ? shipping.info.HLL?.data.map((value: any) => {
        return (value = {
          ...value,
          label: value.means.replace(/(_)/gi, " ") + ` (${value.duration})`,
        });
      })
    : [];
  const listedMiles: any[] = mileage?.payload ?? [];

  React.useEffect(() => {
    address.getAddress({ page: 1 });
    shipping.getShipping({ page: 1 });
    fetchMilage({ path: "/milez" });
    fetchDistance({ path: "/distances" });
  }, []);

  let shippingCalculated = iforexWS({
    from: "USD",
    value: shipingInfo.shippingMethod?.amount,
  });
  const handleConfirmation = () => {
    //__location cordinates
    let userLocation: any =
        shipingInfo.address?.details ?? defaultAddress?.details,
      sellerLocation: any[] = [],
      //pick the coordinates base on the availability that can be found
      availableUserCordinates = userLocation?.city_coordinate?.latitude
        ? userLocation?.city_coordinate
        : userLocation?.state_coordinate?.latitude
        ? userLocation?.state_coordinate
        : userLocation?.country_coordinate;
    // get the actual name of place for buyer
    let manullyDistance = findBackupDistance(
      distance.payload,
      userLocation,
      items
    );

    //
    items.forEach((value: any) => {
      let address = JSON.parse(value?.shop?.address),
        //pick the coordinates base on thxe availability that can be found
        availablesellerCordinates = address?.city_details?.latitude
          ? address?.city_details
          : address?.state_details?.latitude
          ? address?.state_details
          : address?.country_details;
      return sellerLocation.push({
        latitude: availablesellerCordinates?.latitude,
        longitude: availablesellerCordinates?.longitude,
      });
    });
    // console.log(
    //   "availablesellerCordinates",sellerLocation,
    //   "availableUserCordinates",availableUserCordinates,
    // )

    //______computing and processing
    //pick the matched country to the user address
    //use this price to compute price per mile
    //add the shipping method price to it afterwards
    const relatedMilePrice =
      listedMiles.find((value) => {
        let isAvailable = value?.country
          ?.toLowerCase()
          .includes(userLocation?.country?.toLowerCase());
        if (isAvailable) {
          return value;
        }
      }) ?? null;

    const shippingIsSet = Object.entries(shipingInfo).every(
      (v) => v[1] !== null
    );
    iscConfirm(shippingIsSet);

    if (!shippingIsSet) {
      return openModal({
        view: "ALERT",
        payload:
          "Please ensure that you have selected your shipping method and / or desired address",
      });
    }

    if (!relatedMilePrice) {
      return openModal({
        view: "ALERT",
        payload: "This location is not set for shipping by PX Stores",
      });
    }

    if (availableUserCordinates?.latitude && sellerLocation.length > 0) {
      let response = handleRetrieveLocation({
        origin: availableUserCordinates,
        destionations: sellerLocation,
        shippingMethodFee: shippingCalculated,
        perMilePrice: relatedMilePrice?.value_per_mile,
        manullyDistance,
      });
    }
  };

  //computation and formatting
  let grand_total_cost = deliveryPrice + total_price_fxws;

  // @ts-ignore
  let email = data?.email;
  let isReady = [total_price_fxws, deliveryPrice].every(
    (v) => typeof v === "number"
  );
  let total = isReady ? grand_total_cost : 0;
  const cashoutDetails = {
    email,
    total,
    currency_name,
  };
  //_Localizing the shipping cost for the items relating to the seller
  //Obtaing the percent ratio of the user's shipping:total price (both already in fxws)
  //the delivery_fee_fxws_percent will be consume on the backend for localization, but not saved rather the outcome is save
  // note delivery_fee_fxws_percent is not approximated for accurate usage since it is an ongoing transaction
  let delivery_fee_fxws_percent = shippingCalculated / total_price_fxws;

  let receipt = issueOrderReceipt({
    delivery_fee: deliveryPrice,
    delivery_fee_fxws_percent,
    shipping_address: shipingInfo.address?.details ?? defaultAddress?.details,
    status: 1,
    items,
    total_items,
    total_price: total,
    total_price_fxws: total,
    total_wieght,
    isEmpty,
    // @ts-ignore
    userID,
    currency: currency_name,
  });

  const status = [shippingz].every((v) => v.length > 0);
  if (!status) {
    return <Loader />;
  }

  return (
    <div className="md:mx-[5rem] md:my-5">
      <TagShortDecription
        title="Shipping Address"
        description="You are expected to select your desired address"
        outerlayerStyle="md:col-span-1"
        innerBodyStyle="px-4 sm:px-0 my-10"
        innerHeaderStyle="text-lg font-medium leading-6 text-gray-900"
        innerlayerStyle="mt-1 text-sm text-gray-600"
      />
      <ListItems
        list={addressez}
        layoutStyli="flex items-start shadow overflow-auto sm:rounded-md mb-[100px]"
        card={{
          frameStyli: "shadow rounded-md min-w-[300px]",
          itemStyli: "border-b border-gray-100 align-baseline",
        }}
        pattern="key"
        onSelect={(v) => {
          setshippingInfo({ ...shipingInfo, address: v });
          iscConfirm(false);
        }}
      />

      <HorizontalLineAcross />
      <TagShortDecription
        title="Shpping Method"
        description="Shipping methods below are designed to cater for the speed of
                delivery and bulkiness of items. Please select the most
                appropriate. Do not select a motor cycle when the item is a
                fridge."
        outerlayerStyle="md:col-span-1"
        innerBodyStyle="px-4 sm:px-0 my-10"
        innerHeaderStyle="text-lg font-medium leading-6 text-gray-900"
        innerlayerStyle="mt-1 text-sm text-gray-600"
      />
      <div className="bg-white py-10 px-5 mb-[100px] shadow">
        {shippingz.map((value, index) => (
          <CheckBox
            key={index}
            type="radio"
            name="name"
            autoComplete="given-name"
            classNameCheck="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            htmlFor="first-name"
            classNameLabel="block text-sm font-medium text-gray-700 ml-2 capitalize"
            labelTitle={value?.label}
            classNameGrouped="col-span-6 flex justify-start items-center m-5"
            onChange={(v) => {
              setshippingInfo({ ...shipingInfo, shippingMethod: value });
              iscConfirm(false);
            }}
          />
        ))}
      </div>

      {iscConfirmed ? (
        <>
          <HorizontalLineAcross />
          <TagShortDecription
            title="Payment"
            description="Please confirm your details. Happy Shopping!"
            outerlayerStyle="md:col-span-1"
            innerBodyStyle="px-4 sm:px-0 my-10"
            innerHeaderStyle="text-lg font-medium leading-6 text-gray-900"
            innerlayerStyle="mt-1 text-sm text-gray-600"
          />
          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-2">
              {/* section 1 */}
              <CardWrapper cardStyli="shadow">
                <div className="flex justify-between item-center m-5">
                  <div className="text-bold">Shipping</div>
                  <UiForex from={currency_name} value={deliveryPrice} />
                </div>
                <div className="flex justify-between item-center m-5">
                  <div className="text-bold">Subtotal</div>
                  <UiForex from={currency_name} value={total_price_fxws} />
                </div>
                <div className="flex justify-between item-center m-5">
                  <div className="text-bold">Total</div>
                  <UiForex from={currency_name} value={grand_total_cost} />
                </div>
              </CardWrapper>

              <Payment
                checkout={{
                  cashoutDetails,
                  receipt: { ...receipt, reasaon: "product" },
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <button
          type="button"
          onClick={() => handleConfirmation()}
          className="font-bold w-2/4 m-auto block justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Proceed
        </button>
      )}
      <Spacer />
    </div>
  );
}
export default Checkout;
function MileageList() {
  const [mileage, setmileage] = React.useState<{
    payload: any[];
    error: string;
  }>({
    payload: [],
    error: "",
  });

  async function fetchMilage({ path }: { path: string }) {
    try {
      const headers: any = {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Authorization": `Bearer ${token}`
      };
      const response = await axios.post(`${baseURl + path}`, {
        headers,
      });
      return setmileage({ payload: response.data?.HLL, error: "" });
    } catch (error) {
      // @ts-ignore
      return setmileage({ payload: [], error: error.message });
    }
  }
  const mileageData = () => mileage;
  return {
    fetchMilage,
    mileageData,
  };
}
function DisancesList() {
  const [distance, setdistance] = React.useState<{
    payload: any[];
    error: string;
  }>({
    payload: [],
    error: "",
  });

  async function fetchDistance({ path }: { path: string }) {
    try {
      const headers: any = {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Authorization": `Bearer ${token}`
      };
      const response = await axios.post(`${baseURl + path}`, {
        headers,
      });
      return setdistance({ payload: response.data?.HLL, error: "" });
    } catch (error) {
      // @ts-ignore
      return setdistance({ payload: [], error: error.message });
    }
  }
  const distanceData = () => distance;
  return {
    fetchDistance,
    distanceData,
  };
}
// function findBackupDistance(
//   distances: any[],
//   origin: any,
//   distinations: any[]
// ): number {
//   let result: number = 0,
//     targetOrigin = origin?.city_coordinate?.latitude
//       ? origin?.city
//       : origin?.state_coordinate?.latitude
//       ? origin?.state
//       : origin?.country;
//   for (let index = 0; index < distinations.length; index++) {
//     let distination = JSON.parse(distinations[index]?.shop?.address),
//       distinationName = distination?.city_details?.latitude
//         ? distination?.city_details.city_name
//         : distination?.state_details?.latitude
//         ? distination?.state_details.state_name
//         : distination?.country_details.country_name;
//     distances.find((distance) => {
//       //check location A
//       let distinationIsAvailableOnA = distance?.location_A
//           ?.toLowerCase()
//           ?.includes(distinationName?.toLowerCase()),
//         originIsAvailableOnA = distance?.location_A
//           ?.toLowerCase()
//           ?.includes(targetOrigin?.toLowerCase()),
//         //check location B
//         distinationIsAvailableOnB = distance?.location_B
//           ?.toLowerCase()
//           ?.includes(distinationName?.toLowerCase()),
//         originIsAvailableOnB = distance?.location_B
//           ?.toLowerCase()
//           ?.includes(targetOrigin?.toLowerCase());
//       if (distinationIsAvailableOnA && originIsAvailableOnB) {
//         //A - B
//         result += Number(distance.distance_km);
//       }

//       if (distinationIsAvailableOnB && originIsAvailableOnA) {
//         // B - A
//         result += Number(distance.distance_km);
//       }
//     });
//   }

//   return result;
// }

function findBackupDistance(
  backupLocations: any,
  buyerLocation: any,
  shopLocations: any
) {
  // backupLocations =>  buyerLocation & shopLocations
  // * location_A [Hollywood,Califonia, United States] <--------------x shop [Hollywood,Califonia, United States]
  // * location_B [Nigeria,Lagos, Enugu] <--------------x user [Nigeria,Lagos, Enugu]
  // *
  // * location_A [Hollywood,Califonia, United States] <--------------x shop [Nigeria,Lagos, Enugu]
  // * location_B [Nigeria,Lagos, Enugu] <--------------x user [Hollywood,Califonia, United]

  // buyer and shop location
  let result: number = 0,
    transLocatinons: any = [];
  for (let i = 0; i < shopLocations.length; i++) {
    let distination = JSON.parse(shopLocations[i]?.shop?.address),
      shopCity = distination?.city_details?.city_name,
      shopState = distination?.state_details?.state_name,
      shopCountry = distination?.country_details?.country_name,
      buyerCity = buyerLocation?.city,
      buyerState = buyerLocation?.state,
      buyerCountry = buyerLocation?.country;
    transLocatinons.push({
      buyer: [buyerCity, buyerState, buyerCountry],
      shop: [shopCity, shopState, shopCountry],
    });
  }

  // get matched buyer&shop location from backkup location
  for (let i = 0; i < backupLocations.length; i++) {
    let backupLocation = backupLocations[i],
      location_A: string = backupLocation?.location_A,
      location_B: string = backupLocation?.location_B;

    // match to shoplocation
    for (let j = 0; j < transLocatinons.length; j++) {
      let transLocatinon = transLocatinons[j],
        city: any = null,
        state: any = null,
        country: any = null;
      // console.log('transLocatinon', transLocatinon);

      city = cityFinder(backupLocation, transLocatinon, location_A, location_B);
      if (Boolean(city) && city?.found) {
        result += city?.result;
      }
      if (Boolean(city) && !city?.found) {
        state = stateFinder(
          backupLocation,
          transLocatinon,
          location_A,
          location_B
        );
        result += state?.result;
      }

      if (Boolean(state) && !state?.found) {
        country = countryFinder(
          backupLocation,
          transLocatinon,
          location_A,
          location_B
        );
        result += country?.result;
      }
      // console.log('city', city, 'state', state, 'country', country);
    }
  }

  // console.log('result', result);

  return result;
}

function cityFinder(
  backupLocation: any,
  transLocatinon: any,
  location_A: any,
  location_B: any
) {
  let found = false,
    result = 0;
  // city to city
  if (transLocatinon?.buyer[0] || transLocatinon?.shop[0]) {
    let locationA = location_A
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.buyer[0]?.toLowerCase()),
      locationB = location_B
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.shop[0]?.toLowerCase());
    let reverselocationA = location_B
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.buyer[0]?.toLowerCase()),
      reverselocationB = location_A
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.shop[0]?.toLowerCase());

    // alternating city to state
    let alternateLocationA = location_A
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.buyer[0]?.toLowerCase()),
      alternateLocationB = location_B
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.shop[1]?.toLowerCase());
    let alternateReverselocationA = location_B
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.buyer[1]?.toLowerCase()),
      alternateReverselocationB = location_A
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.shop[0]?.toLowerCase());
    // console.log(
    //   '1A --> location_A - location_B',
    //   locationA,
    //   locationB,
    //   'reverse',
    //   reverselocationA,
    //   reverselocationB,
    // );
    // console.log(
    //   '1B --> alternate location_A - location_B',
    //   alternateLocationA,
    //   alternateLocationB,
    //   'alternate reverse',
    //   alternateReverselocationA,
    //   alternateReverselocationB,
    // );
    if (locationA && locationB) {
      // console.log('1A location', backupLocation?.distance_km);
      found = true;
      result += Number(backupLocation?.distance_km);
    } else if (reverselocationA && reverselocationB) {
      // console.log('1A reverse location', backupLocation?.distance_km);
      found = true;
      result += Number(backupLocation?.distance_km);
    } else if (alternateLocationA && alternateLocationB) {
      // console.log('1B alternate location', backupLocation?.distance_km);
      found = true;
      result += Number(backupLocation?.distance_km);
    } else if (alternateReverselocationA && alternateReverselocationB) {
      // console.log('-1 alternate location', backupLocation?.distance_km);
      found = true;
      result += Number(backupLocation?.distance_km);
    }
  }

  return {
    result,
    found,
  };
}
function stateFinder(
  backupLocation: any,
  transLocatinon: any,
  location_A: any,
  location_B: any
) {
  let found = false,
    result = 0;
  // state to state
  if (transLocatinon?.buyer[1] || transLocatinon?.shop[1]) {
    let locationA = location_A
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.buyer[1]?.toLowerCase()),
      locationB = location_B
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.shop[1]?.toLowerCase());
    let reverselocationA = location_B
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.buyer[1]?.toLowerCase()),
      reverselocationB = location_A
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.shop[1]?.toLowerCase());

    // alternating state to country
    let alternateLocationA = location_A
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.buyer[1]?.toLowerCase()),
      alternateLocationB = location_B
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.shop[2]?.toLowerCase());
    let alternateReverselocationA = location_B
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.buyer[2]?.toLowerCase()),
      alternateReverselocationB = location_A
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.shop[1]?.toLowerCase());
    // console.log(
    //   '2A --> location_A - location_B',
    //   locationA,
    //   locationB,
    //   'reverse',
    //   reverselocationA,
    //   reverselocationB,
    // );
    // console.log(
    //   '2B --> alternate location_A - location_B',
    //   alternateLocationA,
    //   alternateLocationB,
    //   'alternate reverse',
    //   alternateReverselocationA,
    //   alternateReverselocationB,
    // );
    if (locationA && locationB) {
    } else if (reverselocationA && reverselocationB) {
      // console.log('2A location', backupLocation?.distance_km);
      found = true;
      result += Number(backupLocation?.distance_km);
    } else if (alternateLocationA && alternateLocationB) {
      // console.log('2B location', backupLocation?.distance_km);
      found = true;
      result += Number(backupLocation?.distance_km);
    } else if (alternateReverselocationA && alternateReverselocationB) {
      // console.log('-2 location', backupLocation?.distance_km);
      found = true;
      result += Number(backupLocation?.distance_km);
    }
  }

  return {
    result,
    found,
  };
}
function countryFinder(
  backupLocation: any,
  transLocatinon: any,
  location_A: any,
  location_B: any
) {
  let found = false,
    result = 0;

  // country to country
  if (transLocatinon?.buyer[2] || transLocatinon?.shop[2]) {
    let locationA = location_A
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.buyer[2]?.toLowerCase()),
      locationB = location_B
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.shop[2]?.toLowerCase());
    let reverselocationA = location_B
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.buyer[2]?.toLowerCase()),
      reverselocationB = location_A
        .toLowerCase()
        .trim()
        .includes(transLocatinon?.shop[2]?.toLowerCase());
    // console.log(
    //   '3 --> location_A - location_B',
    //   locationA,
    //   locationB,
    //   'reverse',
    //   reverselocationA,
    //   reverselocationB,
    // );
    if (locationA && locationB) {
      // console.log('3 location', backupLocation?.distance_km);
      found = true;
      result += Number(backupLocation?.distance_km);
    } else if (reverselocationA && reverselocationB) {
      // console.log('3 location', backupLocation?.distance_km);
      found = true;
      result += Number(backupLocation?.distance_km);
    }
  }

  return {
    result,
    found,
  };
}
