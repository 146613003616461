import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { addressAction, addressSelector } from "../../redux/reducers/address-reducer";
import { initialState } from "../../redux/reducers/address-reducer";

type UseApplyProps = {
    createOrUpdateAddress:(payload:any) => any 
    getAddress:(payload:any) => any 
    deleteAddress:(payload:any) => any
    deactivateAddress:(payload:any) => any

} & typeof initialState

export const useAddress = ():UseApplyProps => {
    const dispatch = useDispatch();
    const state = useSelector(addressSelector);


    const createOrUpdateAddress = (payload:any) => dispatch<any>(addressAction({path:"/address", payload}));
    const getAddress = (payload:any) => dispatch<any>(addressAction({path:"/addresses", payload}));
    const deleteAddress = (payload:any) => dispatch<any>(addressAction({path:"/address/status", payload,}));
    const deactivateAddress = (payload:any) => dispatch<any>(addressAction({path:"/address/status", payload,}));

    return {
        ...state,
        createOrUpdateAddress, 
        getAddress, 
        deleteAddress,
        deactivateAddress,
    }
}