import React, { useEffect, useState } from "react";

// @ts-ignore
import crossIcon from "../assets/cross-icon.png";
// @ts-ignore
import chatIcon from "../assets/chat-icon.png";
import { useConverter } from "../hook/currency-conversion";
import { usePermission } from "../hook/permission";
import { useChat } from "../hook/use-chat";

interface OpenedChatProps {
  open: boolean;
  conversion: boolean;
}
interface ConversionPrpos {
  onClick: (arg?: any) => any;
  handleSendMessage?: () => any;
  onChangeText?: (arg: any) => any;
  list?: chatMessageProps[];
  admin?: string;
  admin_online?: boolean;
  inputValue?: string;
}
interface chatMessageProps {
  sent: number;
  recieved: number;
  message: string;
}
interface chatsProps {
  admin_name: string;
  admin_online: boolean | null;
  user_ip_address: string;
  user_name: string;
  user_email: string;
  chats: chatMessageProps[];
}
function ChatBox() {
  const isAdmin = usePermission(2),
    { liveInfo } = useConverter(),
    { getUserChat, info, createOrUpdateChat } = useChat(),
    [counter, setcounter] = React.useState<number>(0),
    [openedChat, openChat] = useState<OpenedChatProps>({
      open: false,
      conversion: false,
    }),
    [sentMessage, sendMessage] = useState<chatMessageProps>({
      sent: 0,
      recieved: 0,
      message: "",
    }),
    [chatMessages, chatMessage] = useState<chatsProps>({
      admin_name: "",
      admin_online: null,
      user_name: "",
      user_email: "",
      user_ip_address: "",
      chats: [],
    }),
    handleSendMessage = async () => {
      let payload = {
        ...info?.detail,
        ...chatMessages,
        chats: [
          ...info?.detail?.chats,
          {
            ...sentMessage,
          },
        ],
      };
      chatMessage(payload);

      //update user chat log
      await createOrUpdateChat(payload);

      setTimeout(() => {
        sendMessage({
          sent: 0,
          recieved: 0,
          message: "",
        });
      }, 1000);
    };

  const ipAddress = liveInfo?.connection?.ip;
  useEffect(() => {
    getUserChat({
      user_ip_address: ipAddress,
    });

    const timer = setInterval(() => {
      setcounter(() => {
        if (counter === 100) {
          return 0;
        }
        return counter + 1;
      });
    }, 60000);
    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  return (
    <div>
      <div className={openedChat.open ? styles.chat : ""}>
        {/* chat area */}
        {openedChat.open && (
          <div className={styles.chatArea}>
            {openedChat.conversion ? (
              <Conversion
                list={info?.detail?.chats}
                onClick={() =>
                  openChat({
                    ...openedChat,
                    conversion: !openedChat.conversion,
                  })
                }
                handleSendMessage={handleSendMessage}
                inputValue={sentMessage.message}
                onChangeText={(v) => {
                  sendMessage({
                    ...sentMessage,
                    message: v.target.value,
                    sent: 1,
                    recieved: 0,
                  });
                }}
                admin={info?.detail?.admin_name}
                admin_online={info?.detail?.admin_online}
              />
            ) : (
              <StartConversion
                onClick={async (v) => {
                  let payload = {
                    ...chatMessages,
                    user_ip_address: ipAddress,
                    user_name: v?.name,
                    user_email: v?.email,
                    chats: [
                      ...chatMessages?.chats,
                      {
                        message: v?.message,
                        sent: v?.sent,
                        recieved: v?.message,
                      },
                    ],
                  };
                  chatMessage(payload);
                  openChat({
                    ...openedChat,
                    conversion: !openedChat.conversion,
                  });

                  //update user chat log
                  await createOrUpdateChat(payload);
                }}
              />
            )}
          </div>
        )}

        {/* chat float button */}
        <FlaotButton
          onClick={() => openChat({ ...openedChat, open: !openedChat.open })}
          opened={openedChat.open}
        />
      </div>
    </div>
  );
}

function StartConversion({ onClick }: ConversionPrpos) {
  const [sentMessage, sendMessage] = useState<chatMessageProps | any>({
      sent: 1,
      recieved: 0,
      name: "",
      email: "",
      message: "",
    }),
    handleSendMessage = () => {
      onClick(sentMessage);
    };
  return (
    <>
      <div className={styles.chatHeader}>
        <div className={styles.chatTitle}>
          Please complete the form below and an agent will connect with you
          shortly.
        </div>
      </div>
      <div className={styles.chatBox}>
        <input
          className={styles.input}
          onChange={(v) => {
            sendMessage({ ...sentMessage, name: v.target.value });
          }}
          placeholder="*Name"
          autoCapitalize="none"
          autoFocus={true}
          autoComplete="off"
        />
        <input
          className={styles.input}
          onChange={(v) => {
            sendMessage({ ...sentMessage, email: v.target.value });
          }}
          placeholder="*Email"
          autoCapitalize="none"
          autoFocus={true}
          autoComplete="off"
        />
        <textarea
          className={styles.input}
          onChange={(v) => {
            sendMessage({ ...sentMessage, message: v.target.value });
          }}
          placeholder="*Message"
          autoCapitalize="none"
          autoFocus={true}
          autoComplete="off"
        ></textarea>
        <a className={styles.submit} onClick={handleSendMessage}>
          <div className={styles.submitText}>Submit</div>
        </a>
        <div className={styles.spacer}></div>
      </div>
    </>
  );
}

function Conversion({
  onClick,
  list = [],
  handleSendMessage,
  onChangeText,
  inputValue,
  admin,
  admin_online,
}: ConversionPrpos) {
  return (
    <div>
      <div className={styles.startConversion}>
        {admin_online && (
          <div className={styles.online}>
            <div onClick={onClick}>{"<"}</div>
            <div>{admin} is Online</div>
          </div>
        )}
        <div className={styles.messageFrame}>
          {list.slice(-4).map((chat: chatMessageProps, index: number) => (
            <div key={index}>
              {chat.recieved === 1 && (
                <div className={styles.youFrame}>
                  <div className={styles.meYouFrame}>
                    <div className={styles.you}>{chat.message}</div>
                  </div>
                </div>
              )}
              {chat.sent === 1 && (
                <div className={styles.meFrame}>
                  <div className={styles.meYouFrame}>
                    <div className={styles.me}>{chat.message}</div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={styles.send}>
          <input
            className={styles.sendInput}
            onChange={onChangeText}
            placeholder="Type here..."
            autoCapitalize="none"
            autoFocus={true}
            autoComplete="off"
            value={inputValue}
          />
          <a className={styles.sendBtn} onClick={handleSendMessage}>
            <div className={styles.sendText}>{">"}</div>
          </a>
        </div>
      </div>
    </div>
  );
}
interface FlaotButtonProps {
  onClick: () => any;
  opened: boolean;
}
function FlaotButton({ onClick, opened }: FlaotButtonProps) {
  return (
    <div className="fixed right-10 bottom-10">
      <div className="bg-white flex justify-end mx-[10px] mb-[10px] mt-[-100px] z-[9] relative text-black p-[5px]">
        {"Chat with PX Stores"}
      </div>
      <div className={styles.floatBtn}>
        {opened ? (
          <a onClick={onClick} className={styles.floatbtnIcon}>
            <img src={crossIcon} className={styles.icon} />
          </a>
        ) : (
          <a onClick={onClick} className={styles.floatbtnIcon}>
            <img src={chatIcon} className={styles.icon} />
          </a>
        )}
      </div>
    </div>
  );
}
const styles = {
  spacer: "h-[35px]",
  icon: "h-[35px] w-[35px]",
  chat: "bg-[transparent] w-[350px] h-[380px] z-[10] fixed right-10 bottom-10",
  floatBtn:
    "bg-[transparent] flex flex-row justify-end mt-[10px] fixed z-[10] right-10 bottom-10",
  floatbtnIcon:
    "bg-[#403d93] w-[60px] h-[60px] p-[13px] rounded-[50px] justify-center",
  chatArea:
    "bg-[#f0f0f0] w-[330px] min-h-[320] max-h-[330] border border-[#f0f0f0]  rounded-[10px] pt-[10px] px-[10px]",
  chatAreaHide: "hidden",
  chatHeader: "bg-[#403d93]",
  chatTitle: "text-[white] pt-[10px] text-center",
  input:
    "border border-[1px] border-[#f0f0f0] rounded-[10px] p-[10px] m-[10px]",
  chatBox:
    "bg-[white] border border-[1px] border-[#f0f0f0] rounded-[10px] p-[10px] m-[10px] max-h-[230px] overflow-auto",
  submit: "",
  submitText: "bg-[#403d93] text-[white] p-[10px] text-center font-bold",
  online:
    "bg-[white] border border-[1px] border-[#f0f0f0] rounded-[10px] p-[10px] m-[10px] flex flex-row justify-between",
  startConversion: "bg-[white] border border-[1px]",
  messageFrame: "min-h-[200px] max-h-[220px] p-[10px] overflow-auto",
  meYouFrame:
    "border border-[1px] border-[#f0f0f0] rounded-[10px] w-[60%] p-[2px]",
  me: "text-black bg-[#f0f0f0] p-[5px]",
  meFrame: "m-[5] w-[100%] flex flex-row justify-end",
  you: "text-white bg-[green] p-[5px]",
  youFrame: " m-[5px] w-[100%] flex flex-row justify-start",
  send: "flex flex-row justify-center",
  sendInput: "w-[90%] border border-[1px] border-[#f0f0f0] p-[10px]",
  sendBtn: "bg-[green] text-[blue] p-[10px] w-[10%] text-center",
  sendText: "text-[white] font-bold",
};

export default ChatBox;
