/*jshint ignore:start*/
import "./common.css";
import React from "react";

export const Safety = (props) => {
  return (
    <div>
      <section className="jumbotron text-center py-5 my-2 md:py-10 md:my-5">
        <div className="container md:py-10">
          <h1 className="jumbotron-heading md:text-[3em]">SAFETY</h1>
          <p className="lead opacity-50 mb-0 help-step-title md:mt-4"></p>
        </div>
      </section>

      <div className="container pb-3 mb-1">
        <div className="card mb-3">
          <div
            className="card-body"
            style={{ paddingBottom: "-100px" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="md:grid md:grid-flow-col items-center gap-6 pt-2 pb-1 md:p-20">
              <div className="step" data-aos="fade-up" data-aos-duration="1000">
                <img
                  className="about-img-2"
                  id="px-app"
                  src="https://pxstores-frontend.netlify.app/images/driver-app.png"
                />
                <h2
                  className="text-center text-lg md:text-3xl py-10"
                  style={{ color: "#403D94" }}
                >
                  Parcel Exchange Driver App
                </h2>
                <div className="grid grid-cols-2 gap-6 items-center app-icon-container">
                  <div className="m-auto">
                    <a
                      href="https://apps.apple.com/us/developer/parcel-exchange-limited/id1452223262"
                      targe="_blank"
                    >
                      <img
                        className="app-icon step-effect md:h-[50px]"
                        src="https://pxstores-frontend.netlify.app/images/icons/apple.png"
                      />
                    </a>
                  </div>
                  <div className="m-auto">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.udyot.parcelexchangedriver"
                      targe="_blank"
                    >
                      <img
                        className="app-icon step-effect md:h-[50px]"
                        src="https://pxstores-frontend.netlify.app/images/icons/googleplay.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="step mt-10 md:mt-0"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 className="help-step-title heading text-center text-2xl md:text-[3em] text-[#403D94] font-bold pb-10">
                  Our Safety measures
                </h2>
                <div
                  className="step flex flex-col justify-center items-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div
                    className="step-icon"
                    style={{ height: "100px", width: "100px" }}
                  >
                    <img
                      style={{ height: "100px" }}
                      src="https://pxstores-frontend.netlify.app/images/icons/order-accepted.png"
                    />
                  </div>
                </div>
                <h2
                  className="text-left subtitle sub-2 py-5"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  At PX Stores, we think safety and this is why all carriers
                  including PX licenced officers go through detailed
                  accreditation checks in respect of documentations like
                  personal identification and others before accounts are
                  activated. Knowing the history of all our carriers is key.
                </h2>
                <div
                  className="step flex flex-col justify-center items-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div
                    className="step-icon"
                    style={{ height: "100px", width: "100px" }}
                  >
                    <img
                      style={{ height: "100px" }}
                      src="https://pxstores-frontend.netlify.app/images/icons/submit-document.png"
                    />
                  </div>
                </div>
                <h2
                  className="text-left subtitle sub-2 py-5"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  All carriers mandatorily submit their valid goods in transit
                  insurance policy before account activation. This ensures any
                  goods lost in transit should there be are paid for by the
                  appropriate insurer.
                </h2>
                <div
                  className="step flex flex-col justify-center items-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div
                    className="step-icon"
                    style={{ height: "100px", width: "100px" }}
                  >
                    <img
                      style={{ height: "100px" }}
                      src="https://pxstores-frontend.netlify.app/images/icons/tracking.png"
                    />
                  </div>
                </div>
                <h2
                  className="text-left subtitle sub-2 py-5"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Our advanced intergrated driver tracking application available
                  on Google Play and App Store is a load tracker. The app is
                  designed to track individual load location real time 24/7
                  anywhere in the globe. This ensures extra security and safety
                  of goods transported across our network.
                </h2>
                <div className="my-[32px] text-center text-white flex flex-col justify-center items-center">
                  <div className="text-[#403D94] text-center font-black">
                    {"For more information, visit "}
                    <a
                      style={{ color: "#E8550F" }}
                      href="https://parcelexchangegroup.com"
                    >
                      Parcel Exchange
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
