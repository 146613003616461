import React from "react";
import cn from "classnames";

type SpacerProps = {
    styli?:string
}
export function Spacer({ styli }:SpacerProps) {
    return (
        <div className={cn( 'h-[100px]' ?? styli )}>.</div>
    )
}