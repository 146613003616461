/*jshint ignore:start*/
import "./common.css";
import React from "react";

export const Purchase = (props) => {
  return (
    <div>
      <section className="jumbotron text-center py-5 my-2 md:py-10 md:my-5">
        <div className="container md:py-10">
          <h1 className="jumbotron-heading md:text-[3em]">PURCHASE ORDERS </h1>
          <p className="lead opacity-50 mb-0 md:mt-4">
            Information on Purchase Orders
          </p>
        </div>
      </section>
    </div>
  );
};
