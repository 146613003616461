import React from 'react'

import ROUTES from '../../constants/routes-list';
import Loader from '../ui/spinner';


function Cancel() {

    React.useEffect(()=>{

        window.location.href = `${ROUTES.HOME}`;
        window.localStorage.removeItem("_x_");
    },[])

    return (<Loader mini={true}/>)
}

export default Cancel;
