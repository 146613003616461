import React from "react";
import cn from "classnames";

type ListItemsProps = {
  list: any[];
  layoutStyli?: string;
  card?: {
    frameStyli?: string;
    itemStyli?: string;
  };
  onSelect?: any;
  onDelete?: any;
  pattern?: "plain" | "key";
  horizontal?: boolean;
  maxLengthExpection?: number;
  showCount?: boolean;
};
export function ListItems({
  list = [],
  layoutStyli,
  card,
  onSelect,
  onDelete,
  pattern = "plain",
  maxLengthExpection,
  showCount = false,
}: ListItemsProps) {
  const [selected, select] = React.useState<number>(-1);

  const handleSelection = (orginalDetails, index) => {
    onSelect(orginalDetails);
    select(index);
  };
  return (
    <>
      {showCount && (
        <div>
          <span>{list.length}</span>
          {maxLengthExpection && <span>{" / " + maxLengthExpection} </span>}
        </div>
      )}
      <div className={cn(layoutStyli)}>
        {list.map((current, index) => {
          const orginalDetails = current;
          delete current.updated_at;
          delete current.created_at;
          delete current.customer_id;
          delete current.default;
          delete current.is_active;
          let item = Object.entries(current) as any;
          return (
            <React.Fragment key={index}>
              {pattern === "plain" ? (
                <div
                  key={index}
                  className={cn(
                    index === selected
                      ? "border border-blue-400"
                      : "border-none",
                    "bg-white p-5 m-5 ",
                    card?.frameStyli
                  )}
                  onClick={() => handleSelection(orginalDetails, index)}
                  onDoubleClick={() => onDelete(current)}
                >
                  {item.map((value: any, index) =>
                    typeof value[1] !== "object" && value[0] !== "id" ? (
                      <div
                        key={index}
                        className={cn("min-w-fit", card?.itemStyli)}
                      >
                        {value[1]}
                      </div>
                    ) : (
                      <div key={index}></div>
                    )
                  )}
                </div>
              ) : (
                <div
                  key={index}
                  className={cn(
                    index === selected
                      ? "border border-blue-400"
                      : "border-none",
                    "bg-white p-5 m-5 ",
                    card?.frameStyli
                  )}
                  onClick={() => handleSelection(orginalDetails, index)}
                  onDoubleClick={() => onDelete(current)}
                >
                  {item.map((value: any, index) =>
                    typeof value[1] !== "object" && value[0] !== "id" ? (
                      <div
                        key={index}
                        className={cn(
                          "min-w-fit flex justify-between items-center",
                          card?.itemStyli
                        )}
                      >
                        <div className="capitalize">
                          {(value[0] as string).replace("_", " ") + ":"}
                        </div>
                        <div>{value[1]}</div>
                      </div>
                    ) : (
                      <div key={index}></div>
                    )
                  )}
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
}

export function CommissionList({
  list = [],
  layoutStyli,
  card,
  onSelect,
  pattern = "plain",
  horizontal = false,
}: ListItemsProps) {
  const [selected, select] = React.useState<number>(-1);

  const handleSelection = (orginalDetails, index) => {
    onSelect(orginalDetails);
    select(index);
  };
  return (
    <div
      className={cn(
        horizontal
          ? "flex flex-col  justify-evenly items-start shadow overflow-auto sm:rounded-md"
          : "flex justify-evenly items-start shadow overflow-auto sm:rounded-md",
        layoutStyli
      )}
    >
      {list.map((current, index) => {
        let item = Object.entries(current) as any;
        return (
          <div key={index}>
            {pattern === "plain" ? (
              <div
                key={index}
                className={cn(
                  index === selected ? "border border-blue-400" : "border-none",
                  "bg-white p-5 m-5 ",
                  card?.frameStyli
                )}
                onClick={() => handleSelection(current, index)}
              >
                {item.map((value: any, index) =>
                  typeof value[1] !== "object" && value[0] !== "id" ? (
                    <div
                      key={index}
                      className={cn("min-w-fit", card?.itemStyli)}
                    >
                      {value[1]}
                    </div>
                  ) : (
                    <div key={index}></div>
                  )
                )}
              </div>
            ) : (
              <div
                key={index}
                className={cn(
                  index === selected ? "border border-blue-400" : "border-none",
                  "bg-white p-5 m-5 ",
                  card?.frameStyli
                )}
                onClick={() => handleSelection(current, index)}
              >
                {item.map((value: any, index) =>
                  typeof value[1] !== "object" && value[0] !== "id" ? (
                    <div
                      key={index}
                      className={cn(
                        "min-w-fit flex justify-between items-center",
                        card?.itemStyli
                      )}
                    >
                      <div>{value[0] + ":"}</div>
                      <div>{value[1]}</div>
                    </div>
                  ) : (
                    <div key={index}></div>
                  )
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
