/*jshint ignore:start*/
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useJob } from "../components/hook/use-job";
import { useModal } from "../components/ui/modal/modal.context";
import Pagination from "../components/ui/table/pagination";
import ROUTES from "../constants/routes-list";
import "./career.css";
import "./career.css.map";
// import countriesData from "../constants/country.json";

export const Career = (data) => {
  const [countries, setCountries] = useState();
  const [searchResult, setSearchResult] = useState([]);
  const { openModal } = useModal();
  const jobs = useJob();

  const handleChange = (location) => {
    let result = jobs?.info?.HLL?.data?.filter((value) =>
      value?.country?.includes(location)
    );
    if (result?.length) {
      setSearchResult(result);
    } else setSearchResult([]);
  };

  const listed =
    searchResult?.length > 0 ? searchResult : jobs?.info?.HLL?.data;
  useEffect(() => {
    fetchCountries().then((response) => {
      if (response.includes("Network Error")) {
        openModal({ view: "ALERT", payload: response });
        return [];
      }
      setCountries(response);
    });
    jobs.getJob({ page: 1 });
  }, []);

  return (
    <div>
      <section class="trending_jobs bg-white">
        {/* <!--====== SUBSCRIBE PART START ======--> */}
        <section class="subscribe_area">
          <div class="container">
            <div class="subscribe_wrapper">
              <div class="row justify-center">
                <div class="col-lg-8 col-md-10">
                  <div class="subscribe_content">
                    <div class="section_title text-center md:py-20">
                      <h5 class="sub_title">Kick-start your career</h5>

                      <div class="text-center md:text-[40px] font-black w-full py-5">
                        <span className="w-2/3">
                          Select your country below to find jobs close to you
                        </span>
                      </div>
                    </div>
                    {/* <!-- section title --> */}
                    <div className="w-full flex justify-center items-center">
                      <div className="m-auto">
                        <select onChange={(e) => handleChange(e.target?.value)}>
                          <option
                            className="max-w-[250px] md:max-w-full"
                            selected
                          >
                            Select country
                          </option>
                          {countries?.map((item, index) => (
                            <option
                              key={index}
                              className="max-w-[250px] md:max-w-full"
                              value={item?.name}
                            >
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <!-- subscribe content --> */}
                </div>
              </div>
            </div>
            {/* <!-- subscribe wrapper --> */}
          </div>
          {/* <!-- container --> */}
        </section>
        {/* <!--====== SUBSCRIBE PART ENDS ======--> */}
        <div class="container my-5">
          <div class="md:grid md:grid-flow-col items-center">
            <div class="md:col-span-11">
              <div class="section_title pb-25">
                <h5 class="sub_title">Explore</h5>
                <h3 class="main_title">PX Stores Jobs</h3>
              </div>
              {/* <!-- section title --> */}
            </div>
            <div class="md:col-span-1">
              <div class="jobs_tabs_menu">
                <div class="nav flex items-center" id="myTab" role="tablist">
                  <div class="nav-item">
                    {/* <a
                      class="active"
                      id="trending-tab"
                      data-toggle="tab"
                      href="#trending"
                      role="tab"
                      aria-controls="trending"
                      aria-selected="true"
                    >
                      All Jobs
                    </a>
                  </div>
                  <div class="nav-item">
                    <a
                      id="recent-tab"
                      data-toggle="tab"
                      href="#recent"
                      role="tab"
                      aria-controls="recent"
                      aria-selected="false"
                    >
                      Recent jobs
                    </a> */}

                    <Pagination
                      from={jobs.info.HLL?.from}
                      to={jobs.info.HLL?.to}
                      total={jobs.info.HLL?.total}
                      current={jobs.info.HLL?.current_page}
                      last={jobs.info.HLL?.last_page}
                      goto={(pageNumber) => jobs.getJob({ page: pageNumber })}
                    />
                  </div>
                </div>
                {/* <!-- nav --> */}
              </div>
              {/* <!-- jobs tabs menu --> */}
            </div>
          </div>
          {/* <!-- row --> */}
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="trending"
              role="tabpanel"
              aria-labelledby="trending-tab"
            >
              <div
                class="md:grid md:grid-cols-3 md:gap-3"
                style={{ overflowY: "scroll", maxHeight: "700px" }}
              >
                {/* {search != ""
                  ? jobs
                      .filter((item) => item.job_country == search)
                      .map((job, i) => (
                        <div class="col-lg-4 col-sm-6">
                          <div class="single_jobs text-center mt-30">
                            <div class="jobs_content">
                              <h4 class="jobs_title">
                                <a href="job-details.html">{job.job_title}</a>
                              </h4>
                            </div>
                            <div class="jobs_meta d-flex justify-content-between">
                              <p class="location">
                                <i class="fa fa-map-marker primary-color"></i>
                                {job.job_state}
                              </p>
                              <p class="time">
                                <i class="fa fa-clock-o primary-color"></i>
                                {job.job_country}
                              </p>
                            </div>
                            <div class="jobs_btn">
                              <Link
                                to={`/application/${job.id}`}
                                className="main-btn main-btn-2"
                              >
                                apply now
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))*/}
                {listed?.map((job, i) => (
                  <div class="col-lg-4 col-sm-6" key={i}>
                    <div class="single_jobs text-center mt-30">
                      <div class="jobs_image w-full">
                        {i >= 0 ? (
                          <img
                            src="https://pxstores-frontend.netlify.app/images/careers/jobs-1.jpg"
                            alt="jobs"
                            className="m-auto"
                          />
                        ) : i > 10 ? (
                          <img
                            src="https://pxstores-frontend.netlify.app/images/careers/jobs-2.jpg"
                            alt="jobs"
                            className="m-auto"
                          />
                        ) : i > 20 ? (
                          <img
                            src="https://pxstores-frontend.netlify.app/images/careers/jobs-3.jpg"
                            alt="jobs"
                            className="m-auto"
                          />
                        ) : i > 30 ? (
                          <img
                            src="https://pxstores-frontend.netlify.app/images/careers/jobs-4.jpg"
                            alt="jobs"
                            className="m-auto"
                          />
                        ) : i > 40 ? (
                          <img
                            src="https://pxstores-frontend.netlify.app/images/careers/jobs-5.jpg"
                            alt="jobs"
                            className="m-auto"
                          />
                        ) : (
                          <img
                            src="https://pxstores-frontend.netlify.app/images/careers/jobs-6.jpg"
                            alt="jobs"
                            className="m-auto"
                          />
                        )}
                      </div>
                      <div class="jobs_content">
                        <h4 class="jobs_title capitalize text-black">
                          <a href="job-details.html">{job.title}</a>
                        </h4>
                      </div>
                      <div class="jobs_meta flex justify-between">
                        <p class="location text-black flex justify-between items-center">
                          {/* <i class="fa fa-map-marker primary-color"></i> */}
                          <PinIcon />
                          {job.country}
                        </p>
                        <p class="time text-black flex justify-between items-center">
                          {/* <i class="fa fa-clock-o primary-color"></i> */}
                          <TimeIcon />
                          {job.expiry}
                        </p>
                      </div>
                      <div class="jobs_btn">
                        <Link
                          to={`${ROUTES.PACKAGES}?q=Jobs`}
                          className="main-btn main-btn-2"
                        >
                          apply now
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* <!-- row --> */}

              {/* {listed.length < 1 && (
                <div className="min-h-[200px] w-full pt-20">
                  <div className="text-center">{"No result"}</div>
                </div>
              )} */}
            </div>
          </div>
          {/* <!-- tab content --> */}
        </div>
        {/* <!-- container --> */}
      </section>
    </div>
  );
};

async function fetchCountries() {
  try {
    const config = {
        headers: {
          "X-CSCAPI-KEY":
            "WUpUYzY1Z0g5TlRpOWh5Q1lEbW8wSmFJN2Vrbm8zWWlzNFVOQnJiWA==",
        },
      },
      response = await axios.get(
        "https://api.countrystatecity.in/v1/countries/",
        config
      );

    return response.data;
  } catch (error) {
    // @ts-ignore
    return error.message;
  }
}

function PinIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-3 h-3"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
      />
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
      />
    </svg>
  );
}
function TimeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-3 h-3"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}
