import React from "react";
import ROUTES from "../../constants/routes-list";

const FooterSettings = {
  highlights: [
    {
      title: "Who We Are",
      list: [
        { name: "Careers", url: ROUTES.CAREER },
        { name: "About PX Stores", url: ROUTES.ABOUT },
        { name: "Safety", url: ROUTES.SAFETY },
        { name: "Partners", url: "https://parcelexchangegroup.com" },
        { name: "Terms and Conditions", url: ROUTES.TERM },
        { name: "Privacy", url: ROUTES.PRIVACY },
      ],
    },
    {
      title: "Make Money with Us",
      list: [
        { name: "Sell products on PX Stores", url: ROUTES.SELL },
        { name: "Advertise Your Products", url: ROUTES.ADVERTISE },
        { name: "Become a Licenced Officer", url: ROUTES.LICENSED },
        { name: "Become a Carrier", url: ROUTES.CARRIER },
        { name: "Become a Seller", url: "https://dashboard.pxstores.com" },
      ],
    },
    {
      title: "Payment Methods",
      list: [
        { name: "Verve", url: "/" },
        { name: "Visa", url: "/" },
        { name: "Mastercard", url: "/" },
        { name: "American Express", url: "/" },
        { name: "Wire Transfer", url: "/" },
        { name: "PayPal", url: "/" },
        { name: "Paystack", url: "/" },
      ],
    },
    {
      title: "Let us help you",
      list: [
        // { name: "Track Orders", url: '/' },
        { name: "Your Orders", url: ROUTES.LOGIN },
        { name: "Quality Assurance", url: ROUTES.QUALITY },
        { name: "Shipping Rates & Policies", url: "/" },
        { name: "Returns & Replacements", url: ROUTES.RETURNS },
        { name: "PX Stores Assistant", url: ROUTES.SUPPORT },
        { name: "Customer Service", url: ROUTES.SERVICE },
      ],
    },
  ],
  countries: [
    "Nigeria",
    "United Kingdom",
    "United States",
    "Canada",
    "Germany",
    "Italy",
    "United Arabs Emirates",
    "France",
    "China",
    "Australia",
    "India",
    "Singapore",
    "Spain",
    "Japan",
    "Netherlands",
    "Turkey",
    "Mexico",
    "Brazil",
    "Saudi Arabia",
  ],
};
function Footer() {
  return (
    <div id="Footer">
      <div className="highlights bg-sky-800 text-center sm:text-left sm:grid grid-cols-4 grid-rows-1 gap-3 text-white py-3 px-3">
        {FooterSettings.highlights.map((value, index) => (
          <div className="col-span-1 mx-3 my-5" key={index}>
            <h5>
              <strong>{value.title}</strong>
            </h5>
            <hr className="my-2 hidden sm:block" />
            {value.list.map((v, i) => (
              <ul className="text-sm pt-2" key={i}>
                <li>
                  <a href={v.url}>{v.name}</a>
                </li>
              </ul>
            ))}
          </div>
        ))}
      </div>
      <div className="social bg-sky-900 flex justify-center items-center">
        <a
          target="_blank"
          href="https://web.facebook.com/Pxstores-100785855242281"
        >
          <img
            className="sm-icon w-6 h-6 m-2"
            src="https://static.xx.fbcdn.net/rsrc.php/yD/r/d4ZIVX-5C-b.ico?_nc_eui2=AeEl30qO77iAYCuKCw0DMJjBaBWfmC2eGbdoFZ-YLZ4ZtwIw4HhTv4hOIXe5YlNNMaQegJ_Mx7mR_WBMF0vGjwfo"
            alt="facebook"
          />
        </a>
        <a target="_blank" href="https://twitter.com/px_stores">
          <img
            className="sm-icon w-6 h-6 m-2"
            src="https://abs.twimg.com/favicons/twitter.2.ico"
            alt="twitter"
          />
        </a>
        <a target="_blank" href="https://instagram.com/pxstores">
          <img
            className="sm-icon w-6 h-6 m-2"
            src="https://static.cdninstagram.com/rsrc.php/v3/yt/r/30PrGfR3xhB.png"
            alt="instagram"
          />
        </a>
        <a
          target="_blank"
          href="https://www.linkedin.com/company/px-stores/?viewAsMember=true"
        >
          <img
            className="sm-icon w-6 h-6 m-2"
            src="https://static-exp1.licdn.com/sc/h/8s162nmbcnfkg7a0k8nq9wwqo"
            alt="linkedin"
          />
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCSLUjJseBGi7ny9CFr1S0NA?view_as=subscriber"
        >
          <img
            className="sm-icon w-6 h-6 m-2"
            src="https://www.youtube.com/s/desktop/cafdf09f/img/favicon.ico"
            alt="youtube"
          />
        </a>
      </div>
      <div className="bg-blue-900 first-line:grid grid-rows-2 gap-6 text-white p-5">
        <div className="row-span-full text-center text-[10px] mb-2 overflow-auto">
          <ul className="countries block sm:flex justify-evenly items-center">
            {FooterSettings.countries.map((value, index) => (
              <li key={index} className="m-2">
                {value}
              </li>
            ))}
          </ul>
        </div>
        <hr />
        <div className="copyright mt-5 row-span-full text-center">
          © {new Date().getFullYear()} PX Stores. All Rights Reserved.
        </div>
      </div>
    </div>
  );
}
export default Footer;
