import React from "react";
import axios, { AxiosRequestConfig } from "axios";
import { baseURl } from "../../redux/env/host-url";

/**
 * distanceInformation
 * THis package does make a call to the serve to retrieve the distance and time
 * using the coordinate provided.
 *
 * In this case we are use the customer location as orign
 * while that of the seller are the destionations
 */

interface AxiosConfigProps {
  method: string;
  url: string;
  headers: {
    Cookie: string;
  };
}
interface DistanceInformationResonseProps {
  destination_addresses: any[];
  origin_addresses: any[];
  rows: RowsProps[];
  status: string;
}

interface RowsProps {
  elements: ElementsProps[];
}

interface ElementsProps {
  distance: ValueProps;
  duration: ValueProps;
  status: string;
}

interface ValueProps {
  text: string;
  value: number;
}

interface CoordinateProps {
  latitude: string;
  longitude: string;
}
interface PricesProps {
  perMilePrice: number;
  shippingMethodFee: number;
  manullyDistance: number;
}
type DistanceInformationProps = {
  origin: CoordinateProps;
  destionations: CoordinateProps[];
} & PricesProps;

type DistanceInformationResponse = {
  deliveryPrice: number;
  handleRetrieveLocation: (arg: DistanceInformationProps) => any;
};
export const useDistanceInformation = (): DistanceInformationResponse => {
  // const [signal, setsignal] = React.useState<boolean>(false);
  const [matrix, setMatrix] = React.useState<
    DistanceInformationResonseProps | any
  >(null);
  const [prices, setprices] = React.useState<PricesProps>({
    perMilePrice: 0,
    shippingMethodFee: 0,
    manullyDistance: 0,
  });

  const handleRetrieveLocation = async ({
    origin,
    destionations = [],
    perMilePrice = 0,
    shippingMethodFee = 0,
    manullyDistance = 0,
  }: DistanceInformationProps) => {
    setprices({
      perMilePrice,
      shippingMethodFee,
      manullyDistance,
    });

    //"%2C" ===> ","
    //"%7C" ===> "|"
    //formatting the distination values
    let destionation: string = "",
      indexOfLastItems = destionations.length - 1;
    destionations.forEach((value, index) => {
      if (indexOfLastItems === index) {
        destionation += value.latitude + "%2C" + value.longitude;
      } else {
        destionation += value.latitude + "%2C" + value.longitude + "%7C";
      }
    });
    // console.log("Input matrix api", destionation, origin);

    try {
      const config = {
          "Content-Type": "application/json",
          Accept: "application/json",
          headers: {},
        },
        data = {
          originLag: origin.latitude,
          originLng: origin.longitude,
          destionation: destionation,
        },
        response = await axios.post(`${baseURl}/distance-matrix`, data, config);
      return setMatrix(response.data);
    } catch (error) {
      // @ts-ignore
      return error.message;
    }

    return true;
  };

  //converted mile price value to local currency
  //convert each meter value to mile
  //multiply alongside its per mile price depending on the locarion
  let meterPerMile = 0.000621371;
  const matrices: ElementsProps[] = matrix?.rows[0]?.elements ?? [],
    deliveryPriceBaseOnDisatance: number = matrices.reduce(function (
      previous: any,
      current: any
    ) {
      let valueInMile =
          !Boolean(current?.distance?.value) && prices.manullyDistance !== 0
            ? prices.manullyDistance * meterPerMile
            : current?.distance?.value * meterPerMile,
        mileTotalCostForSpecificDistance = valueInMile * prices.perMilePrice; //multiply per mile price;
      return previous + mileTotalCostForSpecificDistance; //aggragate
    },
    0);
  let deliveryPrice = Number(
    (deliveryPriceBaseOnDisatance + prices.shippingMethodFee).toFixed(2)
  );
  // console.log(
  //   "Result from matrix api",
  //   deliveryPriceBaseOnDisatance,
  //   prices.perMilePrice,
  //   prices.shippingMethodFee,
  //   matrix,
  //   deliveryPrice
  // );

  return {
    deliveryPrice,
    handleRetrieveLocation,
  };
};
