import axios from "axios";
import React, { useState } from "react";
import { baseURl } from "../../redux/env/host-url";

import { InputBox } from "./input-box";
import { useModal } from "./modal/modal.context";

type SelectCountryProps = {
  countrySelected?: any;
  stateSelected?: any;
  citySelected?: any;
  defaultCountry?: any;
  defaultState?: any;
  defaultCity?: any;
  enableCountry?: boolean;
  enableState?: boolean;
  enableCity?: boolean;
  autoTitle?: boolean;
};

function sorting(first: any, next: any) {
  let firstIndex = first.name.toLowerCase();
  let nextIndex = next.name.toLowerCase();

  // positoning front
  if (firstIndex < nextIndex) {
    return -1;
  }
  // positoning back
  if (nextIndex > firstIndex) {
    return 1;
  }
  // positoning middle
  return 0;
}

export function SelectCountry({
  countrySelected,
  stateSelected,
  citySelected,
  defaultCountry,
  defaultState,
  defaultCity,
  enableCountry = true,
  enableState = true,
  enableCity = true,
  autoTitle = true,
}: SelectCountryProps) {
  const { openModal } = useModal();
  const [showBox, setshowBox] = useState<{
    city: number;
    state: number;
  }>({
    city: 0,
    state: 0,
  });
  const [countriesList, setcountriesList] = useState<any[]>([]);
  const [defaultCountryInput, setdefaultCountryInput] = useState<boolean>(
    defaultCountry ? true : false
  );
  const [statesList, setstatesList] = useState<any[]>([]);
  const [citiesList, setcitiesList] = useState<any[]>([]);

  React.useEffect(() => {
    fetchCountries().then((response) => {
      if (response.includes("Network Error")) {
        openModal({ view: "ALERT", payload: response });
        return [];
      }
      setcountriesList(response);
    });
  }, []);

  // country_code: "AF";
  // country_name: "Afghanistan";
  // latitude: "33.00000000";
  // longitude: "65.00000000";
  const handleCountrySelection = async (item: any) => {
    let country: any = JSON.parse(item.target.value),
      country_code = country?.iso2,
      country_name = country?.name,
      location: any = null;

    let naming = country?.name ?? country,
      queryString = naming.replace(/[\s]/gi, "%20");
    await fetchLocationCoordinates(queryString).then(
      async (response) => (location = await response)
    );

    country?.name &&
      fetchStates(country?.iso2).then((response) =>
        setstatesList([
          {
            name: "Others",
            iso2: "",
            country_code: "",
            country_name: naming,
          },
          ...response.sort(sorting).map((countryx) => ({
            ...countryx,
            country_code,
            country_name,
          })),
        ])
      );

    const formatCountry = {
      country_code,
      country_name: naming,
      latitude: location?.lat,
      longitude: location?.lng,
    };

    countrySelected(formatCountry);
    setshowBox({ city: 0, state: 0 }); //reset
  };

  // latitude: "36.73477250";
  // longitude: "70.81199530";
  // state_code: "BDS";
  // state_name: "Badakhshan";
  const handleStateSelection = async (item: any) => {
    let needle = item.target.value.indexOf("{") === -1,
      state: any = needle ? item.target.value : JSON.parse(item.target.value),
      state_code = state?.iso2,
      state_name = state?.name,
      country_code = state?.country_code,
      country_name = state?.country_name,
      location: any = null;

    if (state.name === "Others") {
      return setshowBox({ ...showBox, state: 1 });
    }

    let naming = state_name ?? state,
      queryString =
        naming.replace(/[\s]/gi, "%20") +
        "%20" +
        state?.country_name.replace(/[\s]/gi, "%20");
    await fetchLocationCoordinates(queryString).then(
      async (response) => (location = await response)
    );

    state?.name &&
      fetchCites(state?.country_code, state?.iso2).then((response) =>
        setcitiesList([
          {
            name: "Others",
            iso2: "",
            state_code: "",
            state_name: naming,
            country_code,
            country_name,
          },
          ...response.sort(sorting).map((statex) => ({
            ...statex,
            state_code,
            state_name,
            country_code,
            country_name,
          })),
        ])
      );

    const formatState = {
      state_code,
      state_name: naming,
      latitude: location?.lat,
      longitude: location?.lng,
    };
    stateSelected(formatState);
  };

  // city_code: undefined;
  // city_name: "Ganye";
  // latitude: "8.43497000";
  // longitude: "12.05107000";
  const handleCitySelection = async (item: any) => {
    let needle = item.target.value.indexOf("{") === -1,
      city: any = needle ? item.target.value : JSON.parse(item.target.value),
      city_name = city?.name,
      location: any = null;

    if (city.name === "Others") {
      return setshowBox({ ...showBox, city: 1 });
    }

    let naming = city_name ?? city,
      queryString =
        naming.replace(/[\s]/gi, "%20") +
        "%20" +
        city?.state_name.replace(/[\s]/gi, "%20") +
        "%20" +
        city?.country_name.replace(/[\s]/gi, "%20");
    await fetchLocationCoordinates(queryString).then(
      async (response) => (location = await response)
    );

    const formatCity = {
      city_code: "",
      city_name: naming,
      latitude: location?.lat,
      longitude: location?.lng,
    };

    citySelected(formatCity);
  };

  return (
    <>
      {/* country */}
      {enableCountry && (
        <>
          {!defaultCountryInput ? (
            <select
              name="country"
              className="col-span-6 focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm border-gray-300 rounded-md"
              onChange={handleCountrySelection}
            >
              <option></option>
              {countriesList.map((item: any, index: number) => (
                <option value={JSON.stringify(item)} key={index}>
                  {item?.name}
                </option>
              ))}
            </select>
          ) : (
            <InputBox
              type="text"
              name={"country"}
              autoComplete={"Country"}
              classNameInput="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              classNameLabel="block text-sm font-medium text-gray-700"
              classNameGrouped="col-span-6"
              labelTitle={autoTitle ? "Country" : ""}
              placeholder={"Country"}
              defaultValue={defaultCountry}
              onChange={() => setdefaultCountryInput(!defaultCountryInput)}
            />
          )}
        </>
      )}

      {/* states */}
      {enableState && (
        <>
          {statesList.length > 0 &&
          !defaultCountryInput &&
          showBox.state < 1 ? (
            <select
              name="state"
              className="col-span-6 focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm border-gray-300 rounded-md"
              onChange={handleStateSelection}
            >
              <option></option>
              {statesList.map((item: any, index: number) => (
                <option value={JSON.stringify(item)} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
          ) : showBox.state === 1 ? (
            <InputBox
              type="text"
              name={"state"}
              autoComplete={"State  / Province"}
              classNameInput="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              classNameLabel="block text-sm font-medium text-gray-700"
              classNameGrouped="col-span-6"
              labelTitle={autoTitle ? "State  / Province" : ""}
              placeholder={"State  / Province"}
              defaultValue={defaultState}
              onChange={(v) => handleStateSelection(v)}
            />
          ) : (
            <InputBox
              type="text"
              name={"state"}
              autoComplete={"State  / Province"}
              classNameInput="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              classNameLabel="block text-sm font-medium text-gray-700"
              classNameGrouped="col-span-6"
              labelTitle={autoTitle ? "State  / Province" : ""}
              placeholder={"State  / Province"}
              defaultValue={defaultState}
              onChange={(v) => handleStateSelection(v)}
            />
          )}
        </>
      )}

      {/* cites */}
      {enableCity && (
        <>
          {citiesList.length > 0 && showBox.city < 1 ? (
            <select
              name="city"
              className="col-span-6 focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm border-gray-300 rounded-md"
              onChange={handleCitySelection}
            >
              <option></option>
              {citiesList.map((item: any, index: number) => (
                <option value={JSON.stringify(item)} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
          ) : showBox.city === 1 ? (
            <InputBox
              type="text"
              name={"city"}
              autoComplete={"City"}
              classNameInput="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              classNameLabel="block text-sm font-medium text-gray-700"
              classNameGrouped="col-span-6"
              labelTitle={autoTitle ? "City" : ""}
              placeholder={"City"}
              defaultValue={defaultCity}
              onChange={(v) => handleCitySelection(v)}
            />
          ) : (
            <InputBox
              type="text"
              name={"city"}
              autoComplete={"City"}
              classNameInput="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              classNameLabel="block text-sm font-medium text-gray-700"
              classNameGrouped="col-span-6"
              labelTitle={autoTitle ? "City" : ""}
              placeholder={"City"}
              defaultValue={defaultCity}
              onChange={(v) => handleCitySelection(v)}
            />
          )}
        </>
      )}
    </>
  );
}

async function fetchCountries() {
  try {
    const config = {
        headers: {
          "X-CSCAPI-KEY":
            "WUpUYzY1Z0g5TlRpOWh5Q1lEbW8wSmFJN2Vrbm8zWWlzNFVOQnJiWA==",
        },
      },
      response = await axios.get(
        "https://api.countrystatecity.in/v1/countries/",
        config
      );

    return response.data;
  } catch (error) {
    // @ts-ignore
    return error.message;
  }
}

async function fetchStates(iso: string) {
  try {
    const config = {
        headers: {
          "X-CSCAPI-KEY":
            "WUpUYzY1Z0g5TlRpOWh5Q1lEbW8wSmFJN2Vrbm8zWWlzNFVOQnJiWA==",
        },
      },
      response = await axios.get(
        `https://api.countrystatecity.in/v1/countries/${iso}/states`,
        config
      );

    return response.data;
  } catch (error) {
    // @ts-ignore
    return error.message;
  }
}

async function fetchCites(isoConutry: string, isoState: string) {
  try {
    const config = {
        headers: {
          "X-CSCAPI-KEY":
            "WUpUYzY1Z0g5TlRpOWh5Q1lEbW8wSmFJN2Vrbm8zWWlzNFVOQnJiWA==",
        },
      },
      response = await axios.get(
        `https://api.countrystatecity.in/v1/countries/${isoConutry}/states/${isoState}/cities`,
        config
      );

    return response.data;
  } catch (error) {
    // @ts-ignore
    return error.message;
  }
}
async function fetchLocationCoordinates(location: string) {
  try {
    const config = {
        "Content-Type": "application/json",
        Accept: "application/json",
        headers: {},
      },
      data = {
        location,
      },
      response = await axios.post(`${baseURl}/find-place`, data, config);
    return response.data?.candidates[0]?.geometry?.location;
  } catch (error) {
    // @ts-ignore
    return error.message;
  }
}
