/*jshint ignore:start*/
import "./common.css";
import React from "react";
import { Link } from "react-router-dom";
import policyImage from "../staticImages/policy.png";

export const Return = (props) => {
  return (
    <div>
      <section class="jumbotron text-center py-5 my-2 md:py-10 md:my-5">
        <div class="container md:py-10">
          <h1 class="jumbotron-heading md:text-[3em]">RETURNS AND REFUNDS</h1>
        </div>
      </section>

      <div class="container pb-3 mb-1">
        <div class="card mb-3">
          <div class="p-4 text-center text-white text-lg bg-[#403D94] rounded-t-lg">
            <h3 class="text-white">
              Returns can be initiated within 7 days if you received a wrong,
              damaged, defective or counterfeit item. Kindly{" "}
              <Link to={"/support"}>Contact us</Link> to initiate a return.
            </h3>
          </div>
          <div class="card-body" data-aos="fade-up" data-aos-duration="1000">
            <div class="steps md:flex justify-between items-center pt-2 pb-1">
              <div class="step" data-aos="fade-up" data-aos-duration="1000">
                <img class="about-img-2 w-100" src={policyImage} />
              </div>
              <div
                class="step"
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
              >
                <h2 class="help-step-title heading text-left">Return Policy</h2>
                <h2 class="text-left subtitle">
                  When returning an item, ensure all seals, tags and accessories
                  are left intact and item is in its original packaging. Keep
                  the original carton and packaging material for a smooth and
                  easy return. If you have created a password on the device you
                  wish to return, please ensure it is removed, otherwise, your
                  return will be invalid.
                </h2>
                <h2 class="text-left subtitle">
                  Validity of return is subject to PX Stores’ administrator
                  discretion and consideration. Refunds are only possible after
                  inspection of item.
                </h2>
                <h2 class="text-left subtitle">
                  Please note that shipping fees are not refunded and not all
                  products are eligible for return.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
