import React from "react";
import ReactDOM from "react-dom/client";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.css";

import App from "./App";
import store from "./redux/config";
import { Provider } from "react-redux";

import reportWebVitals from "./reportWebVitals";
import { ModalProvider } from "./components/ui/modal/modal.context";
import { ModalManager } from "./components/ui/modal/modal-manager";
import { CartProvider } from "./components/ui/cart/shopping-cart.context";
import ShoppingCart from "./components/ui/cart/shopping-cart";

const rootElement: any = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ModalProvider>
        <CartProvider>
          <App />
          <ModalManager />
          <ShoppingCart />
        </CartProvider>
      </ModalProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
