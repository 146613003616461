import { configureStore } from "@reduxjs/toolkit";
import user from "../reducers/user-reducer";
import cart from "../reducers/cart-reducer";
import product from "../reducers/product-reducer";
import support from "../reducers/support-reducer";
import brand from "../reducers/brand-reducer";
import subcription from "../reducers/subcription-reducer";
import order from "../reducers/order-reducer";
import subcriptionPlan from "../reducers/subcriptionPlan-reducer";
import job from "../reducers/job-reducer";
import exchanges from "../reducers/exchanges-reducer";
import category from "../reducers/category-reducer";
import address from "../reducers/address-reducer";
import shipping from "../reducers/shipping-reducer";
import mile from "../reducers/mile-reducer";
import search from "../reducers/search-engine-reducer";
import deletions from "../reducers/deletions-reducer";
import chats from "../reducers/chat-reducer";

const store = configureStore({
  reducer: {
    user,
    cart,
    product,
    support,
    brand,
    subcription,
    order,
    subcriptionPlan,
    job,
    exchanges,
    category,
    address,
    shipping,
    mile,
    search,
    deletions,
    chats,
  },
});
export default store;
