import React from "react";

import { baseURl } from "../../redux/env/host-url";


interface PaginationProps {
    data: object[] | object
    error: string | null
    loading:boolean
}

type AuthProps = {
    pagination: (arg:number)=> void
}

export function usePersonal(targetUser:boolean = false):AuthProps & PaginationProps{
    const [currentPage, newPage] = React.useState<any>(1);
    const [users, getUsers] = React.useState<PaginationProps>({
        data:[],
        error: null, 
        loading:false  
    });




    async function server(signal:any) {   
        //startup loaading
        getUsers({
            ...users,
            loading:true
        })
        
        const savedToken = await window.localStorage.getItem('token');
        // @ts-ignore
        let token = savedToken.slice(0,savedToken?.lastIndexOf(".")),
        // @ts-ignore
        identity = savedToken?.slice(savedToken?.lastIndexOf(".")+1) as any;

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+token);
        
        var formdata = new FormData();
        targetUser 
        ? formdata.append("id", identity)
        : formdata.append("page", currentPage);
        
        var requestOptions:any = {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
          signal: signal,
          redirect: 'follow'
        };
        
        await fetch(baseURl+"/users", requestOptions)
          .then(response => response.json())
          .then(result => 
            getUsers({
                data:result,
                error:null,
                loading:false
            }))
          .catch(error => 
            getUsers({
                data:[],
                error:error,
                loading:false
            }));
    }


    React.useEffect(()=>{
        const Controller = new AbortController();
        const signal = Controller.signal;
        
        server(signal);

        //cleanup
        return () => {
            Controller.abort();
        }
    },[]);
    
    const pagination = (pageNumber:number = 0) => {
        return newPage(currentPage + pageNumber);
    }
    
    return {
        error: users.error, 
        loading: users.loading,
        // @ts-ignore
        data: users.data.detail ?? null,
        pagination
    };
}
