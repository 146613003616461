import React, { SyntheticEvent } from "react";
import { LockClosedIcon } from "@heroicons/react/solid";

import { InputBox } from "../ui/input-box";
import ROUTES from "../../constants/routes-list";
import { useUser } from "../hook/use-user";
import { useModal } from "../ui/modal/modal.context";
import axios, { AxiosRequestConfig } from "axios";
import { baseURl } from "../../redux/env/host-url";

type LoginProps = {
  credential: string;
  password: string;
};

function Login() {
  const { openModal } = useModal();
  const { userAccess, info, error } = useUser();
  const [show, setShow] = React.useState<boolean>(false);
  const { login, internalLogin } = ExternalLogin();
  const [saved, save] = React.useState<LoginProps | null>(null);

  const handleSubmitFrom = () => {
    return userAccess({
      ...saved,
      source: "inbuilt",
      actionType: "login",
    });
  };

  const handlSubmitParcelExhange = () => {
    return login(saved);
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (!info.status && (info.query || error)) {
        openModal({ view: "ALERT", payload: info.query || error });
      }

      if (info.status) {
        window.location.replace("/");
      }
    }, 1200);
  }, [info]);

  const handleSubmitFacebook = () => {
    // @ts-ignore
    FB.getLoginStatus(function (response) {
      const { firstname, lastname, email } =
        response?.authResponse?.signedRequest;
      console.log("response ", response);
      if (response.status === "connected") {
        // updatePermissions();
        // the user is logged in and has authenticated your
        // app, and response.authResponse supplies
        // the user's ID, a valid access token, a signed
        // request, and the time the access token
        // and signed request each expire
        // @ts-ignore
        FB.login(
          function (response) {
            // handle the response
            console.log("response when connected ", response);
          },
          { scope: "publish_actions" }
        );
      } else if (response.status === "not_authorized") {
        // the user is logged in to Facebook,
        // but has not authenticated your app
      } else {
        // @ts-ignore
        FB.login(
          function (response) {
            console.log("response when connected ", response);
          },
          { scope: "publish_actions" }
        );
      }
      //   if (Boolean(email)) {
      //     internalLogin({
      //       name: firstname + " " + lastname,
      //       email,
      //       phone: "N/A" + Math.random(),
      //       source: "facebook",
      //       actionType: "login",
      //     });
      //   }
    });
  };
  return (
    <div className="min-h-[700px] flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 my-auto">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <form className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm -space-y-px">
            <InputBox
              type="text"
              autoComplete="credential"
              required={true}
              classNameInput="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Email or Phone Number e.g +44xxx"
              name="credential" //{...register('email')}
              // @ts-ignore
              onChange={(v) => save({ ...saved, credential: v.target.value })}
            />
            <InputBox
              type={show ? "texe" : "password"}
              autoComplete="current-password"
              required={true}
              classNameInput="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Password"
              name="password"
              // @ts-ignore
              onChange={(v) => save({ ...saved, password: v.target.value })}
            />
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              name="show_password"
              onClick={() => setShow(!show)}
            />
            <div className="ml-3 text-[12px]">Show Password</div>
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <a
                href={ROUTES.RESET}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Forgot your password?
              </a>
            </div>
            <div className="text-sm">
              <a
                href={ROUTES.REGISTER}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                I don't have an account
              </a>
            </div>
          </div>

          <div>
            <button
              onClick={handleSubmitFrom}
              type="button"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#403d93] hover:bg-[#6e6bd5] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  aria-hidden="true"
                />
              </span>
              Sign in
            </button>

            <button
              onClick={handlSubmitParcelExhange}
              type="button"
              className="mt-[10px] group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#f77b55] hover:bg-[#f56438] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-[#f08e71] group-hover:text-[#ec8e71]"
                  aria-hidden="true"
                />
              </span>
              via Parcel Exchange
            </button>

            {/* <div className="my-5 text-center">Or</div>

            <button
              onClick={handleSubmitFacebook}
              type="button"
              className="mt-[10px] group relative w-full flex justify-center py-0 px-4 border border-transparent rounded-md bg-[#115df5] hover:bg-[#246dfe] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <div
                className="fb-login-button text-[inherit]"
                data-width="500"
                data-size="large"
                data-button-type="continue_with"
                data-layout="default"
                data-auto-logout-link="false"
                data-use-continue-as="false"
              ></div>
            </button> */}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;

function ExternalLogin() {
  const [responseData, setresponseData] = React.useState<{
    info: any;
    error: any;
  }>({
    info: null,
    error: "",
  });

  async function login(payload: any) {
    const { credential, password } = payload;
    try {
      const response = await axios.get(
        `https://parcelexchangegroup.com/webservice/webservice.php?action=login&email=${credential}&password=${password}`
      );

      const info = response.data?.result?.data;
      return internalLogin({
        name: info.fname + " " + info.lname,
        email: info.email,
        phone: info.phone_number,
        source: "px_member",
        actionType: "login",
      });
    } catch (error) {
      // @ts-ignore
      return setresponseData({ info: null, error: error.message });
    }
  }
  async function internalLogin(payload: any) {
    try {
      let config: AxiosRequestConfig = {
        method: "post",
        url: `${baseURl}/user/px`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: payload,
      };

      const response = await axios(config);
      //   return console.log({ info: response.data, error: "" });
      setresponseData({ info: response.data?.detail, error: "" });

      //register token to the app
      if (response.data.detail.token) {
        window.localStorage.setItem(
          "token",
          ((response.data.detail.token as string) +
            "." +
            response.data.detail.id) as string
        );
      }

      setTimeout(() => {
        response.data?.query?.includes("success") &&
          window.location.replace("/");
      }, 1200);
    } catch (error) {
      // @ts-ignore
      return setresponseData({ info: null, error: error.message });
    }
  }
  return {
    login,
    ...responseData,
    internalLogin,
  };
}
