import React from "react";
import { Navigate, Outlet } from 'react-router-dom';
import ROUTES from '../../constants/routes-list';

export const ProtectedRoute = ({
    isAllowed,
    redirectPath = ROUTES.LOGIN,
  }) => {
    if (!isAllowed) {
      return <Navigate to={redirectPath} replace />;
    }
  
    return <Outlet />;
};
