import React from "react";
import { useProduct } from "../hook/use-product";

import Categories from "../ui/category/categories-section";
import Collection from "../ui/colletions/collection";
import ProductList from "../ui/products/product-list";
import Promo from "../ui/promo-section";
import { useConverter } from "../hook/currency-conversion";
import { useCategory } from "../hook/use-category";

function Landing() {
  const { getProduct, info } = useProduct();
  const { iforex, iforexWS, liveInfo } = useConverter();
  const categories = useCategory();

  //restructing product to add key with string type for flat list
  let listed =
    info.HLL?.data
      .map((value: any) => {
        // with symbol
        // @eslint-disable-next-line react-hooks/exhaustive-deps
        let sale_price_fx = iforex({
          from: value.currency.code,
          value: value.sale_price,
        });
        // @eslint-disable-next-line react-hooks/exhaustive-deps
        let price_fx =
          value.price > 0
            ? iforex({ from: value.currency.code, value: value.price })
            : value.price;

        // without symbol
        // @eslint-disable-next-line react-hooks/exhaustive-deps
        let sale_price_fxws = iforexWS({
          from: value.currency.code,
          value: value.sale_price,
        });
        // @eslint-disable-next-line react-hooks/exhaustive-deps
        let price_fxws =
          value.price > 0
            ? iforexWS({ from: value.currency.code, value: value.price })
            : value.price;
        let shop_address = JSON.parse(value?.shop?.address ?? "{}"),
          shop_location =
            typeof shop_address?.country === "string"
              ? shop_address?.country
              : shop_address?.country?.country_name,
          user_location = liveInfo?.location?.country?.name;
        let isFromAbroad =
          shop_location?.toLowerCase() !== user_location?.toLowerCase()
            ? "International"
            : "";

        return (value = {
          ...value,
          sale_price_fx,
          price_fx,
          sale_price_fxws,
          price_fxws,
          isFromAbroad,
          key: String(value.id),
        });
      })
      .filter((v: any) => v?.state !== "draft" && v?.shop?.is_active !== 0)
      .filter((v: any) => v?.in_stock > 0) ?? [];
  let listedCategories = categories.info.HLL?.data
    ? categories.info.HLL?.data
    : [];

  React.useEffect(() => {
    getProduct({ page: 1 });
    categories.getCategory({ page: 1 });
  }, []);

  // console.log("product",info.HLL?.data)
  return (
    <div className="Landing">
      <Categories list={listedCategories} />
      <Promo />
      <Collection list={listed} />
      <ProductList products={listed} />
    </div>
  );
}
export default Landing;
