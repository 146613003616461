import React from "react";
import { Card, PackageCard } from "./card";


type CheckDefultProps = {
    type?:string
    name:string
    autoComplete?:string
    required?:boolean
    classNameCheck?:string[] | string
    defaultChecked?:boolean
    htmlFor?:string
    classNameLabel?:string[] | string
    labelTitle?:string
    classNameGrouped?:string
    onChange:(arg:any)=>any
    list?:any
    classNameGroupedList?:any
    useDisplayComponent?:boolean
}
export const CheckBox = ({
    type = "text",
    name = "",
    autoComplete = "",
    required = false,
    classNameCheck,
    htmlFor = "",
    classNameLabel = "sr-only",
    labelTitle = "",
    classNameGrouped = "",
    onChange,
 }:CheckDefultProps) => {
    const styliCheck = typeof classNameCheck === 'object' 
        ? classNameCheck.join(" ") as string
        : classNameCheck;

    const styliLabel = typeof classNameLabel === 'object' 
        ? classNameLabel.join(" ") as string
        : classNameLabel;

    let label = name.replace(/\s/gi, "-");
    return (
        <div className={classNameGrouped}>
            <input
                id={htmlFor ?? label}
                name={name}
                type={type}
                autoComplete={autoComplete ?? name}
                required={required}
                className={styliCheck}
                onChange={onChange}
            />
            <label htmlFor={htmlFor ?? label} className={styliLabel}>
                {labelTitle}
            </label>
        </div>
    )
}
export const CheckBoxWithOption = ({
    type = "text",
    name = "",
    autoComplete = "",
    required = false,
    classNameCheck,
    htmlFor = "",
    classNameLabel = "sr-only",
    classNameGrouped = "",
    onChange,
    classNameGroupedList,
    list=[],
    useDisplayComponent,
 }:CheckDefultProps) => {
    const [isChecked, isCheck] = React.useState<{ index:number, status:boolean }>({ index:0, status:false });
    const [listedValue, listValue] = React.useState<any[]>(
        ()=>list.map((item)=>{
            return {
                ...item,
                status:false 
            }
        })
    );
    const styliCheck = typeof classNameCheck === 'object' 
        ? classNameCheck.join(" ") as string
        : classNameCheck;

    const styliLabel = typeof classNameLabel === 'object' 
        ? classNameLabel.join(" ") as string
        : classNameLabel;

    let label = name.replace(/\s/gi, "-");

    return (
        <div className={classNameGroupedList}>
            {listedValue.map((item, index)=>(<div key={index} className={classNameGrouped}>
                <input
                    id={htmlFor ?? label}
                    name={name}
                    type={type}
                    autoComplete={autoComplete ?? name}
                    required={required}
                    className={styliCheck}
                    // defaultChecked={isChecked.index !== index ? !isChecked.status : false }
                    // checked={isChecked.index === index ? isChecked.status : false }
                    onChange={(e)=>{
                        // @ts-ignore
                        onChange(item);
                        listValue(()=>{
                            let listedItems:any[] = list.map((current, i)=>{
                                if (i === index) {
                                    return {
                                        ...current,
                                        status:true 
                                    }
                                }
                                return {
                                    ...current,
                                    status:false 
                                }
                            });
                            return listedItems;
                        });

                    }}
                />
                {!useDisplayComponent?
                    <label htmlFor={htmlFor ?? label} className={styliLabel}>
                        {item?.title ?? item}
                    </label>
                    :
                    <Card 
                        item={item}
                        cardStyli="mx-10 my-5 shadow"
                    />
                }
            </div>))}
        </div>
    )
}
