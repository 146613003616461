/*jshint ignore:start*/
import "./common.css";
import React from "react";
import { ChevronsDown } from "react-feather";

export const Sell = (props) => {
  return (
    <div>
      <section className="text-center">
        <div className="pb-3 mb-1">
          <h1 className="md:text-[3em] font-medium text-center  py-5 my-2 md:py-10 md:my-5">
            SELL ON PX STORES
          </h1>
          <div className="container">
            <div className="card mb-3">
              <div className="px-3 py-10 text-center text-white text-lg md:text-[2.5em] font-bold italic bg-[#403D94] rounded-t-lg">
                <span className="italics-bold-header">
                  <strong style={{ fontWeight: 600 }}>
                    Kick-start selling on PX Stores
                  </strong>
                </span>
              </div>
              <div
                className="text-center text-white"
                style={{ marginBottom: "32px" }}
              >
                <div className="angle-double-down flex justify-center items-center">
                  <a href="#sell-on-pxstores">
                    <ChevronsDown
                      className="fa fa-4x fa-angle-double-down text-[#403D94]"
                      style={{ margin: "20px", fontSize: "50px" }}
                      size={48}
                    />
                  </a>
                </div>
              </div>
              <div
                id="sell-on-pxstores"
                className="card-body"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="steps md:flex justify-between items-center pt-2 pb-1">
                  <div
                    className="step step-effect flex flex-col justify-center items-center p-2 md:p-10 hover:shadow shadow-gray-500"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <img
                      className="selling-img md:w-[285px] md:h-[185px]"
                      src="https://pxstores-frontend.netlify.app/images/selling.jpg"
                    />
                    <h2 className="help-step-title text-[#403D94] font-bold">
                      Sell on PX Stores
                    </h2>
                    <h2 className="step-title text-sm">
                      Listing your products for sale on PX Stores enables you
                      reach out to millions of shoppers globally, whether you’re
                      selling a single item or millions.
                    </h2>
                  </div>
                  <div
                    className="step step-effect flex flex-col justify-center items-center p-2 md:p-10 hover:shadow shadow-gray-500"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <img
                      className="selling-img md:w-[285px] md:h-[185px]"
                      src="https://pxstores-frontend.netlify.app/images/1200.jpg"
                    />
                    <h2 className="help-step-title text-[#403D94] font-bold">
                      Deliver by Parcel Exchange
                    </h2>
                    <h2 className="step-title text-sm">
                      <strong>DPE</strong> is an intergrated logistic solution
                      provided by our logistics arm, Parcel Exchange which
                      receives orders made automatically, picks, packs and
                      deliver goods to customers timely.
                    </h2>
                  </div>
                </div>
              </div>
              <div
                className="p-4 text-center text-white text-lg bg-[#403D94] rounded-t-lg"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <span className="italics-bold-header">
                  <strong style={{ fontWeight: 600 }}>
                    How do sellers benefit?
                  </strong>
                </span>
                <h3 className="text-white md:text-2xl">
                  Selling on PX Stores means your products are available to
                  buyers globally and you do not need to worry about how to ship
                  to your customers as we have it covered.
                </h3>
              </div>
              <div
                className="card-body"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="md:grid md:grid-cols-4 gap-6 items-baseline steps pt-2 pb-1">
                  <div
                    className="step step-effect py-5 min-h-[200px] flex flex-col justify-center items-center"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                      <img
                        className="icon-img w-[50px] h-[50px]"
                        src="https://pxstores-frontend.netlify.app/images/icons/globe.png"
                      />
                    </div>
                    <h3 className="text-[14px] text-[#403D94] text-center font-black help-step-title">
                      Sell to millions around the globe.
                    </h3>
                  </div>
                  <div
                    className="step step-effect py-5 min-h-[200px] flex flex-col justify-center items-center"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                      <img
                        className="icon-img w-[50px] h-[50px]"
                        src="https://pxstores-frontend.netlify.app/images/icons/one-account.png"
                      />
                    </div>
                    <h3 className="text-[14px] text-[#403D94] text-center font-black help-step-title">
                      Create one account and <br /> sell globally.
                    </h3>
                  </div>
                  <div
                    className="step step-effect py-5 min-h-[200px] flex flex-col justify-center items-center"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                      <img
                        className="icon-img w-[50px] h-[50px]"
                        src="https://pxstores-frontend.netlify.app/images/icons/delivery.png"
                      />
                    </div>
                    <h3 className="text-[14px] text-[#403D94] text-center font-black help-step-title">
                      You sell, we pickup, your customer tracks, we deliver.
                    </h3>
                  </div>
                  <div
                    className="step step-effect py-5 min-h-[200px] flex flex-col justify-center items-center"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                      <img
                        className="icon-img w-[50px] h-[50px]"
                        src="https://pxstores-frontend.netlify.app/images/icons/returns%20and%20refunds.png"
                      />
                    </div>
                    <h3 className="text-[14px] text-[#403D94] text-center font-black help-step-title">
                      We handle returns and refunds
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="text-center text-white"
            data-aos="fade-up"
            data-aos-duration="1000"
            style={{ margin: "32px" }}
          >
            <a
              href="https://dashboard.pxstores.com"
              target="_blank"
              className="start-selling-btn text-black"
            >
              START SELLING
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
