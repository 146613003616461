import React, { SyntheticEvent } from "react";
import { LockClosedIcon } from "@heroicons/react/solid";

import { InputBox } from "../ui/input-box";
import { useUser } from "../hook/use-user";
import { useModal } from "../ui/modal/modal.context";
import { useConverter } from "../hook/currency-conversion";
import classNames from "classnames";

function Register() {
  const { liveInfo } = useConverter();
  const { openModal } = useModal();
  const { userRegister, info, error } = useUser();
  const [show, setShow] = React.useState<boolean>(false);
  const [terms, setterms] = React.useState<{
    status: boolean;
    checked: boolean;
  }>({ status: false, checked: false });

  //preset
  let dailingCode = liveInfo?.location?.country?.dialing_code[0];

  const handleSubmitFrom = (e: SyntheticEvent) => {
    e.preventDefault();

    if (!terms.checked) {
      setterms({ ...terms, status: true });
      return;
    }

    const target = e.target as typeof e.target & {
      name: {
        value: string;
      };
      email: {
        value: string;
      };
      phone: {
        value: string;
      };
      password: {
        value: string;
      };
    };

    if (target.password.value.length < 8) {
      return openModal({
        view: "ALERT",
        payload: "Your password must be minimum 8 digits",
      });
    }

    const save = {
      name: target.name.value,
      email: target.email.value,
      phone: target.phone.value,
      password: target.password.value,
    };

    return userRegister({
      ...save,
      abilities: "[1]",
      source: "inbuilt",
    });
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (!info.status && (info.query || error)) {
        openModal({ view: "ALERT", payload: info.query ?? error });
      }

      if (info.status) {
        window.location.replace("/");
      }
    }, 1200);
  }, [info]);

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 my-auto">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Create an account
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmitFrom}>
          <div className="rounded-md shadow-sm -space-y-px">
            <InputBox
              name="name"
              type="name"
              required={true}
              classNameInput="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Full Name"
            />
            <InputBox
              name="email"
              type="email"
              required={true}
              classNameInput="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Email address"
            />
            <InputBox
              name="phone"
              type="tel"
              required={true}
              classNameInput="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Phone Number"
              defaultValue={"+" + dailingCode}
            />
            <InputBox
              name="password"
              type={show ? "texe" : "password"}
              required={true}
              classNameInput="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Password"
            />
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              name="show_password"
              onClick={() => setShow(!show)}
            />
            <div className="ml-3 text-[12px]">Show Password</div>
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              name="show_password"
              onClick={() => setterms({ checked: true, status: false })}
            />
            <div
              className={classNames([
                "ml-3 text-[12px] underline text-sm text-blue-500",
                terms.status ? "border border-red-500 p-1" : "border-none p-1",
              ])}
            >
              <a href="/terms-and-conditions">{"Terms and Conditions"}</a>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#403d93] hover:bg-[#6e6bd5] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {/* <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
              </span> */}
              Register
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
