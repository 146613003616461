import React from 'react';

import { CommissionList } from './item-list';

interface Props {
    onCLick?:any
    list?:any[]
}

function ShopShortList({ onCLick, list = [] }: Props) {

    return (
        <div className="bg-white max-h-screen overflow-auto">
            <div className='h3 text-center font-bold py-10'>Select Shop for commissioning </div>
            <CommissionList 
                list={list} 
                layoutStyli="mb-[100px] sm:pl-20 sm:pr-10" 
                card={{ frameStyli:"shadow rounded-md sm:w-[500px] w-full", itemStyli:"capitalize border-b border-gray-100" }}
                horizontal={true}
                onSelect={(v)=>onCLick({ ["shop_id"]:v.id })}/> 
        </div>
    )
}

export default ShopShortList;
