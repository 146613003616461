/*jshint ignore:start*/
import "./common.css";
import React from "react";
import logo from "../staticImages/px-logo.jpg";
import arrowForward from "../staticImages/arrow-forward.png";
import arrowDown from "../staticImages/arrow-down.png";

export const About = (props) => {
  return (
    <div>
      <section className="jumbotron text-center">
        <div className="container">
          <h1 className="jumbotron-heading md:text-[3em]">ABOUT US</h1>
          <p className="lead opacity-50 mb-0 help-step-title md:mt-4">
            Who we are
          </p>
        </div>
      </section>

      <div className="container">
        <div className="card m-auto">
          <div
            className="card-body"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="steps md:flex justify-between items-center pt-2 pb-1">
              <div className="step" data-aos="fade-up" data-aos-duration="1000">
                <img className="about-img-2" src={logo} />
              </div>
              <div className="step" data-aos="fade-up" data-aos-duration="1000">
                <h2 className="text-lg md:text-[4em] text-[#403D94] font-bold md:pb-10">
                  Who we are
                </h2>
                <h2 className="text-sm md:text-lg subtitle">
                  PX Stores was born out of necessity when we launched Parcel
                  Exchange, our logistics production system, which is an
                  innovation and first of its kind. At the launch, it was
                  evident our customers wanted a store where they could sell
                  online, so we had to create PX Stores.
                </h2>{" "}
                <br />
                <h2 className="text-sm md:text-lg subtitle">
                  The order fulfilment of PX Stores which is the complete
                  process from point of sales inquiry to delivery of a product
                  to the customer is achieved through either Just In Time or
                  Warehousing fulfilment.
                </h2>
              </div>
            </div>
          </div>
          <div
            className="card-body"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="steps md:flex justify-evenly items-center pt-2 pb-1  my-10">
              <div
                className="step step-effect flex flex-col justify-center items-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="mb-10">
                  <img
                    className="about-img-image h-[185px] w-[285px]"
                    src="https://pxstores-frontend.netlify.app/images/delivery.png"
                  />
                </div>
                <h3 className="text-white text-[10px] md:text-[16px]">
                  <a
                    className="help-step-title start-selling-btn start-selling-btn-mobile bg-orange-600 rounded-full p-5 font-bold"
                    href="#j-i-t"
                  >
                    How Just In Time Fulfilment Works
                  </a>
                </h3>
              </div>
              <div
                className="step step-effect mt-[100px] md:mt-0 flex flex-col justify-center items-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="mb-10">
                  <img
                    className="about-img-image h-[185px] w-[285px]"
                    src="https://pxstores-frontend.netlify.app/images/warehousing.png"
                  />
                </div>
                <h3 className=" text-white text-[10px] md:text-[16px]">
                  <a
                    className="help-step-title start-selling-btn start-selling-btn-mobile bg-orange-600 rounded-full p-5 font-bold"
                    href="#warehousing"
                  >
                    How Warehousing Fulfilment Works
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div id="j-i-t"></div>
          <div
            className="p-4 text-center text-white text-lg bg-[#403D94] rounded-t-lg"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h3 className="text-white text-[10px] md:text-[16px]">
              Just In Time Fulfilment Process Work Flow
            </h3>
          </div>
          <div
            className="card-body"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="md:grid md:grid-cols-5 gap-3 items-baseline pt-2 pb-1">
              <div className="mb-5 md:mb-0 flex flex-col md:flex-row justify-center items-center">
                <div
                  className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                    <img
                      className="icon-img w-[50px] h-[50px]"
                      src="https://pxstores-frontend.netlify.app/images/icons/orders.png"
                    />
                  </div>
                  <h6 className="text-[14px] text-[#403D94] text-center font-black help-step-title">
                    Customer Orders at PX Stores.
                  </h6>
                </div>
                <ArrowForward className="hidden md:block text-orange-600 font-black" />
                <ArrowDown className="bloack md:hidden text-orange-600 font-black" />
              </div>
              <div className="mb-5 md:mb-0 flex flex-col md:flex-row justify-center items-center">
                <div
                  className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                    <img
                      className="icon-img w-[50px] h-[50px]"
                      src="https://pxstores-frontend.netlify.app/images/icons/order-accepted.png"
                    />
                  </div>
                  <h6 className="text-[14px] text-[#403D94] text-center font-black help-step-title">
                    Parcel Exchange Receives Orders Real Time
                  </h6>
                </div>
                <ArrowForward className="hidden md:block text-orange-600 font-black" />
                <ArrowDown className="bloack md:hidden text-orange-600 font-black" />
              </div>
              <div className="mb-5 md:mb-0 flex flex-col md:flex-row justify-center items-center">
                <div
                  className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                    <img
                      className="icon-img w-[50px] h-[50px]"
                      src="https://pxstores-frontend.netlify.app/images/icons/registered-carriers.png"
                    />
                  </div>
                  <h6 className="text-[14px] text-[#403D94] text-center font-black help-step-title">
                    Parcel Exchange Assigns <br />
                    To Registered Carriers
                  </h6>
                </div>
                <ArrowForward className="hidden md:block text-orange-600 font-black" />
                <ArrowDown className="bloack md:hidden text-orange-600 font-black" />
              </div>
              <div className="mb-5 md:mb-0 flex flex-col md:flex-row justify-center items-center">
                <div
                  className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                    <img
                      className="icon-img w-[50px] h-[50px]"
                      src="https://pxstores-frontend.netlify.app/images/icons/pickup.png"
                    />
                  </div>
                  <h6 className="text-[14px] text-[#403D94] text-center font-black help-step-title">
                    Carriers Pickup Packed <br />
                    Items from Sellers
                  </h6>
                </div>
                <ArrowForward className="hidden md:block text-orange-600 font-black" />
                <ArrowDown className="bloack md:hidden text-orange-600 font-black" />
              </div>
              <div className="mb-5 md:mb-0 flex flex-col md:flex-row justify-center items-center">
                <div
                  className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                    <img
                      className="icon-img w-[50px] h-[50px]"
                      src="https://pxstores-frontend.netlify.app/images/icons/delivered-to-customer.png"
                    />
                  </div>
                  <h6 className="text-[14px] text-[#403D94] text-center font-black help-step-title">
                    Carriers Deliver to <br />
                    Customer
                  </h6>
                  {/* <ArrowForward className="hidden md:block text-orange-600 font-black" /> */}
                  {/* <ArrowDown className="bloack md:hidden text-orange-600 font-black" /> */}
                </div>
              </div>
            </div>
          </div>
          <div id="warehousing"></div>
          <div
            className="p-4 text-center text-white text-lg bg-[#403D94] rounded-t-lg"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h3 className="text-white">
              Warehousing Fulfilment Process Work Flow
            </h3>
          </div>
          <div
            className="card-body"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="md:grid md:grid-cols-6 gap-6 items-baseline pt-2 pb-1">
              <div className="mb-5 md:mb-0 flex flex-col md:flex-row justify-center items-center">
                <div
                  className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                    <img
                      className="icon-img w-[50px] h-[50px]"
                      src="https://pxstores-frontend.netlify.app/images/icons/orders.png"
                    />
                  </div>
                  <h6 className="text-[14px] text-[#403D94] text-center font-black help-step-title">
                    Customer Orders at PX Stores.
                  </h6>
                </div>
                <ArrowForward className="hidden md:block text-orange-600 font-black" />
                <ArrowDown className="bloack md:hidden text-orange-600 font-black" />
              </div>
              <div className="mb-5 md:mb-0 flex flex-col md:flex-row justify-center items-center">
                <div
                  className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                    <img
                      className="icon-img w-[50px] h-[50px]"
                      src="https://pxstores-frontend.netlify.app/images/icons/order-accepted.png"
                    />
                  </div>
                  <h6 className="text-[14px] text-[#403D94] text-center font-black help-step-title">
                    Parcel Exchange Receives Orders Real Time
                  </h6>
                </div>
                <ArrowForward className="hidden md:block text-orange-600 font-black" />
                <ArrowDown className="bloack md:hidden text-orange-600 font-black" />
              </div>
              <div className="mb-5 md:mb-0 flex flex-col md:flex-row justify-center items-center">
                <div
                  className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                    <img
                      className="icon-img w-[50px] h-[50px]"
                      src="https://pxstores-frontend.netlify.app/images/icons/registered-carriers.png"
                    />
                  </div>
                  <h6 className="text-[14px] text-[#403D94] text-center font-black help-step-title">
                    Parcel Exchange Assigns <br />
                    To Registered Carriers
                  </h6>
                </div>
                <ArrowForward className="hidden md:block text-orange-600 font-black" />
                <ArrowDown className="bloack md:hidden text-orange-600 font-black" />
              </div>
              <div className="mb-5 md:mb-0 flex flex-col md:flex-row justify-center items-center">
                <div
                  className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                    <img
                      className="icon-img w-[50px] h-[50px]"
                      src="https://pxstores-frontend.netlify.app/images/icons/warehouse.png"
                    />
                  </div>
                  <h6 className="text-[14px] text-[#403D94] text-center font-black help-step-title">
                    Carriers Pickup from Sellers/Manufacturers To PX Stores{" "}
                    <br />
                    Warehouses
                  </h6>
                </div>
                <ArrowForward className="hidden md:block text-orange-600 font-black" />
                <ArrowDown className="bloack md:hidden text-orange-600 font-black" />
              </div>
              <div className="mb-5 md:mb-0 flex flex-col md:flex-row justify-center items-center">
                <div
                  className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                    <img
                      className="icon-img w-[50px] h-[50px]"
                      src="https://pxstores-frontend.netlify.app/images/icons/pickup.png"
                    />
                  </div>
                  <h6 className="text-[14px] text-[#403D94] text-center font-black help-step-title">
                    Goods Are Packed &amp; <br />
                    Handed Over to Carriers
                  </h6>
                </div>
                <ArrowForward className="hidden md:block text-orange-600 font-black" />
                <ArrowDown className="bloack md:hidden text-orange-600 font-black" />
              </div>
              <div className="mb-5 md:mb-0 flex flex-col md:flex-row justify-center items-center">
                <div
                  className="step pb-5 md:pb-0 flex flex-col justify-center items-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="step-icon rounded-full bg-[#f5f5f5] p-5">
                    <img
                      className="icon-img w-[50px] h-[50px]"
                      src="https://pxstores-frontend.netlify.app/images/icons/delivered-to-customer.png"
                    />
                  </div>
                  <h6 className="text-[14px] text-[#403D94] text-center font-black help-step-title">
                    Carriers Deliver to Customer
                  </h6>
                  {/* <ArrowForward className="hidden md:block text-orange-600 font-black" /> */}
                  {/* <ArrowDown className="bloack md:hidden text-orange-600 font-black" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-[32px] text-center text-white flex flex-col justify-center items-center">
        <div className="text-[#403D94] text-center font-black">
          {"For more information, visit "}
          <a
            style={{ color: "#E8550F" }}
            href="https://parcelexchangegroup.com"
          >
            Parcel Exchange
          </a>
        </div>
      </div>
    </div>
  );
};

function ArrowForward({ className }) {
  return (
    <img
      alt="icon"
      src={arrowForward}
      className={className}
      width="30px"
      height="30px"
    />
  );
}
function ArrowDown({ className }) {
  return (
    <img
      alt="icon"
      src={arrowDown}
      className={className}
      width="30px"
      height="30px"
    />
  );
}
