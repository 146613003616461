import { ActionProps, DataProps } from "../../types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURl } from "../env/host-url";

export const initialState: DataProps = {
  info: {
    status: false,
    query: "",
    detail: null,
    reset_token: null,
    HLL: null,
  },
  loading: false,
  error: "",
};

export const searchEngineAcion = createAsyncThunk(
  "search/searchEngineAcion/",
  async ({ path, payload, token }: ActionProps) => {
    try {
      const headers: any = {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Authorization": `Bearer ${token}`
      };
      const response = await axios.post(`${baseURl + path}`, payload, {
        headers,
      });
      return response.data;
    } catch (error) {
      // @ts-ignore
      return error.message;
    }
  }
);

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    clearSearch: (state) => {
      state.loading = false;
      state.info.status = false;
      state.info.query = "";
      state.info.detail = null;
      state.info.reset_token = null;
      state.info.HLL = null;
      state.error = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(searchEngineAcion.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(searchEngineAcion.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
        state.error = "";
      })
      .addCase(searchEngineAcion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearSearch } = searchSlice.actions;

export default searchSlice.reducer;

export const searchSelector = (state: any) => state.search;
