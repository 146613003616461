import React from "react";
import { useSelector } from "react-redux";

import { cartSelector, initialState } from "../../redux/reducers/cart-reducer";

type CartContextProps = typeof initialState;

export const useCart = ():CartContextProps => {    
    
    //form state management
    const state = useSelector(cartSelector);
    
    //cart setup
    let value = window.localStorage.getItem("cart") as string  ?? "",
    backupCart = value ? JSON.parse(value  ?? "") : null,
    localizeCart = Boolean(backupCart) ? backupCart : state;
    
    return localizeCart
}