import React from "react";
import { useDispatch } from "react-redux";

import { CLEAR_CART } from "../../redux/reducers/cart-reducer";
import ROUTES from "../../constants/routes-list";
import { useOrder } from "../hook/use-order";
import Loader from "../ui/spinner";
import { useSubcription } from "../hook/use-subcription";

function Success() {
  const { createOrUpdateOrder } = useOrder(),
    count = React.useRef(0),
    { createOrUpdateSubcription } = useSubcription(),
    dispatch = useDispatch(),
    storage = window.localStorage.getItem("_x_"),
    receipt =
      storage && JSON.parse(window.localStorage.getItem("_x_") as string);
  // console.log("recipt", receipt);

  const handlePlaceOrder = () => {
    if (receipt.reasaon === "cash-on-delievery") {
      dispatch(CLEAR_CART());
      setTimeout(() => {
        window.location.href = `${ROUTES.ACCOUNT}`;
        window.localStorage.removeItem("_x_");
      }, 2000);
    }

    if (!storage) {
      return window.alert("An error occured");
    }

    if (receipt.reasaon === "product") {
      createOrUpdateOrder(receipt);
      dispatch(CLEAR_CART());
      setTimeout(() => {
        window.location.href = `${ROUTES.ACCOUNT}`;
        window.localStorage.removeItem("_x_");
      }, 2000);
    }

    if (receipt.reasaon === "subscription") {
      createOrUpdateSubcription(receipt);
      setTimeout(() => {
        window.location.href = `${ROUTES.HOME}`;
        window.localStorage.removeItem("_x_");
      }, 2000);
    }
  };

  let isMounted = true;
  React.useEffect(() => {
    if (isMounted) {
      isMounted = false;
      handlePlaceOrder();
    }
  }, []);
  return (
    <div className="w-full h-full">
      <div className="flex justify-center items-center mt-[25%]">
        Completed Successfully
      </div>
      <div className="flex justify-center items-center mt-10 text-[10px]">
        redirect...
      </div>
      <Loader mini={true} />
      <div className="flex justify-center items-center mb-[25%] text-[10px]"></div>
    </div>
  );
}

export default Success;
