/*jshint ignore:start*/
import "./common.css";
import React from "react";
import { ChevronsDown } from "react-feather";

export const Advertise = (props) => {
  return (
    <div>
      <section className="jumbotron text-center py-5 my-2 md:py-10 md:my-5">
        <div className="container md:py-10">
          <h1 className="jumbotron-heading md:text-[3em]">
            ADVERTISE YOUR PRODUCTS
          </h1>
        </div>
      </section>

      <section className="text-center">
        <div className="container">
          <div className="container">
            <div className="card mb-3">
              <div className="px-3 py-10 text-center text-white text-lg md:text-[2.5em] font-bold italic bg-[#403D94] rounded-t-lg">
                <span className="italics-bold-header">
                  <strong style={{ fontWeight: 600 }}>
                    Kick-start advertising on PX Stores
                  </strong>
                </span>
              </div>
              <div
                className="text-center text-white"
                style={{ marginBottom: "32px" }}
              >
                <div className="angle-double-down flex justify-center items-center">
                  <a href="#sell-on-pxstores">
                    <ChevronsDown
                      className="fa fa-4x fa-angle-double-down text-[#403D94]"
                      style={{ margin: "20px", fontSize: "50px" }}
                      size={48}
                    />
                  </a>
                </div>
              </div>
              <div
                id="sell-on-pxstores"
                className="card-body"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="steps flex justify-between items-center pt-2 pb-1">
                  <div
                    className="step" // step-effect"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <p style={{ fontSize: "1.5em" }}>
                      When sellers on PX Stores not only list but also advertise
                      their products with us, this ensures shoppers will see
                      such products first as they have become promoted.
                      Advertised products from seller are listed in the featured
                      section of PX Stores and buyers see these products first.
                      Advertising works on a pay per click basis, so if shoppers
                      do not click on the product, then we do not charge.
                    </p>
                    <p style={{ fontSize: "1.5em" }}>
                      Any seller can start advertising by creating a campaign in
                      just few minutes. It does not matter if one is new to
                      advertising.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="px-3 py-10 text-center text-white text-lg md:text-[2.5em] font-bold italic bg-[#403D94] rounded-t-lg"
                data-aos="fade-up"
                data-aos-duration="1000"
              ></div>
            </div>
          </div>
          <div
            className="text-center text-white my-[32px]"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <a
              href="https://dashboard.pxstores.com"
              target="_blank"
              className="start-selling-btn text-blue-500"
            >
              START ADVERTISING NOW
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
