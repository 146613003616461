import React, { useState } from "react";
import Table from "rc-table";

import { useModal } from "../modal/modal.context";
import Pagination from "./pagination";

type PaginationProps = {
  from: number;
  to: number;
  total: number;
  current: number;
  last: number;
  goto: any;
};
type TableLayoutProps = {
  list: any[];
  schama: any[];
  pagination?: PaginationProps;
  columnWidth?: number;
};

function TableLayout({
  list,
  pagination,
  schama = [],
  columnWidth = 100,
}: TableLayoutProps) {
  const { openModal } = useModal();

  let columnz = [];
  for (let index = 0; index < list.length; index++) {
    const order = list[index];
    const createColumns = schama.map((value, index) => {
      let inputItem: any = order
          ? Object.entries(order).find((v) => v[0] === value.key)
          : [],
        valueIndex =
          Boolean(inputItem) &&
          inputItem?.map((v) =>
            typeof v === "object" ? Object.values(v)[1] : v
          );

      return valueIndex
        ? {
            title: value.value as string,
            dataIndex: valueIndex[0],
            key: valueIndex[0],
            width: columnWidth,
            render: (item, row) => (
              <div
                className="capitalize cursor-pointer"
                onClick={() => openModal({ view: "VIEW_ORDER", payload: row })}
              >
                {valueIndex[0] === "status"
                  ? String(Object.values(item)[1])
                  : String(item)}
              </div>
            ),
          }
        : null;
    });
    const cols = createColumns.filter((v) => v !== null) as any;
    columnz = cols;
  }

  // const columns = [
  //     {
  //         title: 'Name',
  //         dataIndex: 'name',
  //         key: 'name',
  //         width: 100,
  //     },
  //     {
  //         title: 'Age',
  //         dataIndex: 'age',
  //         key: 'age',
  //         width: 100,
  //     },
  //     {
  //         title: 'Address',
  //         dataIndex: 'address',
  //         key: 'address',
  //         width: 200,
  //     },
  //     {
  //         title: 'Operations',
  //         dataIndex: '',
  //         key: 'operations',
  //         render: () => <a href="#" onClick={()=>openModal({ view:"VIEW_ORDER", payload:"" })}>Delete</a>,
  //     },
  // ];

  return (
    <div className="bg-white my-5 p-5 text-center">
      <Table
        columns={columnz}
        data={list}
        className="overflow-auto"
        scroll={{ x: true }}
        rowClassName="border-t border-[#f0f0f0] hover:bg-[#f0f0f0]"
        footer={() => {
          return pagination?.from ? (
            <Pagination
              from={pagination?.from!}
              to={pagination?.to!}
              total={pagination?.total!}
              current={pagination?.current!}
              last={pagination?.last!}
              goto={pagination?.goto!}
            />
          ) : (
            <></>
          );
        }}
      />
    </div>
  );
}

export default TableLayout;
