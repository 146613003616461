import axios from "axios";
import React from "react";

import { baseURl } from "../../redux/env/host-url";

interface Props {
  token: string;
  pay: {
    total: number;
    email: string;
    currency_code: string;
  };
}
interface PaymentGatewayResponse {
  payment_url: string | null;
  paymentType: string | null;
  error: string | null;
}
export function usePaymentGateway() {
  const [response, setResponse] = React.useState<PaymentGatewayResponse>({
      payment_url: "",
      paymentType: "",
      error: null,
    }),
    { numerify, stringify } = numberTypeChange(),
    callbacks = {
      redirect_url: `${
        window.location.protocol + "//" + window.location.host
      }/success`,
      cancel_url: `${
        window.location.protocol + "//" + window.location.host
      }/cancel`,
    };

  /**
   * Paypal
   * initialize transaction
   */
  const PayPal = async ({ token, pay }: Props) => {
    //header
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    // update payload
    let payload = {
      ...callbacks,
      ...pay,
      currency_code: "GBP",
      amount: stringify(pay?.total),
    };

    //create transaction
    try {
      const response = axios.post(`${baseURl}/paypal`, payload, { headers });
      // console.log("response", (await response).data);
      const result = (await response).data,
        url = await result?.href;

      if (typeof url !== "string") {
        return await setResponse({
          payment_url: null,
          paymentType: null,
          error: "Your currency is not captured for this payment",
        });
      }

      return await setResponse({
        payment_url: url,
        paymentType: "paystack",
        error: null,
      });
    } catch (error) {
      console.error("Error", error);
    }
  };

  /**
   * Paystack
   * initialize transaction
   */
  const PayStack = async ({ token, pay }: Props) => {
    // header
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    // update payload
    let payload = {
      ...callbacks,
      ...pay,
      amount: numerify(pay?.total),
    };

    //create transaction
    try {
      const response = axios.post(`${baseURl}/paystack`, payload, { headers });
      // console.log("response", (await response).data);
      const result = (await response).data,
        url = await result?.payment_url;

      if (typeof url !== "string") {
        return await setResponse({
          payment_url: null,
          paymentType: null,
          error: "Your currency is not captured for this payment",
        });
      }

      return await setResponse({
        payment_url: url,
        paymentType: "paystack",
        error: null,
      });
    } catch (error) {
      console.error("Error", error);
    }
  };

  return {
    ...response,
    PayPal,
    PayStack,
  };
}

export function numberTypeChange() {
  function stringify(total: number) {
    return String(total.toFixed(2));
  }

  function numerify(total: number) {
    return Number(total.toFixed(2));
  }

  return {
    stringify, //for paypal
    numerify, //for paystack
  };
}
