import React, { useEffect, useState } from "react";
import { useDeletions } from "../hook/use-deletions";
import { usePersonal } from "../hook/user-detail";

const Actions = [
  { title: "No Action", action: "No action" },
  { title: "Delete Permanently", action: "Request for deletion by own" },
  { title: "Cancel Delete", action: "Cancel deletion by own" },
];

interface DeletionProps {
  feedback: string;
  deletion_status: string;
  customer: string;
  customer_id: number | null;
  status: boolean;
}
function AccoutDeletion() {
  const { data } = usePersonal(true),
    { createOrUpdateDeletions, info, getUserDeletionRequest } = useDeletions(),
    owner: any = data,
    [isSent, send] = useState<boolean>(false),
    [action, takeAction] = useState<DeletionProps>({
      customer: "",
      deletion_status: "",
      feedback: "",
      customer_id: null,
      status: false,
    }),
    handleAction = () => {
      createOrUpdateDeletions({
        ...action,
        customer: owner?.name,
        customer_id: owner?.id,
      });
      send(true);

      setTimeout(() => {
        send(false);
        takeAction({
          customer: "",
          deletion_status: "",
          feedback: "",
          customer_id: null,
          status: false,
        });
        window.location.reload();
      }, 1500);
    };

  return (
    <div className="my-[50px] pt-5 lg:pb-40 lg:px-80">
      {isSent && (
        <div className={styles.text + "text-[green]"}>
          Completed Successfully
        </div>
      )}
      <div>
        <div className={styles.boxWrapper}>
          <div className={styles.text + styles.textExt}>
            Please select an action to Proceed
          </div>
          <select
            className="w-full"
            onChange={(v) =>
              //@ts-ignore
              takeAction({
                ...action,
                deletion_status: JSON.parse(v.target.value)?.action,
              })
            }
          >
            {Actions.map((value, index) => (
              <option key={index} value={JSON.stringify(value)}>
                {value.title}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.boxWrapper}>
          <div className={styles.text + styles.textExt}>
            Kindly tell us the reason for your decision, to help us improve
          </div>
          <textarea
            name="feedback"
            placeholder="Write here..."
            onChange={(v) =>
              takeAction({ ...action, feedback: v.target.value })
            }
            className="w-full"
            rows={10}
          ></textarea>
        </div>
      </div>
      <div className={styles.container}>
        {action.deletion_status === Actions[1].action && (
          <div className="text-[red] my-[10px] font-500">
            This will take up to 7 days before deleting permanently, and you can
            still undo this action within this space of time
          </div>
        )}
        <button className={styles.btn + styles.btnText} onClick={handleAction}>
          Take action
        </button>
      </div>
    </div>
  );
}

export default AccoutDeletion;

const styles = {
  container: "flex-col justify-center items-center ",
  text: "text-[#000000] ",
  textExt: "font-bold text-[18px] mb-[10px] ",
  btn: "my-3 mx-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 p-2 rounded-md float-right ",
  btnText: "text-[#ffffff] font-bold ",
  boxWrapper: "bg-[#fff] my-[10px] p-[10px] w-full ",
};
