
import React from 'react';
import Profile from '../ui/account/index';

function Account() {
  return (
    <div className="Landing">
        <Profile/>
    </div>
  );
}
export default Account;
