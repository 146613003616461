import React from "react";

import { useOrder } from "../../components/hook/use-order";
import { usePaymentGateway } from "../../components/hook/gateways";
import useToken from "../../components/hook/token";
import { useConverter } from "../../components/hook/currency-conversion";
import classNames from "classnames";

interface PaymentProps {
  checkout: any;
}
export function Payment({ checkout }: PaymentProps) {
  const [token] = useToken(),
    { liveInfo } = useConverter(),
    trackUserLocation = liveInfo?.location?.country?.name,
    { createOrUpdateOrder } = useOrder(),
    { cashoutDetails, receipt } = checkout,
    { paymentType, payment_url, error, PayPal, PayStack } = usePaymentGateway(),
    validation = () => {
      //if not logged in
      if (typeof token !== "string") {
        return alert("Please login and come back to proceed");
      }

      if (
        typeof cashoutDetails.total === "number" &&
        cashoutDetails.total < 1
      ) {
        return alert(
          "The price is not captured, try again or contact support."
        );
      }
      return true;
    },
    handleSubmitPayPal = () => {
      if (validation()) {
        return PayPal({ token: token!, pay: cashoutDetails });
      }
    },
    handleSubmitPayStack = () => {
      if (validation()) {
        return PayStack({ token: token!, pay: cashoutDetails });
      }
    },
    handleSubmitCashOnDelievery = () => {
      if (validation()) {
        //create order receipt
        createOrUpdateOrder({
          ...receipt,
          ["payment_gateway"]: "CASH_ON_DELIVERY",
        });

        // post-receipt preparation
        let convertToString = JSON.stringify({
          reasaon: "cash-on-delievery",
        });
        window.localStorage.setItem("_x_", convertToString);

        // redirecting
        setTimeout(() => {
          window.location.href = `${
            window.location.protocol + "//" + window.location.host
          }/success`;
        }, 1000);
      }
    };

  // console.log("checkout", checkout);
  // console.log("gateway", paymentType, payment_url, error);
  if (Boolean(error)) {
    alert(JSON.stringify(error));
  }

  React.useEffect(() => {
    if (Boolean(payment_url)) {
      // agent url
      let gatewayAgent = payment_url?.toLocaleLowerCase().includes("paystack");

      // post-receipt preparation
      let convertToString = JSON.stringify({
        ...receipt,
        payment_gateway: gatewayAgent ? "PAYSTACK" : "PAYPAL",
      });
      window.localStorage.setItem("_x_", convertToString);

      // redirecting
      setTimeout(() => {
        window.location.href = `${payment_url}`;
      }, 1000);
    }
  }, [payment_url]);

  return (
    <>
      {/* payment buttons */}
      <div
        className={classNames(
          receipt?.reasaon === "product"
            ? "mt-5 md:mt-0 bg-white flex flex-col justify-center items-center shadow"
            : "mt-5 md:mt-0 bg-white flex justify-center items-center shadow"
        )}
      >
        <button
          type="button"
          onClick={handleSubmitPayPal}
          className="cursor-pointer font-bold w-3/4 m-2 block justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          PayPal
        </button>

        {trackUserLocation?.toLowerCase() === "nigeria" && (
          <>
            <button
              type="button"
              onClick={handleSubmitPayStack}
              className="cursor-pointer font-bold w-3/4 m-2 block justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white bg-blue-200 hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              PayStack
            </button>

            {receipt?.reasaon === "product" && (
              <button
                type="button"
                onClick={handleSubmitCashOnDelievery}
                className="cursor-pointer font-bold w-3/4 m-2 block justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white bg-green-800 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cash On Delievery
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
}
