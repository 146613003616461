/*jshint ignore:start*/
import "./common.css";
import React from "react";
import { Link } from "react-router-dom";
import qualityImage from "../staticImages/quality.png";

export const Quality = (props) => {
  return (
    <div>
      <section class="jumbotron text-center py-5 my-2 md:py-10 md:my-5">
        <div class="container md:py-10">
          <h1 class="jumbotron-heading md:text-[3em]">QUALITY ASSURANCE</h1>
        </div>
      </section>

      <div class="container pb-3 mb-1">
        <div class="card mb-3">
          <div class="card-body" data-aos="fade-up" data-aos-duration="1000">
            <div class="steps md:flex justify-between items-center pt-2 pb-1">
              <div class="step" data-aos="fade-up" data-aos-duration="1000">
                <img class="about-img-2 w-100" src={qualityImage} />
              </div>
              <div class="step" data-aos="fade-up" data-aos-duration="1000">
                <h2 class="help-step-title heading text-left">We value you</h2>
                <h2 class="text-left subtitle">
                  At PX Stores, we value our customers as they are key to our
                  operation. As a result, we ensure to the best of our ability
                  that sellers sell products that are fit for the consumption of
                  our customers.{" "}
                </h2>
                <h2 class="text-left subtitle">
                  In the event that an item bought is bad or defective, returns
                  can be made by using our contact us form located{" "}
                  <Link to="/contact">HERE</Link>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
