import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

import { useShoppingCart } from "./shopping-cart.context";
import ROUTES from "../../../constants/routes-list";
import { useDispatch } from "react-redux";
import {
  REMOVE_FROM_CART,
  RETRIEVE_ITEMS_FROM_BACKUP_CART,
} from "../../../redux/reducers/cart-reducer";
import { useConverter } from "../../hook/currency-conversion";
import { useCart } from "../../hook/use-cart";

export default function ShoppingCart() {
  const { viewCart, closeCart, isOpen } = useShoppingCart();
  const { total_price_fxws } = useCart();
  const { currency_name, UiForex } = useConverter();
  const state = useCart();
  const dispatch = useDispatch();

  const products = state.items,
    total = state.total_price_fxws;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={viewCart}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          {" "}
                          Shopping cart{" "}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={closeCart}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          {products.length > 0 ? (
                            <ul
                              role="list"
                              className="-my-6 divide-y divide-gray-200"
                            >
                              {products.map((product) => (
                                <li key={product.id} className="flex py-6">
                                  <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                    <img
                                      src={product.image[0]?.image}
                                      alt="product 1"
                                      className="h-full w-full object-cover object-center"
                                    />
                                  </div>

                                  <div className="ml-4 flex flex-1 flex-col">
                                    <div>
                                      {product.isFromAbroad?.length > 0 && (
                                        <div className="p-1 bg-purple-500 text-white rounded-md w-fit text-[11px] font-bold">
                                          {product?.isFromAbroad}
                                        </div>
                                      )}
                                      <div className="flex justify-between text-base font-medium text-gray-900">
                                        <h3>
                                          <a href={product.href}>
                                            {" "}
                                            {product.name}{" "}
                                          </a>
                                        </h3>
                                        <p className="ml-4">
                                          {"x " + product.sale_price_fx}
                                        </p>
                                      </div>
                                      {Boolean(
                                        product?.variety_option?.color
                                      ) && (
                                        <p className="mt-1 text-sm text-gray-500">
                                          {product?.variety_option?.color}
                                        </p>
                                      )}
                                    </div>
                                    <div className="flex flex-1 items-end justify-between text-sm">
                                      <p className="text-gray-500">
                                        Qty {product.quantity}
                                      </p>

                                      <div className="flex">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            dispatch(
                                              RETRIEVE_ITEMS_FROM_BACKUP_CART(
                                                // @ts-ignore
                                                state
                                              )
                                            );

                                            setTimeout(() => {
                                              dispatch(
                                                // @ts-ignore
                                                REMOVE_FROM_CART(product)
                                              );
                                            }, 2000);
                                          }}
                                          className="font-medium text-indigo-600 hover:text-indigo-500"
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <div className="flex justify-center items-center m-auto">
                              Cart is empty
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                      <div className="flex justify-between text-base font-medium text-gray-900">
                        <p>Subtotal</p>
                        <UiForex
                          from={currency_name}
                          value={total_price_fxws}
                        />
                      </div>
                      {/* <p className="mt-0.5 text-sm text-gray-500">Shipping and taxes calculated at checkout.</p> */}
                      <div className="mt-6">
                        <a
                          href={products.length > 0 ? ROUTES.CHECKOUT : "#"}
                          className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                        >
                          Checkout
                        </a>
                      </div>
                      <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                        <p>
                          or{" "}
                          <button
                            type="button"
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                            onClick={closeCart}
                          >
                            Continue Shopping
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
