import React, { SyntheticEvent } from "react";

import { useConverter } from "../hook/currency-conversion";
import { usePermission } from "../hook/permission";
import useToken from "../hook/token";
import { useSupport } from "../hook/use-support";
import { FormLayout } from "../ui/form-layout";
import { InputBox, TextAreaBox } from "../ui/input-box";
import { ListItems } from "../ui/item-list";
import { useModal } from "../ui/modal/modal.context";
import { Spacer } from "../ui/spacer";
import { TagShortDecription } from "../ui/tag-short-decription";

export const Contact = () => {
  const supports = useSupport();
  const [token, , userID] = useToken();
  const isAdmin = usePermission(2);
  const { openModal } = useModal();
  const { liveInfo } = useConverter();
  const { location } = liveInfo;
  const [writtenMessage, writeMessage] = React.useState<any>(null);

  React.useEffect(() => {
    supports.getSupport({ page: 1 });
  }, []);

  let supportz = supports.info.HLL?.data
    ? supports.info.HLL?.data.map((value) => {
        return {
          country: value.country,
          name: value.user.name,
          title: value.title,
          message: value.message,
          _full_details: value,
        };
      })
    : [];

  const handleContact = (e: SyntheticEvent) => {
    //if not login, alert the you to do so
    if (!token) {
      return openModal({
        view: "ALERT",
        payload: "Please login and come back to proceed",
      });
    }

    e.preventDefault();

    const target = e.target as typeof e.target & {
      title: {
        value: string;
      };
      message: {
        value: string;
      };
    };
    const title = target.title.value;
    const message = target.message.value;

    //prepare payload
    let payload = {
      ...writtenMessage,
      onwer_id: writtenMessage?.customer_id ?? userID,
      title,
      message,
      country: location?.country?.name,
    };

    supports.createOrUpdateSupport(payload);

    //clear
    writeMessage(null);

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div className="w-full bg-gray-100">
      <div className="flex flex-col md:flex-row max-w-7xl w-full md:w-[80%] lg:w-[80%] mx-auto pt-[5rem] lg:pt-[10rem] xl:pt-[10rem] 2xl:pt-[10rem] pb-[5rem] px-5 xl:py-14 xl:px-8 2xl:px-14">
        {/* Contact form */}
        <div className="w-full order-1 md:order-2 md:mb-0 md:ms-7 lg:ms-9 md:p-8 bg-light">
          <h1 className="mb-7 text-xl md:text-2xl font-body font-bold text-heading">
            {"Questions, Comments, Or Concerns?"}
          </h1>
          <FormLayout
            onsubmit={handleContact}
            buttonTitle="Send"
            setClearButtonTitle={true}
            onClear={() => {
              writeMessage(null);

              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }}
          >
            <InputBox
              name="title"
              type="text"
              classNameInput="w-1/2 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              htmlFor="Subject"
              classNameLabel="block text-sm font-medium text-gray-700"
              labelTitle="Subject"
              defaultValue={writtenMessage?.title}
              classNameGrouped="col-span-6"
            />
            <TextAreaBox
              name="message"
              placeholder="Write..."
              type="text"
              classNameInput="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              htmlFor="Message"
              classNameLabel="block text-sm font-medium text-gray-700"
              labelTitle="Message"
              classNameGrouped="col-span-6"
              numberOfRows={10}
            />
          </FormLayout>
          <div className="mt-5 mx-auto">
            {"You can email us at: support@pxstores.com"}
          </div>
        </div>
      </div>

      {isAdmin && (
        <div className="max-w-7xl w-full md:w-[80%] lg:w-[80%] mx-auto pb-[5rem] px-5  xl:px-8 2xl:px-14">
          {/* feedback admin only */}
          <TagShortDecription
            title="Logs"
            description="This section is only visible to admin only"
            outerlayerStyle="md:col-span-1"
            innerBodyStyle="px-4 sm:px-0 my-10"
            innerHeaderStyle="text-lg font-medium leading-6 text-gray-900"
            innerlayerStyle="mt-1 text-sm text-gray-600"
          />
          <ListItems
            list={supportz}
            layoutStyli="flex justify-evenly items-start shadow overflow-auto sm:rounded-md mb-[100px] md:pl-10 md:pr-10 lg:pl-10 lg:pr-10 flex-col pt-[100px] max-h-screen overflow-auto"
            card={{
              frameStyli: "shadow w-5/6",
            }}
            onSelect={(v) =>
              writeMessage({
                ...v._full_details,
                title: v.title.includes("Re:") ? v.title : "Re: " + v.title,
              })
            }
          />
          <Spacer />
        </div>
      )}
    </div>
  );
};
export default Contact;
