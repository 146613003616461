import React from "react";

import Modal from "./modal";
import { useModal } from "./modal.context";

const Alert = React.lazy(()=>import("../alert-popup"));
const ProductOverview = React.lazy(()=>import("../products/product-overview"));
const OrderOverview = React.lazy(()=>import("../orders/order-overview"));
const SearchBar = React.lazy(()=>import("../search-bar"));

export function ModalManager () {
    const { closeModal, isOpen, view, payload } = useModal()

    return (
        <Modal open={isOpen} onClose={closeModal}>
            {view === "ALERT" && <Alert item={payload} />}
            {view === "VIEW_PRODUCT" && <ProductOverview item={payload}/>}
            {view === "VIEW_ORDER" && <OrderOverview item={payload}/>}
            {view === "SEARCH_BAR" && <SearchBar />}
        </Modal>
    )
}

