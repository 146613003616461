import React from "react";

import { useModal } from "../ui/modal/modal.context";
import { imagePlaceholder } from "../../constants/image-static";
import { useConverter } from "../hook/currency-conversion";
import { useSearchEngineFull } from "../hook/search-engine";
import { useParams } from "react-router-dom";
import Loader from "../ui/spinner";
import { ErrorPage } from "./error";
import classNames from "classnames";

export default function RelatedView() {
  const { openModal } = useModal();
  const searcher = useSearchEngineFull();
  const { UiForex, iforex, iforexWS, currency_name } = useConverter();
  const params = useParams();

  const searchValue = params.related as string,
    tagName = searchValue.slice(0, searchValue.indexOf("-")),
    title =
      tagName === "loading"
        ? "Amazing choice!"
        : tagName === "preown"
        ? "Pre-Owned"
        : tagName,
    searchTerm = searchValue.slice(
      searchValue.indexOf("-") + 1,
      searchValue.lastIndexOf("-")
    );

  let pointer = searchValue.slice(searchValue.lastIndexOf("-") + 1),
    targets =
      pointer === "subcategory"
        ? ["subcategory_id"]
        : pointer === "collection"
        ? ["shop_id"]
        : [
            "name",
            "subcategory_id",
            "description",
            "id",
            "premier",
            "product_type",
            "price",
            "quantity",
          ];

  React.useEffect(() => {
    //searching
    searcher.findRelated({
      keyIndex: 7,
      value: searchTerm === "0" ? "" : searchTerm,
      targets: targets,
    });
  }, [tagName]);

  //restructing product to add key with string type for flat list
  let products =
    typeof searcher.info?.HLL === "object" &&
    searcher.info?.HLL?.length !== undefined
      ? searcher.info?.HLL.map((value: any) => {
          // with symbol
          // @eslint-disable-next-line react-hooks/exhaustive-deps
          let sale_price_fx = iforex({
            from: value.currency.code,
            value: value.sale_price,
          });
          // @eslint-disable-next-line react-hooks/exhaustive-deps
          let price_fx =
            value.price > 0
              ? iforex({ from: value.currency.code, value: value.price })
              : value.price;

          // without symbol
          // @eslint-disable-next-line react-hooks/exhaustive-deps
          let sale_price_fxws = iforexWS({
            from: value.currency.code,
            value: value.sale_price,
          });
          // @eslint-disable-next-line react-hooks/exhaustive-deps
          let price_fxws =
            value.price > 0
              ? iforexWS({ from: value.currency.code, value: value.price })
              : value.price;

          // return value;
          return (value = {
            ...value,
            sale_price_fx,
            price_fx,
            sale_price_fxws,
            price_fxws,
            key: String(value.id),
          });
        })
          .filter((v: any) => v.state !== "draft")
          .filter(
            (v) =>
              (v?.subcategory_id == searchTerm && pointer === "subcategory") ||
              (v?.shop_id == searchTerm && pointer === "collection") ||
              (v?.product_type == "preown" && pointer === "preown") ||
              (v?.brand?.name?.toLowerCase() === tagName.toLowerCase() &&
                pointer === "brands")
          )
      : [];

  if (searcher.error) {
    return <ErrorPage />;
  }

  if (searcher.loading) {
    return <Loader />;
  }

  let colorPicker = Math.round(Math.random() * 10) ?? 1;
  return (
    <div className="bg-white border-t border-gray-100">
      <div className="">
        <div
          className={classNames(
            "flex justify-start items-center h-16 sm:h-24 w-full my-auto px-5",
            {
              "bg-gradient-to-r from-white via-blue-300 to-blue-600":
                colorPicker === 1,
            },
            {
              "bg-gradient-to-r from-white via-red-300 to-red-600":
                colorPicker === 2,
            },
            {
              "bg-gradient-to-r from-white via-green-300 to-green-600":
                colorPicker === 3,
            },
            {
              "bg-gradient-to-r from-white via-purple-300 to-purple-600":
                colorPicker === 4,
            },
            {
              "bg-gradient-to-r from-white via-orange-300 to-orange-600":
                colorPicker === 5,
            },
            {
              "bg-gradient-to-r from-white via-slate-300 to-slate-600":
                colorPicker === 6,
            },
            {
              "bg-gradient-to-r from-white via-pink-300 to-pink-600":
                colorPicker === 7,
            },
            {
              "bg-gradient-to-r from-white via-lime-300 to-lime-600":
                colorPicker === 8,
            },
            {
              "bg-gradient-to-r from-white via-sky-300 to-sky-600":
                colorPicker === 9,
            },
            {
              "bg-gradient-to-r from-white via-gray-300 to-black":
                colorPicker === 10,
            }
          )}
        >
          <h2 className="text-xl sm:text-5xl text-amber-300  font-extrabold drop-shadow-lg capitalize">
            {title}
          </h2>
        </div>
        <hr />

        {products.length > 0 ? (
          <>
            <div className="min-h-screen max-w-2xl mx-auto pb-16 sm:pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8 mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
              {products.map((product, index) => (
                <div
                  key={index}
                  className="group relative"
                  onClick={() =>
                    openModal({ view: "VIEW_PRODUCT", payload: product })
                  }
                >
                  <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                    <img
                      src={product.image[0]?.image ?? imagePlaceholder} //
                      alt="product"
                      className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                    />
                  </div>
                  <div className="mt-4 flex justify-between">
                    <div>
                      <h3 className="text-sm text-gray-700">
                        <a href={product.href}>
                          <span
                            aria-hidden="true"
                            className="absolute inset-0"
                          />
                          {product.name}
                        </a>
                      </h3>
                      {product.price !== 0 && (
                        <UiForex
                          from={currency_name}
                          value={product.price}
                          styli="mt-1 text-sm text-gray-500 line-through"
                        />
                      )}
                    </div>
                    <p className="text-sm font-medium text-gray-900">
                      {product.sale_price_fx}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="max-w-full min-h-[500px] pt-[200px] font-bold text-3xl flex justify-center item-center">
            No Result
          </div>
        )}
      </div>
    </div>
  );
}
