import React from "react";
import cn from "classnames";

type FormLayoutProps = {
  children:any
  onsubmit:any
  layoutStyli?:any
  buttonTitle?:string
  onClear?:any
  clearButtonTitle?:string
  setClearButtonTitle?:boolean
}
export function FormLayout({ children, onsubmit, layoutStyli, buttonTitle = "Save", clearButtonTitle = "Clear", onClear, setClearButtonTitle = false }:FormLayoutProps) {
    return (
      <form onSubmit={onsubmit}>
        <div className={cn("shadow overflow-hidden sm:rounded-md", layoutStyli)}>
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="grid grid-cols-6 gap-6">
              {children}
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            {setClearButtonTitle && 
              <button
                type="button"
                onClick={onClear}
                className="mx-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {clearButtonTitle}
              </button>
            }
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {buttonTitle}
            </button>
          </div>
        </div>
      </form>
    )
}