import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { applicantAction, applicantSelector } from "../../redux/reducers/applicant-reducer";
import { initialState } from "../../redux/reducers/applicant-reducer";

type UseApplyProps = {
    createOrUpdateApplicant:(payload:any) => any 
    getApplicant:(payload:any) => any 
    deleteApplicant:(payload:any) => any

} & typeof initialState

export const useApply = ():UseApplyProps => {
    const dispatch = useDispatch();
    const state = useSelector(applicantSelector);


    const createOrUpdateApplicant = (payload:any) => dispatch<any>(applicantAction({path:"/applicant", payload}));
    const getApplicant = (payload:any) => dispatch<any>(applicantAction({path:"/applicants", payload}));
    const deleteApplicant = (payload:any) => dispatch<any>(applicantAction({path:"/applicant/status", payload,}));

    return {
        ...state,
        createOrUpdateApplicant, 
        getApplicant, 
        deleteApplicant
    }
}