import React from "react";
// @ts-ignore
import error from "../assets/error-animation.gif";

export function NotFound() {
    return (
        <div className='flex flex-col justify-center items-center mx-auto my-[5%]'>
            <p className='font-bold text-[10rem]'>{ "404!" }</p>
            <p className='font-bold text-[1rem]'>{ "The page is not found" }</p>
        </div>
    )
}

export function ErrorPage() {
    return (
        <div className='flex flex-col justify-center items-center mx-auto my-[5%]'>
            <p className=''>{ "An Error occurred" }</p>
            <img
                className="h-1/2 w-1/3 rounded-full"
                src={error}
                alt=""
            />
        </div>
    )
}